import React, {Component} from 'react';
import { Button } from 'react-bootstrap';
import './Documentacion.css';
import { MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink, MDBIcon, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBBtn, MDBView, MDBCardImage, MDBCard, MDBAnimation, MDBRow, MDBMask } from "mdbreact";
import fosa from './fosa.png';
import sismo from './sismo.gif';
import epihipo from './epihipo.png';
import intensidad from './intensidad.jpg';
import magnitud from './magnitud.png';
import calor from './calor.png';
import hipoceresis from './hipoceresis.png';
import intensidades from './intensidades.png';
import fallas from './fallas.png';
import isosistas from './isosistas.png';
import cuadroisosistas from './cuadroisosistas.png';
import formulas from './formulas.JPG';
import formulasimm from './formulasimm.JPG';
import vol1 from './vol1.jpg';
import vol5 from './vol5.jpg';
import vol7a from './vol7a.jpg';
import vol10 from './vol10.jpg';

import axios from 'axios';

export class Documentacion extends Component{
    constructor(props){
        super(props);
        this.state = {
            desliza: "no_deslizador",
            activeItemClassicTabs3: "3",
            metodologia: [],
            referencia: [],
            ruta_django: "https://eval-vulnerabilidad.cdlima.org.pe",
        }
    };
    toggleClassicTabs3 = tab => () => {
        if (this.state.activeItemClassicTabs3 !== tab) {
        this.setState({
          activeItemClassicTabs3: tab
        });
        }
      };
    
    async get_metodologia(){
      await axios.get('encuesta/metodologias')
      .then(response =>{
        console.log(response.data);
        const metodologias =  response.data
        
        for (let index = 0; index < metodologias.length; index++) {
          //metodologias[index]["stage"]=false
          metodologias[index]["image"]=this.state.ruta_django + metodologias[index]["image"]
        }
        this.setState({
          metodologia: metodologias
        })
        console.log(metodologias)
      })
      .catch(e => {})
    }

    async get_referencia(){
      await axios.get('encuesta/referencias')
      .then(response =>{
        console.log(response.data);
        const referencias =  response.data
        
        for (let index = 0; index < referencias.length; index++) {
          //referencias[index]["stage"]=false
          referencias[index]["image"]=this.state.ruta_django + referencias[index]["image"]
          referencias[index]["files"]=this.state.ruta_django + referencias[index]["files"]
        }
        this.setState({
          referencia: referencias
        })
        console.log(referencias)
      })
      .catch(e => {})
    }
    
    componentDidMount(){
      this.get_metodologia();
      this.get_referencia();

      }
    render(){
        return(
          <div className="documentacion_principal">
            <MDBContainer>
              <div className="classic-tabs">
                <MDBNav classicTabs color="red" className="mt-4">
                  <MDBNavItem>
                    <MDBNavLink className="fuente" link to="#" active={this.state.activeItemClassicTabs3==="3"} onClick={this.toggleClassicTabs3("3")}>
                      <MDBIcon icon="book" size="2x" />
                      <br />
                      <small>Metodología</small>
                    </MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink className="fuente" link to="#" active={this.state.activeItemClassicTabs3==="4"} onClick={this.toggleClassicTabs3("4")}>
                      <MDBIcon icon="file-pdf" size="2x" />
                      <br />
                      <small>Referencias</small>
                    </MDBNavLink>
                  </MDBNavItem>
                  
                </MDBNav>
                <MDBTabContent className="card-content mb-5" activeItem={this.state.activeItemClassicTabs3}>
                  <MDBTabPane tabId="3">
                    <Organizacion metodologias={this.state.metodologia}/>
                  </MDBTabPane>
                  <MDBTabPane tabId="4">
                    <Downloads
                      referencias={this.state.referencia}
                      sm_image = "2"
                      sm_descripcion = "10"
                      url_image = {vol1}
                      title = "Catalogo de Terremotos para America del Sur - Vol1"
                      descripcion = "Contiene Información referente a los terremotos ocurridos en América del Sur"
                      referencia = "Referencia: CERESIS"
                      ref_pdf = "../SISRA-VOL01.pdf"
                    />
                  </MDBTabPane>
                  </MDBTabContent>
              </div>
            </MDBContainer>

          </div>
        );
    }
}

function Organizacion(props){
  return(
    <MDBRow md="12">
      <MDBCardBody  className="contenido_descipcion">
        <MDBRow className='my-4 mx-4'>
          
            {props.metodologias.map(({id, name, image, description}) => <MDBCol id={id} className="style-organizacion" md="12">
              <h3 className='font-weight-bold mb-3 black-text' >{name}</h3>
              <p  className="texto-parrafo">
              {description}
              </p>
              <br/><br/>
            </MDBCol>
            )}
        </MDBRow>
      </MDBCardBody>
    </MDBRow>
  );
}

function Downloads(props){
  return(<p>{props.referencias.map(({id, name, image, files, description, referencia}) => <MDBCol id={id} md="12">
    <MDBCardBody  className="contenido_descipcion">
        <MDBRow className='my-4 mx-4'>
          <MDBCol md={props.sm_image} className='mt-3 pt-2'>
            <MDBView className='z-depth-1'>
              <MDBCardImage
                className = 'img-fluid z-depth-1'
                src = {image}
              />
            </MDBView>
          </MDBCol>
          <MDBCol md={props.sm_descripcion}>
            <h3 className='font-weight-bold mb-3 black-text' >
              {name}
            </h3>
            <p>
              {description}
            </p>
            <p className='mb-0 '>
              <strong>Referencia:  </strong>{referencia}
            </p>
            <p className='mb-0 '>
              <strong>Descargue el Volumen: <a href={files}  target="_blank" type="download"><MDBIcon icon="cloud-download-alt" size="2x"/></a></strong>
            </p>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
      </MDBCol>)
            }
            </p>
            
  );  
}
