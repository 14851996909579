import React, {Component} from 'react';
import { Button, Card} from 'react-bootstrap';
import './Nosotros.css';
import { MDBContainer, MDBCol, MDBRow, MDBMask } from "mdbreact";
import {Cards} from './Cards';



import axios from 'axios';

export class Team extends Component{
    constructor(props){
        super(props);
        this.state = {
            text_expanded: "texto_cards_oculto",
            boton_expanded: "boton_expand",
            boton_contrae: "boton_contrae",
            activeItemClassicTabs3: "1",
            revisado:[],
            desarrollado:[],
        }
    };
    
    expanded=()=>{
        this.setState({
            text_expanded: "texto_cards",
            boton_expanded: "boton_contrae",
            boton_contrae: "boton_expand",
        })
    }

    contrae=()=>{
        this.setState({
            text_expanded: "texto_cards_oculto",
            boton_expanded: "boton_expand",
            boton_contrae: "boton_contrae",
        })
    }

    async team_revisado(){
        await axios.get('encuesta/revisores')
        .then(response =>{
          console.log(response.data);
          this.setState({
            revisado: response.data
          })
        })
        .catch(e => {})
      }
    
      async team_desarrollado(){
        await axios.get('encuesta/desarrolladores')
        .then(response =>{
          console.log(response.data);
          this.setState({
            desarrollado: response.data
          })
        })
        .catch(e => {})
      }
    
    componentDidMount(){
        this.team_revisado();
        this.team_desarrollado();
    }

    render(){
        return(
            <div className="documentacion_nosotros">
                <MDBContainer>
                    <MDBRow>
                        <MDBCol md={12}>
                            <h3 className="Titulo_Equipo">Equipo de Trabajo</h3>
                        </MDBCol>
                        <MDBCol md={6}>
                            <h3 className="Titulo_Equipo">Revisado por:</h3>
                            <Desarrollado desarrollado={this.state.desarrollado}/>
                        </MDBCol>
                        <MDBCol md={6}>
                            <h3 className="Titulo_Equipo">Desarrollado por:</h3>
                            <Revisado revisado={this.state.revisado}/>
                        </MDBCol>
                    </MDBRow>
                    
                                        
                </MDBContainer>
            </div>
        );
    }
}

function Revisado(props){
    return( <MDBCol md={12}>
        {props.revisado.map(({linkcontacto,nombre,cargo,centro_trabajo,description,red_social,url_redsocial,image})=><Cards 
        cardone = "12"
        cardtwo = "12"
        linkcontacto={linkcontacto}
        nombre = {nombre}
        cargo = {cargo}
        centro_trabajo = {centro_trabajo}
        email = {JSON.parse(description)}
        red_social = {red_social}
        url_redsocial = {url_redsocial}
        url_photo = {"https://eval-vulnerabilidad.cdlima.org.pe//"+image}
        />)}
    </MDBCol>
    )
}

function Desarrollado(props){
    return( <MDBCol md={12}>
        {props.desarrollado.map(({linkcontacto,nombre,cargo,centro_trabajo,description,red_social,url_redsocial,image})=><Cards 
        cardone = "12"
        cardtwo = "12"
        linkcontacto={linkcontacto}
        nombre = {nombre}
        cargo = {cargo}
        centro_trabajo = {centro_trabajo}
        email = {JSON.parse(description)}
        red_social = {red_social}
        url_redsocial = {url_redsocial}
        url_photo = {"https://eval-vulnerabilidad.cdlima.org.pe//"+image}
        />)}
    </MDBCol>
    )
}


