import React, { SyntheticEvent, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Nav, NavDropdown, Navbar, Container, Offcanvas, Form, FormControl, Button} from 'react-bootstrap';
//import { ModaleLogin } from "./modal_login";
//import Userkeycloak from "./userkeycloak";
import ciplimafinal from './Encabezado_paint.png';
import './navegador.css';

const Navegador = (props) =>{

    const [redirect, setRedirect] = useState(false);
    const [modal_login, setModalLogin] = useState(false);
    let access;

    const accion_close = () =>{
        localStorage.clear();
        setRedirect(true);
        console.log("storage borrados: ", localStorage.getItem('names_login'))
        props.setNombres(null);
        props.setCondicion(null);
    }

    const OpenLogin = () => {
      setModalLogin(true)
    }
    const CloseModal = () => {
      setModalLogin(false)
    }
    {/*if (props.nombres != null) {*
    access = <Offcanvas.Body>
        <Nav className="justify-content-end flex-grow-1 pe-3">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/hcsomonitoring">HCSO</Nav.Link>
          <Nav.Link href="/odoogeneral">Odoo</Nav.Link>
          
          {/*<Userkeycloak keycloak={props.datoskeycloak}/>
          <span className="ToLogin"><img src="https://www.citypng.com/public/uploads/preview/white-user-member-guest-icon-png-image-31634946729lnhivlto5f.png" className="icon_session"/> {props.nombres} </span> */}
        
    {/*}      <Nav.Link href={"/"} onClick={accion_close} className="nav-link active" ><strong> Salir </strong></Nav.Link>
        </Nav>
        <ModaleLogin
          cierramodal={CloseModal} 
          statemodal={modal_login}
          nombres={props.nombres}
          setNombres={props.setNombres}
          />
      </Offcanvas.Body>
      
    /* }
    else{
      access = <Offcanvas.Body>
      <Nav className="justify-content-end flex-grow-1 pe-3">
        {/*<Nav.Link href="/">Home</Nav.Link>*/}
        {/*<Nav.Link href="/analyzer">Joseph</Nav.Link>
        {/*<Nav.Link href="/odoogeneral">Odoo</Nav.Link>*/}
        
      {/*</Nav>
      <button className="ToLogin" onClick={OpenLogin}>Login</button>
      <ModaleLogin
        cierramodal={CloseModal} 
        statemodal={modal_login}
        nombres={props.nombres}
        setNombres={props.setNombres}
        />
    </Offcanvas.Body>
    
    } */

    return(
      <Navbar key="sm" bg="#2D2D2D" expand="sm" variant='dark' className="navegador_final">
      <Container fluid>
        <Navbar.Brand><img src={ciplimafinal} className="logo_red"/><br/> <strong className="white-text texto_nombre" title="Encuesta ">Encuesta de Vulnerabilidad Sísmica de Edificaciones de Albañileria</strong></Navbar.Brand>      
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-sm`}
          aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
          placement="end"
        >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
            Offcanvas
          </Offcanvas.Title>
        </Offcanvas.Header>
          {/*{access}*/}
          <Offcanvas.Body>
        <Nav className="justify-content-end flex-grow-1 pe-3">
          <Nav.Link className="items_f" href="/" >Home</Nav.Link>
          <Nav.Link className="items_f"  href="/Encuesta" >Encuesta</Nav.Link>
          <Nav.Link className="items_f" href="/Mapavulnerabilidad" >Mapa</Nav.Link>
          <NavDropdown className="items_f" title="Nosotros" id="nav-dropdown">
            <NavDropdown.Item href="/Instituciones">Instituciones</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="Team">Equipo Trabajo</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link className="items_f" href="/Documentacion">Documentacion</Nav.Link>
          
          {/*<Userkeycloak keycloak={props.datoskeycloak}/>
          <span className="ToLogin"><img src="https://www.citypng.com/public/uploads/preview/white-user-member-guest-icon-png-image-31634946729lnhivlto5f.png" className="icon_session"/> {props.nombres} </span> */}
        
          {/*<Nav.Link href={"/"} onClick={accion_close} className="nav-link active" ><strong> Salir </strong></Nav.Link>*/}
        </Nav>
        {/*<ModaleLogin
          cierramodal={CloseModal} 
          statemodal={modal_login}
          nombres={props.nombres}
          setNombres={props.setNombres}
        />*/}
      </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>

        );
}};

export default Navegador;