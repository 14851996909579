import React, {Component} from 'react';
import { Button, Card} from 'react-bootstrap';
import './Nosotros.css';
import { MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink, MDBIcon, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBBtn, MDBView, MDBCardImage, MDBCard, MDBAnimation, MDBRow, MDBMask } from "mdbreact";

export class CardsInstituciones extends Component{
    constructor(props){
        super(props);
        this.state = {
            text_expanded: "texto_cards",
            boton_expanded: "boton_expand",
            boton_contrae: "boton_contrae",
            activeItemClassicTabs3: "1"
        }
    };
    
    expanded=()=>{
        this.setState({
            text_expanded: "texto_cards",
            boton_expanded: "boton_contrae",
            boton_contrae: "boton_expand",
        })
    }

    contrae=()=>{
        this.setState({
            text_expanded: "texto_cards_oculto",
            boton_expanded: "boton_expand",
            boton_contrae: "boton_contrae",
        })
    }

    render(){
        return(
            
            <Card className="cards">
                <Card.Header className="jheader">
                    <p className="title_ref"><a href={this.props.linkcontacto} target="_blank" className="name_ref"><h4><strong>{this.props.nombre}</strong></h4> </a><h6 className="cargo_ref"><strong>{this.props.cargo}</strong></h6> </p>
                    {/* <button className={this.state.boton_expanded} onClick={this.expanded}><MDBIcon icon="angle-double-down" size="2x" /></button>
                    <button className={this.state.boton_contrae} onClick={this.contrae}><MDBIcon icon="angle-double-up" size="2x" /></button> */}
                </Card.Header>
                <Card.Body className={this.state.text_expanded}>
                    <blockquote className="blockquote mb-0">
                    <MDBRow md="12">
                        <MDBCardBody  className="contenido_descipcion">
                            <MDBRow className='my-1 mx-1'>
                                
                                <MDBCol md={this.props.cardone}>
                                    
                                    <p>
                                        {this.props.descripcion}
                                    </p>
                                    <footer className='mb-0 '>
                                    <strong>Web: </strong><a href={this.props.url_maps} target="_blank">{this.props.maps}</a>
                                    </footer>
                                </MDBCol>
                                <MDBCol md={this.props.cardtwo} className='mt-3 pt-2'>
                                    <MDBView className={'z-depth-1 '.concat(this.props.style_logo)}>
                                    <MDBCardImage
                                        className='img-fluid z-depth-1'
                                        src={this.props.url_photo}
                                        alt=''
                                    />
                                    </MDBView>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBRow>
                    {/* <p>
                        {' '}
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                        erat a ante.{' '}
                    </p>
                    <footer className="blockquote-footer">
                        Someone famous in <cite title="Source Title">Source Title</cite>
                    </footer> */}
                    </blockquote>
                </Card.Body>
            </Card>
                
        );
    }
}