import React, {Component} from 'react';
import './Isosistas.css';

import View from 'ol/View';
import Projection from 'ol/proj/Projection';
import Map from 'ol/Map';
import Tile from 'ol/layer/Tile';
import Heatmap from 'ol/layer/Heatmap';
import TileLayer from 'ol/layer/Tile';
import Vector from 'ol/layer/Vector';
import OSM from 'ol/source/OSM';
import Stamen from 'ol/source/Stamen';
import Control from 'ol/control/Control';
import Attribution from 'ol/control/Attribution';
import Zoom from 'ol/control/Zoom';
import ZoomToExtent from 'ol/control/ZoomToExtent';
import ZoomSlider from 'ol/control/ZoomSlider';
import FullScreen from 'ol/control/FullScreen';
import OverviewMap from 'ol/control/OverviewMap';
import Rotate from 'ol/control/Rotate';
import ScaleLine from 'ol/control/ScaleLine';
//import ZoomSlider from 'ol/source/ZoomSlider';
//importar estilos
import Style from 'ol/style/Style';
import BingMaps from 'ol/source/BingMaps';


import {ImageArcGISRest, XYZ} from 'ol/source';
import {Image as ImageLayer} from 'ol/layer';

//import para vectores

import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {Fill, Stroke, Text, Circle, RegularShape} from 'ol/style';
import BaseLayer from 'ol/layer/Base';

//importar para geolocalizar 
import Geolocation from 'ol/Geolocation';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Icon from 'ol/style/Icon';
import { fromLonLat } from 'ol/proj';
import {transform} from 'ol/proj';


//Import DatePicker
import DatePicker from "react-datepicker";

//Importar para dibujar
import Draw, {createBox, createRegularPolygon} from 'ol/interaction/Draw';

//Importar para Medir
import {getArea, getDistance, getLength} from 'ol/sphere';

//importar para popup
import Overlay from 'ol/Overlay';

// import icons
import { FaSearchLocation, FaLayerGroup, FaRegBookmark, FaList, FaRev, FaCloudDownloadAlt,FaRegEyeSlash, FaRegEye, FaGem, FaHeart, FaHome, FaExpand, FaHotTub, FaMarker, FaFulcrum, FaMap, FaMapMarkedAlt, FaGlobeAmericas, FaGlobe, FaHotjar, FaEyeDropper, FaEye, FaDownload, FaSave, FaPrint} from 'react-icons/fa';
import { BsGrid, BsFillLayersFill, BsLayersHalf, BsSquare, BsSquareHalf, BsGeoAlt, BsGeo } from "react-icons/bs";
import { FiFilter, FiLayers } from "react-icons/fi";
import { MdZoomOutMap, MdFilterList, MdGroup, MdCenterFocusStrong, MdRefresh } from "react-icons/md";
import {CgClose, CgExternal, CgHomeAlt} from 'react-icons/cg';

import {GiUnionJack, GiPositionMarker, GiWorld} from "react-icons/gi";

//importar SideNav
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarContent, SidebarFooter, SidebarHeader } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';

//Import Modal
import {Modal, Button, Container, Row, Col} from 'react-bootstrap';

//Geocode
import Geocode from "react-geocode";

//Imagenes
import leyenda_intensidades from './intensidad.jpg';
import sismo_icon from './sismo.png';
import isosista_icon from './rectangulorojo.png';
import isosistas_page from './isosistas.gif';
//Generador de PDF
import Pdf from 'react-to-pdf';

//import axios
import axios from 'axios';
import { MDBBtn, MDBIcon, MDBInput } from 'mdbreact';
import { BiStats } from 'react-icons/bi';
import { invert } from 'ol/transform';

import fallas_leyenda from './fallas_leyenda.png';

import ModalPage from './Modal';
//Importa Estilo Open Layers
require('ol/ol.css');


const ref = React.createRef();


export class Isosistas extends Component{
    ano=String(new Date().getFullYear());
    constructor(props){
        super(props);
        this.state = {
          //Dates date-picker
          date_ini: new Date("01/01/"+this.ano),
          date_end: new Date(),
          //Modal
          toggle_modal: false,
          //SideBar
          sidebar_state: false,
          //Slide
          slide_detail: 'Caracteristicas_popover',
          slide_ciudades: "tabla_ciudades_oculta",
          //Capas de Mapas
          mapa_calor:"capa_inactiva",
          mapa_epicentral:"capa_activa",
          intensidad_II:"capa_activa",
          intensidad_III:"capa_activa",
          fallas_geo:"capa_inactivac",

          //Visibilidad de Capas
          visible_II: true,
          visible_III: true,
          
          //Texto isosistas
          color_texts:'rgba(180,180,180,0.8)',
          //Detalles
          Nombre: '',
          Fuente: '',
          tipo: '',
          Referencia:'',
          Layer: '',
          Intensidad:'',
          value_intensidad:'',
          EscalaIntensidad:'',
          Latitud:'',
          Longitud:'',
          MaxIntensidad:'',
          Ciudad:'',
          Fecha:'',
          Iso: '',
          nombre_detalle: '',
          latitud_detalle: '',
          longitud_detalle: '',
          magnitud_detalle: '',
          unidad_mag_detalle: '',
          profundidad_detalle: '',
          fecha_detalle: '',
          referencia_detalle: '',
          
          //Filtros del mapa
          lat_filter: '-10.5634',
          lon_filter:'-74.6789',
          type_filter: "fuente",
          filter_fecha: "oculta_filtro_fecha",
          filter_pais: "oculta_filtro_pais",
          filter_area: "oculta_filtro_area",
          filter_fuente: "muestra_filtro_fuente",
          filter_magnitud: "oculta_filtro_magnitud",
          type_geometry_filter: "circular",
          type_geometry_radio:"1",
          type_fuente: "USGS",
          type_magnitud: "2.5",
          type_pais: "PERU",

          //Contenido Sismo Isosistas
          loading_sismosisosistas: true,
          matriz_sismosisosistas: [],
          id_sismo:"5",

          //Contenido Ciudades isosistas
          loading_ciudades_isosistas:true,
          matriz_ciudades_isosistas:[],

          //Leyenda
          t_leyenda_fallas: "sin_leyenda",
          leyenda_fallas:"sin_leyenda",
        };
        this.toggle_side = this.toggle_side.bind(this);
        this.cambia_direccion = this.cambia_direccion.bind(this);
        this.busca_direccion = this.busca_direccion.bind(this);
        this.select_sismo = this.select_sismo.bind(this);

        //Mapa vista Open Street Map
        this.OSM = new TileLayer({
            source: new OSM(),
            visible: false
        });

        //Mapa vista Open Street Map Nocturno
        this.Nocturno = new TileLayer({
          source: new OSM(),
          className: "OSM_Map",
          visible: false
        });

        //Mapa vista Color de Agua
        this.WaterColor = new TileLayer({
            source: new Stamen({
              layer: 'watercolor',
            }),
            visible: false
        });

        //Mapa vista BingMap
        this.bingmap = new TileLayer({
            source: new BingMaps({
                key:'Akf58DQVppAPmtBLUolPS8RS9tBvBWJyyiT4cvgMwZIwMUGsAADIkedByoa6l_K1 ',
                imagerySet:'AerialWithLabelsOnDemand',//AerialWithLabelsOnDemand
            }),
            visible: false
        });

        //Mapa vista RoadMap
        this.bingmapRoad = new TileLayer({
          source: new BingMaps({
              key:'Akf58DQVppAPmtBLUolPS8RS9tBvBWJyyiT4cvgMwZIwMUGsAADIkedByoa6l_K1 ',
              imagerySet:'Road',//AerialWithLabelsOnDemand
          }),
          visible: true
        });

        //Mapa vista Blanco y Negro
        this.ByN = new TileLayer({
          source: new Stamen({
            layer: 'toner',
          }),
          visible: false
        });

        //Mapa vista Terreno
        this.terreno = new TileLayer({
          source: new Stamen({
            layer: 'terrain',
          }),
          visible: false
        });
        
        //Mapa vista Google Maps
        this.GMaps = new TileLayer({
          title: "Google Maps",
          visible: false,
          source: new XYZ({
              url: 'https://mt.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
          })
        });
        
        //Mapa vista GMaps Terreno
        this.GMapsTerreno = new TileLayer({
          title: "Google Terrain",
          visible: false,
          source: new XYZ({
              url: 'https://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}'
          })
        });
        
        //Mapa vista GMaps Satelite
        this.GMapsSatelite = new TileLayer({
          title: "Google Satellite",
          visible: false,
          source: new XYZ({
              url: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
          })
        });

        //Etiquetas del Mapa
        this.Etiquetas = new TileLayer({
          source: new Stamen({
            layer: 'terrain-labels',
          }),
          visible: true
        });
        
        
        //sources
        this.sourceII = new VectorSource({url: 'https://datacatalogo.citdi.uni.edu.pe/sismoisosistas.geojson/5', format: new GeoJSON()});
        this.sourceIII = new VectorSource({url: 'https://datacatalogo.citdi.uni.edu.pe/isosistas.geojson/5', format: new GeoJSON()});

        this.color_line = (intensidad) =>{
            if (intensidad==true) { return 'rgba(255,0,0,0.9)'}
            if (intensidad==false) {return 'rgba(0,0,0,0.0)'}
            if (intensidad=="X") {return 'rgba(199, 0, 3, 0.99)'}
            if (intensidad=="IX") {return 'rgba(220, 220, 220, 0.79)'}
            if (intensidad=="VIII") {return 'rgba(254, 145, 0, 0.99)'}
            if (intensidad=="VII") {return 'rgba(255, 199, 1, 0.99)'}
            if (intensidad=="VI") {return 'rgba(253, 254, 2, 0.99)'}
            if (intensidad=="V") {return 'rgba(127, 247, 149, 0.99)'}
            if (intensidad=="IV") {return 'rgba(128, 254, 253, 0.99)'}
            if (intensidad=="III") {return 'rgba(154, 153, 255, 0.99)'}
            if (intensidad=="II") {return 'rgba(192, 204, 255, 0.99)'}
            if (intensidad=="I") {return 'rgba(233, 255, 232, 0.99)'}
        }
        this.color_line_two = (valores) =>{
          if (valores==true) { return 'rgba(0,0,0,0.99)'}
          if (valores==false) {return 'rgba(0,0,0,0.0)'}
      }
        //Vectores
        this.styleCacheII ={};
        this.vectorII = new VectorLayer({
            name: 'Sismo',
            source: this.sourceII,
            style: (feature) => {
              this.intensidad = feature.get("grafica");
              this.style = this.styleCacheII[this.intensidad];
              if (!this.style) {
                this.style = new Style({
                  image: new RegularShape({
                    points:5,
                    radius: 12,
                    radius2:6,
                    fill: new Fill({color:this.color_line(this.intensidad)}),//new Fill({color: 'rgba(255,0,0,0.9)'}),
                    stroke: new Stroke({color: this.color_line_two(this.intensidad), width: 1.5,}),
                  }),
                });
                this.styleCacheII[this.intensidad] = this.style;              
              }
              return this.style;
            },
            visible: this.state.visible_II
          });
        
        this.styleCacheIII ={};
        this.vectorIII = new VectorLayer({
            name: 'intensidades',
            source: this.sourceIII,
            style: (feature) => {
              this.intensidad = feature.get("intensidad");
              this.style = this.styleCacheIII[this.intensidad];
              if (!this.style) {
                this.style = new Style({
                    fill: new Fill({color: "rgba(0,0,0,0.99)"}),
                    stroke: new Stroke({color: "rgb(255,0,0,0.99)", width:4}),//color:this.color_line(this.intensidad), width: 6,}),
                    text: new Text({
                      text: this.intensidad,
                      font: 'bold 15px arial-black',// sans-serif',
                      offsetX: '-15',
                      offsetY: '-15',
                      fill: new Fill({
                        color: this.state.color_texts
                      }),
                      stroke: new Stroke({
                          color: 'black',
                          lineCap: 'butt',
                          width: 2
                      }),
                    })
                });
                this.text = this.intensidad;
                this.styleCacheIII[this.intensidad] = this.style;              
              }
              return this.style;
            },
            visible: this.state.visible_III
          });
        

        //View
        this.view = new View({
            center: fromLonLat([-76.048, -9.4913]),
            zoom: 6,
            minZoom: 2,
            maxZoom: 28,
        });

        //Control Extend
        this.extend = new ZoomToExtent({
            extent: [
                813079.7791264898, 5929220.284081122,
                848966.9639063801, 5936863.986909639
              ],
            label: "E",
            tipLabel: "Extender para centrar mapa"
        });

        //Dibuja Caracteristicas
        this.source = new VectorSource({wrapX: false});
        this.vector = new VectorLayer({
          source: this.source,
        });

        //Fallas
        this.fallaLayer = new ImageLayer({
          title: 'Geological Faults',
          source: new ImageArcGISRest({
              ratio: 1,
              params: {layers:'show:1'},
              url: "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_NEOTECTONICA/MapServer",
          }),
          visible: false,
        });

        //Mapa
        this.map =  new Map({
            view: this.view,
            controls: [ 
              new Zoom({zoomInTipLabel:'Acercar Mapa', zoomOutTipLabel:'Alejar Mapa'}), 
              new ZoomSlider({duration:100,}), 
              new FullScreen({tipLabel:'Pantalla Completa'}), 
              new ScaleLine({minWidth:100}),
              //new OverviewMap({ layers:[this.OSM,this.WaterColor,this.bingmap,this.ByN,this.terreno],collapsible:true, tipLabel:"Sobre vista del mapa"}),
              new Rotate({autoHide:true, tipLabel:'Orientar Mapa'})],
            layers: [this.OSM, this.WaterColor, this.Etiquetas, this.bingmap, this.bingmapRoad, this.ByN, this.terreno, this.Nocturno, this.GMaps, this.GMapsTerreno, this.GMapsSatelite, this.fallaLayer, this.vectorII, this.vectorIII]
        });

        
        
        //Click sobre una caracteristica
        this.simpleclic = (evt) => {
          this.caracteristicas = this.map.getFeaturesAtPixel(evt.pixel);
          if (this.caracteristicas.length>=1) {
            this.caracteristica = this.caracteristicas[0];
            console.log(this.caracteristica);
            //alert(this.caracteristica.get('name')); 
            if (this.caracteristica.get('tipo')=="ISOSISTA") {
              this.setState({
                Intensidad: this.caracteristica.get('intensidad'),
                value_intensidad: this.caracteristica.get('valueintensidad'),
                EscalaIntensidad: this.caracteristica.get('escalaintensidad'),
                tipo: this.caracteristica.get('tipo'),
                Fecha: this.caracteristica.get('fecha'),
                Nombre: this.caracteristica.get('nombre'),
                Fuente: this.caracteristica.get('fuente'),
                Referencia: this.caracteristica.get('referencia'),
                Latitud:this.caracteristica.get('latitud'),
                Longitud: this.caracteristica.get('longitud'),
                MaxIntensidad: this.caracteristica.get('maxintensidad'),
                Ciudad: this.caracteristica.get('ciudad'),
                matriz_ciudades_isosistas: this.caracteristica.get('ciudades'),
                loading_ciudades_isosistas:false,
                slide_detail:'Caracteristicas_popover_expanded',
              });
            }
            else{
              this.setState({
                Intensidad: this.caracteristica.get('intensidad'),
                tipo: this.caracteristica.get('tipo'),
                Fecha: this.caracteristica.get('fecha'),
                Nombre: this.caracteristica.get('nombre'),
                Fuente: this.caracteristica.get('fuente'),
                Referencia: this.caracteristica.get('referencia'),
                Longitud: this.caracteristica.get('longitud'),
                Latitud:this.caracteristica.get('latitud'),
                MaxIntensidad: this.caracteristica.get('maxintensidad'),
                Ciudad: this.caracteristica.get('ciudad'),
                slide_detail:'Caracteristicas_popover_expanded',
                slide_ciudades:'tabla_ciudades_oculta'
              });
            }
              
            console.log("Id de la isosista seleccionada: ", this.caracteristica.get('id'))
          }
          else{
            this.setState({
              slide_detail:'Caracteristicas_popover',
              slide_ciudades:'tabla_ciudades_oculta'
            })
          }
        };
        this.map.on('singleclick', this.simpleclic);

        //Cambia cursor
        this.cambiacursor = (e) => {
          this.pixel = this.map.getEventPixel(e.originalEvent);
          this.hit = this.map.hasFeatureAtPixel(this.pixel);
          this.map.getTarget().style.cursor = this.hit ?'pointer':'';
        };
        this.map.on('pointermove',this.cambiacursor);

        //GeoPosicionamiento
        this.strokePosition = new Stroke({color: 'rgba(255,0,0,0.9)', width: 5});
        this.fillPosition = new Fill({color: 'rgba(0,255,0,0.9)', width: 5});
        this.geoposition = new Geolocation({
          trackingOptions: { enableHighAccuracy: true },
          projection: this.view.getProjection()
        });

        
    }

    //Obtener Sismos de Isosistas
    async Obtener_SismoIsosistas(){
      await axios.get("https://datacatalogo.citdi.uni.edu.pe/sismosisosistas.geojson")
      .then(response =>{
        console.log(response);
        this.setState({matriz_sismosisosistas: response.data.features, loading_sismosisosistas:false})
      })
      .catch(e => {console.log(e)})
    }

    //Obtener Parametros Sismos de Isosistas
    async Obtener_SismoIsoCentraMapa(ruta_ingresada){
      await axios.get(ruta_ingresada)
      .then(response =>{
        console.log(response);
        this.centrar_mapa(response.data.sismo_lat, response.data.sismo_lon,6);
        //console.log(response.data.features.properties.latitud);
        //this.setState({matriz_sismosisosistas: response.data.features, loading_sismosisosistas:false})
      })
      .catch(e => {console.log(e)})
    }

    //Genera Datos Sismos Isosistas
    Data_SismoIsosistas(){
      if (this.state.loading_sismosisosistas){
        return(<div className="spinner-border text-danger" role="status">
        <span className="sr-only">Loading...</span>
      </div>)
      }
      return(
        this.state.matriz_sismosisosistas.map(
          sismoisosista => <option value={sismoisosista.properties.id} key={sismoisosista.properties.id}>{sismoisosista.properties.ciudad} {sismoisosista.properties.fecha.slice(0,10)}</option>
          // <tr><td>{hipocentro.properties.nombre}</td><td>{hipocentro.properties.latitud} </td><td>{hipocentro.properties.longitud}</td><td>{hipocentro.properties.profundidad}</td><td>{hipocentro.properties.magnitud} {hipocentro.properties.unit_mag}</td><td></td></tr>         
        )
      )
    };

    //Genera la data de las ciudades por donde pasa una isosista
    Data_Ciudades_Isosista(){
      if (this.state.loading_ciudades_isosistas){
        return(<div className="spinner-border text-danger" role="status">
          <span className="sr-only">Loading...</span>
        </div>)
        }
        return(
          this.state.matriz_ciudades_isosistas.map(
            ciudadisosista => <tr><td className="border_tabla_ciudades">{ciudadisosista[0]}</td><td className="border_tabla_ciudades">{ciudadisosista[1]}</td><td className="border_tabla_ciudades">{ciudadisosista[2]}</td><td className="border_tabla_ciudades">{ciudadisosista[3]}</td></tr>
            // <tr><td>{hipocentro.properties.nombre}</td><td>{hipocentro.properties.latitud} </td><td>{hipocentro.properties.longitud}</td><td>{hipocentro.properties.profundidad}</td><td>{hipocentro.properties.magnitud} {hipocentro.properties.unit_mag}</td><td></td></tr>         
          )
        )
    };
    

    componentDidMount ()  {
        this.map.setTarget(this.refs.mapaCatalogo);
        this.Obtener_SismoIsosistas();
        this.Obtener_SismoIsoCentraMapa();
    }

    

    //GeoPosicionamiento
    GeoPositionInMap=()=>{
      this.geoposition.setTracking('true');
      this.geoposition.setProjection();
      // this.geoposition.getPosition();
      this.geoposition.on('change',this.ObtenUbicacion);
      this.setState({boton_geoposition:'Geo_Activo',estado_boton_geoposition:'Activo'});
    };
    ObtenUbicacion=()=>{
      console.log("Aparezco cuando acepta el tracking");
      //console.log(this.geoposition.getPosition());
      this.coordinates= transform(this.geoposition.getPosition(),'EPSG:3857','EPSG:4326');
      this.geoposition.setTracking(false);
      console.log(this.coordinates[0]);
      console.log(this.coordinates[1]);
      this.setState({lat_filter:this.coordinates[1], lon_filter:this.coordinates[0]});
      this.iconFeature = new Feature({
        geometry: new Point(fromLonLat([this.coordinates[0], this.coordinates[1]])),
        tipo_ensayo: 'Geo Posicionamiento',
        name: 'Geo Posicionamiento',
        coordUTM1: parseFloat(this.coordinates[0]).toFixed(4),
        coordUTM2: parseFloat(this.coordinates[1]).toFixed(4),
        fuente: <a href='https://www.linkedin.com/in/joseph-marlon-montero-inga-aa3aa7106/' target='_blank' rel="noopener noreferrer">Fuente del Programador</a>,
      });
      
      this.iconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 46],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          src: 'https://cdn.pixabay.com/photo/2016/01/14/14/37/marker-1140068_960_720.png',
          scale: 0.05
        }),
        stroke: this.strokePosition,
        fill: this.fillPosition,
        text: new Text({
          text: "Tú estas aquí !!",
				  font: 'bold 11px sans-serif',
        })
      });
      
      this.iconFeature.setStyle(this.iconStyle);
      
      this.vectorSourceGP = new VectorSource({
        features: [this.iconFeature]
      });
      
      this.vectorLayerGP = new VectorLayer({
        source: this.vectorSourceGP
      });
      
      this.map.setView(
        new View({
          center: fromLonLat(this.coordinates),
          zoom: 15
        })
      );
      this.map.addLayer(this.vectorLayerGP);
    };

    //Expande o contrae sidebar
    toggle_side(){this.setState({sidebar_state: !this.state.sidebar_state})}
    
    //Activa Desactiva las capas
    capa_intensidadII = () =>{
      if(this.state.visible_II){this.vectorII.setVisible(false);this.setState({visible_II:false, intensidad_II:"capa_inactiva"})}
      else{this.vectorII.setVisible(true);this.setState({visible_II:true, intensidad_II:"capa_activa"})}
    };
    capa_intensidadIII = () =>{
      if(this.state.visible_III){this.vectorIII.setVisible(false);this.setState({visible_III:false, intensidad_III:"capa_inactiva"})}
      else{this.vectorIII.setVisible(true);this.setState({visible_III:true, intensidad_III:"capa_activa"})}
    };
    capa_intensidadIV = () =>{
      if(this.state.visible_IV){this.vectorII.setVisible(false);this.setState({visible_IV:false, intensidad_IV:"capa_inactiva"})}
      else{this.vectorIV.setVisible(true);this.setState({visible_IV:true, intensidad_IV:"capa_activa"})}
    };
    
    //activa capa del mapa de calor
    capa_calor = () => {
      this.vectorHeatMap.setVisible(true);
      this.vectorMagnitud.setVisible(false);
      this.setState({
        mapa_epicentral:"capa_inactiva",
        mapa_calor:"capa_activa"
      })
    }
    //activa capa del mapa epicentral
    capa_hipoc = () => {
      this.vectorHeatMap.setVisible(false);
      this.vectorMagnitud.setVisible(true);
      this.setState({
        mapa_epicentral:"capa_activa",
        mapa_calor:"capa_inactiva"
      })
    }
    //activa capa del mapa epicentral
    capa_fallas = () => {
      if (this.fallaLayer.getVisible()) {
        this.fallaLayer.setVisible(false);
        this.setState({fallas_geo:"capa_inactivac", t_leyenda_fallas:"sin_leyenda", leyenda_fallas:"sin_leyenda"})
      }
      else{
        this.fallaLayer.setVisible(true);
        this.setState({fallas_geo:"capa_activac", t_leyenda_fallas:"titulo_submenu", leyenda_fallas:"leyenda"})
      }      
    }
    //Cambia Mapas
    toggleShowBM=()=>{
      this.bingmap.setVisible(true);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(false);
      this.terreno.setVisible(false);
      this.Nocturno.setVisible(false);
      this.GMaps.setVisible(false);
      this.GMapsTerreno.setVisible(false);
      this.GMapsSatelite.setVisible(false);
    };
    toggleShowBMR=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(true);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(false);
      this.terreno.setVisible(false);
      this.Nocturno.setVisible(false);
      this.GMaps.setVisible(false);
      this.GMapsTerreno.setVisible(false);
      this.GMapsSatelite.setVisible(false);
    };
    toggleShowWC=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(true);
      this.ByN.setVisible(false);
      this.terreno.setVisible(false);
      this.Nocturno.setVisible(false);
      this.GMaps.setVisible(false);
      this.GMapsTerreno.setVisible(false);
      this.GMapsSatelite.setVisible(false);
    };
    toggleShowOSM=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(true);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(false);
      this.terreno.setVisible(false);
      this.Nocturno.setVisible(false);
      this.GMaps.setVisible(false);
      this.GMapsTerreno.setVisible(false);
      this.GMapsSatelite.setVisible(false);
    };
    toggleShowByN=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(true);
      this.terreno.setVisible(false);
      this.Nocturno.setVisible(false);
      this.GMaps.setVisible(false);
      this.GMapsTerreno.setVisible(false);
      this.GMapsSatelite.setVisible(false);
    }
    toggleShowTerreno=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(false);
      this.terreno.setVisible(true);
      this.Nocturno.setVisible(false);
      this.GMaps.setVisible(false);
      this.GMapsTerreno.setVisible(false);
      this.GMapsSatelite.setVisible(false);
    }
    toggleShowNocturno=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(false);
      this.terreno.setVisible(false);
      this.Nocturno.setVisible(true);
      this.GMaps.setVisible(false);
      this.GMapsTerreno.setVisible(false);
      this.GMapsSatelite.setVisible(false);
    }
    toggleShowGMaps=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(false);
      this.terreno.setVisible(false);
      this.Nocturno.setVisible(false);
      this.GMaps.setVisible(true);
      this.GMapsTerreno.setVisible(false);
      this.GMapsSatelite.setVisible(false);
    }
    toggleShowGMapsTerreno=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(false);
      this.terreno.setVisible(false);
      this.Nocturno.setVisible(false);
      this.GMaps.setVisible(false);
      this.GMapsTerreno.setVisible(true);
      this.GMapsSatelite.setVisible(false);
    }
    toggleShowGMapsSatelite=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(false);
      this.terreno.setVisible(false);
      this.Nocturno.setVisible(false);
      this.GMaps.setVisible(false);
      this.GMapsTerreno.setVisible(false);
      this.GMapsSatelite.setVisible(true);
    }


    //Buscador de Direcciones
    cambia_direccion(event) {
      this.setState({value: event.target.value});
      console.log(this.state.value);
    }
    busca_direccion(event) {
      this.convert_direccion(this.state.value);
      event.preventDefault();
    }
    convert_direccion(direccion){
      Geocode.setApiKey("AIzaSyBEN1zVUqxX4a32voiBUrmEl_8BeOIt4_I");
      Geocode.setLanguage("latam");
      Geocode.setRegion("pe");
      Geocode.enableDebug();
      Geocode.fromAddress(direccion).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location;
          console.log(lat, lng);
          this.center_marker(lat,lng,17);
        },
        error => {
          console.error(error);
        }
      );
    }
    center_marker(latitud, longitud, zoom_acercar) {
      this.map.setView(
        new View({
          center: fromLonLat([longitud,latitud]),
          zoom: zoom_acercar
        })
      );
    }
    
    //Hacer Zoom
    hacer_zoom = () =>{
      this.coordenadasS = [this.state.longitud_zoom, this.state.latitud_zoom];
      this.map.setView(
        new View({
          center: fromLonLat(this.coordenadasS),
          zoom: 18
        })
      );
    }

    //Centrar Mapa -70.048 -9.4913 5.5
    centrar_mapa = (lat, lon, zoom) =>{
      this.map.setView(
        new View({
          center: fromLonLat([lon , lat]),
          zoom: zoom ,
        })
      );
    }

    //Close Características
    close_caracteristicas = () => {
      this.setState({slide_detail: 'Caracteristicas_popover', slide_ciudades:'tabla_ciudades_oculta'})
    }
    close_ciudades = () => {
      this.setState({slide_ciudades:'tabla_ciudades_oculta'})
    }


    select_sismo = (event) =>{
      this.setState({id_sismo: event.target.value});
      this.sourceII.setUrl("https://datacatalogo.citdi.uni.edu.pe/sismoisosistas.geojson/"+event.target.value);
      this.sourceII.refresh();
      this.sourceIII.setUrl("https://datacatalogo.citdi.uni.edu.pe/isosistas.geojson/"+event.target.value);
      this.sourceIII.refresh();
      this.setState({slide_detail:"Caracteristicas_popover",slide_ciudades:'tabla_ciudades_oculta'});
      this.Obtener_SismoIsoCentraMapa("https://datacatalogo.citdi.uni.edu.pe/sismoisosistas.geojson/"+event.target.value);
    }

    open_ciudades = () => {
      this.setState({slide_ciudades:'tabla_ciudades'})
    }
    //Abre mapa intensidades
    
    Open_Fuente(){
      if (this.state.Fuente!="-") {
        return(<tr><td><strong>Fuente: </strong></td><td>{this.state.Fuente}</td></tr>)
      }
      else{
        return null
      }
    }          
    Open_Intensidad(){
      if (this.state.tipo=="ISOSISTA") {
        return(<tr><td><strong>Intensidad:</strong></td><td><strong>{this.state.value_intensidad}, </strong><a onClick={this.open_ciudades}>  <small>Ver Ciudades Afectadas </small><FaEye/></a></td></tr>)
      }
      else{
        return(<tr><td><h6><strong>Max. Int.: </strong></h6></td><td>{this.state.MaxIntensidad}</td></tr>)
      }
    }
    Open_Ciudad(){
      if (this.state.tipo=="SISMO") {
        return(<tr><td><strong>Ciudad: </strong></td><td>{this.state.Ciudad}</td></tr>)
      }
      else{
        return null
      }
    }
    Open_Coordenadas(){
      if (this.state.tipo=="SISMO") {
        return(<tr><td><strong>Coordenadas:</strong></td><td>Lat: {this.state.Latitud}, Lon: {this.state.Longitud}</td></tr>)
      }
      else{
        return null
      }
    }
    Open_Escala(){
      if (this.state.tipo=="ISOSISTA") {
        return(<tr><td><strong>Escala:</strong></td><td>{this.state.EscalaIntensidad}</td></tr>)
      }
      else{
        return null
      }
    }
    
    render(){
        return(
          <div className="panel">
            <div className="catalogo">
              <div className="SideNavMap">
                <ProSidebar collapsed={this.state.sidebar_state} breakPoint='xs'>
                  <SidebarHeader>
                    <Menu iconShape="round">
                        <MenuItem icon={<CgHomeAlt />} /*onClick={this.toggle_side}*/ title="Catálogo implementado por el Centro de Investigación en Transformación Digital en Ingeniería"><strong className="titulo_header_side"> <a href="/">C.I.T.D.I.</a></strong></MenuItem>
                      </Menu>
                  </SidebarHeader>
                  <SidebarContent>
                    <Menu iconShape="circle">
                      
                      <SubMenu title="Mapas" className="mapas_titulo" icon={< FaMap />}>
                      <p className="maps_type_web">
                        <button onClick={this.toggleShowBM} title="Mapa Satelital" className="boton_satelital">Satelital</button>
                        <button onClick={this.toggleShowBMR} title="Mapa Vial" className="boton_road">Vial</button>
                        <button onClick={this.toggleShowWC} title="Mapa Fondo de Agua" className="boton_wc">Agua</button>
                        <button onClick={this.toggleShowOSM} title="Mapa Básico" className="boton_osm">OSM</button>
                        <button onClick={this.toggleShowByN} title="Mapa de Fondo Blanco y Negro" className="boton_byn">Binario</button>
                        <button onClick={this.toggleShowTerreno} title="Mapa Vista de Terreno" className="boton_terreno">Terreno</button>
                        <button onClick={this.toggleShowNocturno} title="Mapa Vista Nocturna" className="boton_nocturno">Nocturno</button>
                        <button onClick={this.toggleShowGMaps} title="Mapa Vista Google Maps" className="boton_gmaps">G Maps</button>
                        <button onClick={this.toggleShowGMapsTerreno} title="Mapa Vista Google Maps Terreno" className="boton_gmapsterreno">GM Terreno</button>
                        <button onClick={this.toggleShowGMapsSatelite} title="Mapa Vista Google Maps Satelital" className="boton_gmapssatelite">GM Satelite</button>
                    </p>
                      </SubMenu>
                      <SubMenu title="Capas" className="capas_titulo" icon={<FaLayerGroup />}>
                        <MenuItem onClick={this.capa_intensidadII}> <strong className="titulo_capa"><BsGeo className={this.state.intensidad_II}/> Sismo</strong> </MenuItem>
                        <MenuItem onClick={this.capa_intensidadIII}> <strong className="titulo_capa"><BiStats className={this.state.intensidad_III}/> Isosistas </strong></MenuItem>
                        <MenuItem onClick={this.capa_fallas}> <strong className="titulo_capa"><BiStats className={this.state.fallas_geo}/> Fallas Geológicas </strong> </MenuItem>
                      </SubMenu>
                      <SubMenu title="Buscar Dirección" className="capas_titulo" icon={<FaSearchLocation />}>
                        <form onSubmit={this.busca_direccion} className="form-busca-direccion" title="Escribe tu dirección para encontrarte">
                          Busca una Dirección
                          <label className="label-busca-direccion" title="Escribe tu dirección para encontrarte">
                            <input  className="input-busca-direccion" type="text" value={this.state.value} onChange={this.cambia_direccion} placeholder="Ingresa direción a buscar" title="Escribe tu dirección para encontrarte"/>
                            <FaSearchLocation className="icon-busca-direccion" onClick={this.busca_direccion} title="Escribe tu dirección para encontrarte"/>
                          </label>
                        </form>
                      </SubMenu>
                      <MenuItem icon={<BsGeoAlt />} title="GeoPosicionamiento"  onClick={this.GeoPositionInMap}><strong className="titulo_content_side"> GeoPosicionamiento </strong></MenuItem>
                      <MenuItem icon={<MdCenterFocusStrong/>} title="Centrar Mapa" onClick={()=>this.centrar_mapa(-9.4913, -70.048, 5.5)}><strong className="titulo_content_side">Centrar Mapa</strong></MenuItem>
                    </Menu>
                  </SidebarContent>
                  <SidebarFooter>
                    <Menu iconShape="round">
                      <span className="Fecha"><p className="titulo_leyenda_fallas"><strong >Acceso:</strong></p>
                      {String(Date()).slice(0,32)}</span>
                    </Menu>
                  </SidebarFooter>
                </ProSidebar>
              </div>
              
              <div className="mapa" id="mapaCatalogo" ref="mapaCatalogo"/>

            </div>
            <ModalPage titulo="Guía para la sección" estado={true} src_modal={isosistas_page} texto="Para obtener información de las isosistas, seleccione cualquier linea o el sismo sobre el mapa, para ver las ciudades por donde pasa la isosista, seleccione la opción 'Ver Ciudades'."/>
            <div className="seismic_select">
              <span className="content_detail"><strong>Seleccione Sismo: </strong></span>
              <select className="style_selector" value={this.state.id_sismo} onChange={this.select_sismo}>
                {this.Data_SismoIsosistas()}
              </select>
              <br/><br/>
            </div>
            
            <div className="leyenda_general">
              <h6 className="titulo_leyenda_fallas"><strong>Leyenda</strong></h6>
              <p className="titulo_leyenda_fallas"><td><img className="leyenda3" src={sismo_icon}/></td> <td>Sismo </td>{/*src={leyenda_intensidades}/>*/}</p>
              <p className="titulo_leyenda_fallas"><td><img className="leyenda4" src={isosista_icon}/></td> <td>Isosista </td>{/*src={leyenda_intensidades}/>*/}</p>
              <div className={this.state.leyenda_fallas}>
              <h6 className="titulo_leyenda_fallas"><strong>Fallas Geológicas</strong></h6>
              <img className="leyenda_image_fallas" src={fallas_leyenda}/>             
            </div>          
            </div>
            <div className={this.state.slide_detail}>
              <div className="titulo_detail"><div className="Titulo_d">Caracteristicas:  {this.state.tipo} </div><CgClose className="cerrar_detail" onClick={this.close_caracteristicas}/></div>
              <div className="content_detail">
                <tr><td><strong>Nombre: </strong></td><td>{this.state.Nombre}</td></tr>
                {this.Open_Fuente()}
                <tr><td><strong>Referencia: </strong></td><td>{this.state.Referencia}</td></tr>
                <tr><td><strong>Fecha: </strong></td><td>{this.state.Fecha}</td></tr>
                {this.Open_Ciudad()}
                {this.Open_Coordenadas()}
                {this.Open_Intensidad()}
                {this.Open_Escala()}
              </div>
            </div>
            <div className={this.state.slide_ciudades}>
              <div className="titulo_detail"><div className="Titulo_d">Ciudades Afectadas</div><CgClose className="cerrar_detail" onClick={this.close_ciudades}/></div>
              <div className="espacio_contenido_ciudades">
              <thead> 
              <tr><th className="tittle_tabla_ciudades"><strong>Distrito</strong></th><th className="tittle_tabla_ciudades">Provincia</th><th className="tittle_tabla_ciudades">Departamento</th><th className="tittle_tabla_ciudades">Pais</th></tr>
              </thead>
              <tbody>
              {this.Data_Ciudades_Isosista()}
              </tbody> 
              </div>
            </div>
          </div>
        );
    }
} 