
import React, {Component} from 'react';
import './Estadisticas.css';
import { MDBCol, MDBContainer, MDBRow, MDBBtn, MDBIcon } from "mdbreact";
import { Line, Bar, Bubble } from "react-chartjs-2";

import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";
import { CgViewDay } from 'react-icons/cg';
import { Button } from 'react-bootstrap';

//import axios
import axios from 'axios';


export class Estadisticas extends Component{
  ano=String(new Date().getFullYear()-10);
  constructor(props){
    super(props);
    this.state={
      date_ini: new Date("01/01/1600"),//+this.ano),
      date_end: new Date(),
      loading_data:true,
      data_profunda: [],
      data_superficial:[],
      labels_hipocentrales:[],
      dataLine: {
        labels: [],
        datasets: []
      },
      LineChartOptions:{
        responsive: true,
        title: {
          display: true,
          text: 'Sismos Acumulado por Años',
        },
        scales: {
          xAxes: [
            {scaleLabel: {
            labelString: 'Años',
            display: true
          }}],
          yAxes:[{
            scaleLabel: {
              labelString: 'Cantidad de Sismos',
              display: true
            }
          }]
        }
      },
      dataBar: {
        labels: [],
        datasets: [
          {
            label: "Cantidad de sismos por Profundidad [1600 - 2021]",
            data: [],
            backgroundColor: "rgba(98, 182, 239,0.2)",/* [
              "rgba(255, 134,159,0.4)",
              "rgba(98,  182, 239,0.4)",
              "rgba(255, 218, 128,0.4)",
              "rgba(113, 205, 205,0.4)",
              "rgba(170, 128, 252,0.4)",
              "rgba(255, 177, 101,0.4)"
            ], */
            borderWidth: 1,
            borderColor: "rgba(38, 40, 80,0.99)",/* [
              "rgba(255, 134,159,0.4)",
              "rgba(98,  182, 239,0.4)",
              "rgba(255, 218, 128,0.4)",
              "rgba(113, 205, 205,0.4)",
              "rgba(170, 128, 252,0.4)",
              "rgba(255, 177, 101,0.4)"
            ], */
          }
        ]
      },
      dataBar2: {
        labels: [],
        datasets: [
          {
            label: "Cantidad de sismos por Magnitud [1600 - 2021]",
            data: [],
            backgroundColor: "rgba(98, 182, 239,0.4)",
            borderWidth: 1,
            borderColor: "rgba(38, 40, 80,0.99)",
          }]
      },
      barChartOptions: {
        title: {
          display: false,
          text: 'Frecuencia de Sismos por Magnitud',
        },
        scales: {
          xAxes: [
            {
              barPercentage: 1,
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)"
              },
              scaleLabel: {
                labelString: 'Profundidad (km)',
                display: true
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)"
              },
              ticks: {
                beginAtZero: true
              }
              ,
              scaleLabel: {
                labelString: 'Frecuencia de Sismos',
                display: true
              }
            }
          ]
        }
      },
      barChartOptions2: {
        responsive: true,
        maintainAspectRatio: true,
        title: {
          display: false,
          text: 'Frecuencia de Sismos por Magnitud',
        },
        scales: {
          xAxes: [
            {
              barPercentage: 1,
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)"
              },
              scaleLabel: {
                labelString: 'Magnitud (Mw)',
                display: true
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)"
              },
              ticks: {
                beginAtZero: true
              }
              ,
              scaleLabel: {
                labelString: 'Frecuencia de Sismos',
                display: true
              }
            }
          ]
        }
      },
      dataBubble: {
        labels: [],
        datasets: [
          {
            label: 'Sismos: Magnitud vs Profundidad [1600 - 2021]',
            //   fill: false,
            lineTension: 0.2,
            backgroundColor: 'rgba(98, 182, 239, 0.2)',
            borderColor: 'rgba(38, 40, 80, 0.9)',
            //  borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 0.5,
            pointHitRadius: 2,
            data: [],
          },
        ],
      },
      BubleOptions:{
        responsive: true,
        title: {
          display: false,
          text: 'Gráfico de Lineas',
        },
        scales: {
          xAxes: [
            {scaleLabel: {
            labelString: 'Profundidad (km)',
            display: true
          }}],
          yAxes:[{
            scaleLabel: {
              labelString: 'Magnitud (Mw)',
              display: true
            }
          }]
        }
      },
      LineOptions:{
        responsive: true,
        title: {
          display: false,
          text: 'Sismos Acumulados por Año y Magnitud',
        },
        scales: {
          xAxes: [
            {scaleLabel: {
            labelString: 'Años',
            display: true
          }}],
          yAxes:[{
            scaleLabel: {
              labelString: 'Sismos Acumulados',
              display: true
            }
          }]
        }
      }
    }
    this.filtrar_source=this.filtrar_source.bind(this)
  };
  
  async Obtener_Estadisticas(ruta_ingresada){
    await axios.get(ruta_ingresada)
    .then(response =>{
      console.log(response);
      this.setState({ 
        dataBar: {
          labels: response.data.rango_profundidades,
          datasets: [
            {
              label: response.data.enunciado_sis_prof,
              data: response.data.rango_sismos_profun,
              backgroundColor: "rgba(98, 182, 239,0.4)",
              borderWidth: 1,
              borderColor: "rgba(38, 40, 80,0.99)",
            }
          ]
        },
        dataBar2: {
          labels: response.data.rango_magnitudes,
          datasets: [
            {
              label: response.data.enunciado_sis_mag,
              data: response.data.rango_sismos_mag,
              backgroundColor: "rgba(98, 182, 239,0.4)",
              borderWidth: 1,
              borderColor: "rgba(38, 40, 80,0.99)",
            }
          ]
        },
        dataBubble: {
          datasets: [
            {
              label: response.data.enunciado_mag_prof,
              //   fill: false,
              lineTension: 0.2,
              backgroundColor: 'rgba(98, 182, 239, 0.2)',
              borderColor: 'rgba(38, 40, 80, 0.9)',
              //  borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: 'rgba(75,192,192,1)',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: 'rgba(75,192,192,1)',
              pointHoverBorderColor: 'rgba(220,220,220,1)',
              pointHoverBorderWidth: 2,
              pointRadius: 0.5,
              pointHitRadius: 2,
              data: response.data.magnitud_profundidad,
            },
          ],
        },
        dataLine: {
          labels: response.data.rango_acumulado.anos,
          datasets: [
            {
              label: "<3Mw",
              fill: false,
              lineTension: 0.3,
              backgroundColor: "rgba(81, 209, 246, 0.99)",
              borderColor: "rgba(81, 209, 246, 0.99)",
              borderCapStyle: "butt",
              borderDash: [1, 4],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "rgba(81, 209, 246, 0.99)",
              pointBackgroundColor: "rgba(81, 209, 246, 0.99)",
              pointBorderWidth: 10,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "rgb(0, 0, 0)",
              pointHoverBorderColor: "rgba(81, 209, 246, 0.99)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: response.data.rango_acumulado.rango1
            },
            {
              label: "3-4Mw",
              fill: false,
              lineTension: 0.3,
              backgroundColor: "rgba(57, 255, 20, 0.99)",
              borderColor: "rgba(57, 255, 20, 0.99)",
              borderCapStyle: "butt",
              borderDash: [1,3],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "rgba(57, 255, 20, 0.99)",
              pointBackgroundColor: "rgba(57, 255, 20, 0.99)",
              pointBorderWidth: 10,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "rgba(0, 0, 0)",
              pointHoverBorderColor: "rgba(220, 220, 220, 1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: response.data.rango_acumulado.rango2
            },
            {
              label: "4-5Mw",
              fill: false,
              lineTension: 0.3,
              backgroundColor: "rgba(0, 128, 0, 0.99)",
              borderColor: "rgba(0, 128, 0, 0.99)",
              borderCapStyle: "butt",
              borderDash: [1,3],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "rgba(0, 128, 0, 0.99)",
              pointBackgroundColor: "rgba(0, 128, 0, 0.99)",
              pointBorderWidth: 10,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "rgba(0, 0, 0)",
              pointHoverBorderColor: "rgba(220, 220, 220, 1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: response.data.rango_acumulado.rango3
            },
            {
              label: "5-6Mw",
              fill: false,
              lineTension: 0.3,
              backgroundColor: "rgba(255, 255, 0, 0.99)",
              borderColor: "rgba(255, 255, 0, 0.99)",
              borderCapStyle: "butt",
              borderDash: [1,2],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "rgba(255, 255, 0, 0.99)",
              pointBackgroundColor: "rgba(255, 255, 0, 0.99)",
              pointBorderWidth: 10,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "rgba(0, 0, 0)",
              pointHoverBorderColor: "rgba(220, 220, 220, 1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: response.data.rango_acumulado.rango4
            },
            {
              label: "6-7Mw",
              fill: false,
              lineTension: 0.3,
              backgroundColor: "rgba(255, 128, 0, 0.99)",
              borderColor: "rgba(255, 128, 0, 0.99)",
              borderCapStyle: "butt",
              borderDash: [1,2],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "rgba(255, 128, 0, 0.99)",
              pointBackgroundColor: "rgba(255, 128, 0, 0.99)",
              pointBorderWidth: 10,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "rgba(0, 0, 0)",
              pointHoverBorderColor: "rgba(220, 220, 220, 1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: response.data.rango_acumulado.rango5
            },
            {
              label: "7-8Mw",
              fill: false,
              lineTension: 0.3,
              backgroundColor: "rgba(213, 0, 249, 0.99)",
              borderColor: "rgba(213, 0, 249, 0.99)",
              borderCapStyle: "butt",
              borderDash: [1,2],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "rgba(213, 0, 249, 0.99)",
              pointBackgroundColor: "rgba(213, 0, 249, 0.99)",
              pointBorderWidth: 10,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "rgba(0, 0, 0)",
              pointHoverBorderColor: "rgba(220, 220, 220, 1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: response.data.rango_acumulado.rango6
            },
            {
              label: ">8Mw",
              fill: false,
              lineTension: 0.3,
              backgroundColor: "rgba(255, 0, 0, 0.99)",
              borderColor: "rgba(255, 0, 0, 0.99)",
              borderCapStyle: "butt",
              borderDash: [1,2],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "rgba(255, 0, 0, 0.99)",
              pointBackgroundColor: "rgba(255, 0, 0, 0.99)",
              pointBorderWidth: 10,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "rgba(0, 0, 0)",
              pointHoverBorderColor: "rgba(220, 220, 220, 1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: response.data.rango_acumulado.rango7
            }
          ]
        },
        // matriz_hipocentros: response.data.features, 
        loading_data:false
      })
    })
    .catch(e => {console.log(e)})
  }
  
  async Estadisticas_Iniciales(){
    await axios.get("https://datacatalogo.citdi.uni.edu.pe/estadisticas_totales/date_ini="+this.state.date_ini.toISOString().slice(0,10)+"&&date_end="+this.state.date_end.toISOString().slice(0,10))
    .then(response =>{
      console.log(response);
      this.setState({ 
        dataBar: {
          labels: response.data.rango_profundidades,
          datasets: [
            {
              label: response.data.enunciado_sis_prof,
              data: response.data.rango_sismos_profun,
              backgroundColor: "rgba(98, 182, 239,0.4)",
              borderWidth: 1,
              borderColor: "rgba(38, 40, 80,0.99)",
            }
          ]
        },
        dataBar2: {
          labels: response.data.rango_magnitudes,
          datasets: [
            {
              label: response.data.enunciado_sis_mag,
              data: response.data.rango_sismos_mag,
              backgroundColor: "rgba(98, 182, 239,0.4)",
              borderWidth: 1,
              borderColor: "rgba(38, 40, 80,0.99)",
            }
          ]
        },
        dataBubble: {
          datasets: [
            {
              label: response.data.enunciado_mag_prof,
              //   fill: false,
              lineTension: 0.2,
              backgroundColor: 'rgba(98, 182, 239, 0.2)',
              borderColor: 'rgba(38, 40, 80, 0.9)',
              //  borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: 'rgba(75,192,192,1)',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: 'rgba(75,192,192,1)',
              pointHoverBorderColor: 'rgba(220,220,220,1)',
              pointHoverBorderWidth: 2,
              pointRadius: 0.5,
              pointHitRadius: 2,
              data: response.data.magnitud_profundidad,
            },
          ],
        },
        dataLine: {
          labels: response.data.rango_acumulado.anos,
          datasets: [
            {
              label: "<3Mw",
              fill: false,
              lineTension: 0.3,
              backgroundColor: "rgba(81, 209, 246, 0.99)",
              borderColor: "rgba(81, 209, 246, 0.99)",
              borderCapStyle: "butt",
              borderDash: [1, 4],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "rgba(81, 209, 246, 0.99)",
              pointBackgroundColor: "rgba(81, 209, 246, 0.99)",
              pointBorderWidth: 10,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "rgb(0, 0, 0)",
              pointHoverBorderColor: "rgba(81, 209, 246, 0.99)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: response.data.rango_acumulado.rango1
            },
            {
              label: "3-4Mw",
              fill: false,
              lineTension: 0.3,
              backgroundColor: "rgba(57, 255, 20, 0.99)",
              borderColor: "rgba(57, 255, 20, 0.99)",
              borderCapStyle: "butt",
              borderDash: [1,3],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "rgba(57, 255, 20, 0.99)",
              pointBackgroundColor: "rgba(57, 255, 20, 0.99)",
              pointBorderWidth: 10,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "rgba(0, 0, 0)",
              pointHoverBorderColor: "rgba(220, 220, 220, 1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: response.data.rango_acumulado.rango2
            },
            {
              label: "4-5Mw",
              fill: false,
              lineTension: 0.3,
              backgroundColor: "rgba(0, 128, 0, 0.99)",
              borderColor: "rgba(0, 128, 0, 0.99)",
              borderCapStyle: "butt",
              borderDash: [1,3],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "rgba(0, 128, 0, 0.99)",
              pointBackgroundColor: "rgba(0, 128, 0, 0.99)",
              pointBorderWidth: 10,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "rgba(0, 0, 0)",
              pointHoverBorderColor: "rgba(220, 220, 220, 1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: response.data.rango_acumulado.rango3
            },
            {
              label: "5-6Mw",
              fill: false,
              lineTension: 0.3,
              backgroundColor: "rgba(255, 255, 0, 0.99)",
              borderColor: "rgba(255, 255, 0, 0.99)",
              borderCapStyle: "butt",
              borderDash: [1,2],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "rgba(255, 255, 0, 0.99)",
              pointBackgroundColor: "rgba(255, 255, 0, 0.99)",
              pointBorderWidth: 10,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "rgba(0, 0, 0)",
              pointHoverBorderColor: "rgba(220, 220, 220, 1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: response.data.rango_acumulado.rango4
            },
            {
              label: "6-7Mw",
              fill: false,
              lineTension: 0.3,
              backgroundColor: "rgba(255, 128, 0, 0.99)",
              borderColor: "rgba(255, 128, 0, 0.99)",
              borderCapStyle: "butt",
              borderDash: [1,2],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "rgba(255, 128, 0, 0.99)",
              pointBackgroundColor: "rgba(255, 128, 0, 0.99)",
              pointBorderWidth: 10,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "rgba(0, 0, 0)",
              pointHoverBorderColor: "rgba(220, 220, 220, 1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: response.data.rango_acumulado.rango5
            },
            {
              label: "7-8Mw",
              fill: false,
              lineTension: 0.3,
              backgroundColor: "rgba(213, 0, 249, 0.99)",
              borderColor: "rgba(213, 0, 249, 0.99)",
              borderCapStyle: "butt",
              borderDash: [1,2],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "rgba(213, 0, 249, 0.99)",
              pointBackgroundColor: "rgba(213, 0, 249, 0.99)",
              pointBorderWidth: 10,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "rgba(0, 0, 0)",
              pointHoverBorderColor: "rgba(220, 220, 220, 1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: response.data.rango_acumulado.rango6
            },
            {
              label: ">8Mw",
              fill: false,
              lineTension: 0.3,
              backgroundColor: "rgba(255, 0, 0, 0.99)",
              borderColor: "rgba(255, 0, 0, 0.99)",
              borderCapStyle: "butt",
              borderDash: [1,2],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "rgba(255, 0, 0, 0.99)",
              pointBackgroundColor: "rgba(255, 0, 0, 0.99)",
              pointBorderWidth: 10,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "rgba(0, 0, 0)",
              pointHoverBorderColor: "rgba(220, 220, 220, 1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: response.data.rango_acumulado.rango7
            }
          ]
        },
        // matriz_hipocentros: response.data.features, 
        loading_data:false
      })
    })
    .catch(e => {console.log(e)})
  }

  componentDidMount(){
    this.Obtener_Estadisticas();
    this.Estadisticas_Iniciales()
  }

  handleDateChange = (value) =>{
    console.log("Selecciona Fecha", value)
    this.setState({
      date_ini: value
    })
  }

  handleDateSelect = (value) =>{
    console.log("Selecciona Fecha", value)
    this.setState({
      date_ini: value
    })
  }
  handleDateChange_end = (value) =>{
    console.log("Selecciona Fecha", value);
    this.setState({
      date_end: value
    })
  }

  handleDateSelect_end = (value) =>{
    console.log("Selecciona Fecha", value)
    this.setState({
      date_end: value
    })
  }
  filtrar_source(){
    this.Obtener_Estadisticas("https://datacatalogo.citdi.uni.edu.pe/estadisticas_totales/date_ini="+this.state.date_ini.toISOString().slice(0,10)+"&&date_end="+this.state.date_end.toISOString().slice(0,10));
  }
  /* inicia (){
    this.Obtener_Estadisticas("https://datacatalogo.citdi.uni.edu.pe/estadisticas_totales")
  } */
  render(){
    
    return(
      <div className="principal">
        {/* {this.inicia()} */}
        <MDBContainer >
          <MDBRow md="12" className="grafica_t">
          <MDBCol md="2" className="col_dates">
            <strong className="Titulo_Filter mt-1 py-2">Rango por Fecha</strong>
          </MDBCol>
          <MDBCol md="4" className="col_dates">
            <strong>Inicio: </strong>
            <DatePicker
              selected={this.state.date_ini}
              onSelect={this.handleDateSelect} //when day is clicked
              onChange={this.handleDateChange} //only when value has changed
              
              className = "date_date"
              dateFormatCalendar = "yyyy"
              showYearPicker
            />
            </MDBCol>
            <MDBCol md="4" className="col_dates">
            <strong>Final: </strong>
            <DatePicker
              selected={this.state.date_end}
              onSelect={this.handleDateSelect_end} //when day is clicked
              onChange={this.handleDateChange_end} //only when value has changed
              
              className = "date_date"
              dateFormatCalendar = "yyyy"
              showYearPicker
            />
            </MDBCol>
            <MDBCol md="2" className="col_dates_boton">
              <MDBBtn color="elegant" rounded size="md" onClick={this.filtrar_source}>
                <MDBIcon far icon="eye" className="left" /> Filtrar
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6" className="grafica">
            <h6 className="mt-1 py-1 graph_title"><strong>Frecuencia de Sismos por Profundidad</strong></h6>
              <Bar width={100} height={54} data={this.state.dataBar} options={this.state.barChartOptions} />
              {/* <h3 className="mt-1 py-2">Sismos según Hipocentro</h3> */}
              {/* <Line data={this.state.dataLine} options={this.state.LineChartOptions} /> */}
            </MDBCol>
            <MDBCol md="6" className="grafica">
              <h6 className="mt-1 py-1 graph_title"><strong>Frecuencia de Sismos por Magnitud</strong></h6>
              <Bar width={100} height={54} data={this.state.dataBar2} options={this.state.barChartOptions2} />
            </MDBCol>
          </MDBRow> 
          <MDBRow>
            <MDBCol md="6" className="grafica">
              <h6 className="mt-1 graph_title"><strong>Magnitud de Sismos por Profundidad</strong></h6>
              <Bubble width={100} height={55} data={this.state.dataBubble} options={this.state.BubleOptions}/>
              {/* <Line data={this.state.dataLine} options={{ responsive: true }} /> */}
            </MDBCol>
            <MDBCol md="6" className="grafica">
              <h6 className="mt-1 graph_title"><strong>Sismos Acumulados por Años y Magnitud</strong></h6>
              <Line width={100} height={55} data={this.state.dataLine} options={this.state.LineOptions} />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
      );
    }
}