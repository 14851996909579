import React, { Component } from 'react';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { Button } from 'react-bootstrap';

class ModalPage extends Component {
    constructor(props){
    super(props);
    this.state = {
        modal14: props.estado
      }
    };


toggle = nr => () => {
  let modalNumber = 'modal' + nr
  this.setState({
    [modalNumber]: !this.state[modalNumber]
  });
}

render() {
  return (
      <MDBContainer>
        <button color="danger" type="button" className="btn button boton_encima" onClick={this.toggle(14)}>+</button>
        <MDBModal isOpen={this.state.modal14} toggle={this.toggle(14)} size="lg" centered>{/* side position="top-right">*/}
            <MDBModalHeader className="tittle_modal" toggle={this.toggle(14)}>
               <div md="12" className="text_title_modal">{this.props.titulo}</div>
            </MDBModalHeader>
          <MDBModalBody>
          <img src={this.props.src_modal} class="images_modal_small" alt="Responsive image"/>
          <br/><br/>
          <p className="content_modal">{this.props.texto}</p>
          </MDBModalBody>
          <MDBModalFooter>
            <Button className="button_modal danger" variant="danger" onClick={this.toggle(14)}>CERRAR</Button>
            {/* <MDBBtn color="primary">Save changes</MDBBtn> */}
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default ModalPage;