import React, { Component } from 'react';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { Button } from 'react-bootstrap';

class ModalPageIntro extends Component {
    constructor(props){
    super(props);
    this.state = {
        modal15: props.estado
      }
    };


toggle = nr => () => {
  let modalNumber = 'modal' + nr
  this.setState({
    [modalNumber]: !this.state[modalNumber]
  });
}

render() {
  return (
      <MDBContainer>
        
        <MDBModal isOpen={this.state.modal15} toggle={this.toggle(15)} centered>
            <MDBModalHeader className="tittle_modal" toggle={this.toggle(15)}>
               <div md="12" className="text_title_modal_intro">{this.props.titulo}</div>
            </MDBModalHeader>
          <MDBModalBody>
          
          <p className="content_modal_intro">
            El Colegio de Ingenieros del Perú – Consejo Departamental Lima, a través del Capítulo de Ingeniería Civil, la Comisión de Riesgos de Desastres y el Centro de Investigación y Desarrollo Allpanchis – CIDALL, les da la más cordial bienvenida a la <strong>Plataforma Virtual</strong> de la <strong>Encuesta de Vulnerabilidad Sísmica para Edificaciones de Albañilería </strong>.
            <br/><br/>
            <p className='text_center_images_modal'>
              <img src={this.props.src_modal} class="images_modal_intro2b" alt="Responsive image"/>
              <img src={this.props.src_modal2} class="images_modal_intro2" alt="Responsive image"/>
            </p> 
          </p>
          
          </MDBModalBody>
          <MDBModalFooter>
            <div className="footer_modal">
                <Button className="button_modal danger" variant="danger" onClick={this.toggle(15)}>CERRAR</Button>
            </div>
            {/* <MDBBtn color="primary">Save changes</MDBBtn> */}
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default ModalPageIntro;