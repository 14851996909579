
import React, {Component} from 'react';
import './Home.css';
//import Pdf from "react-to-pdf";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FaGem, FaHeart } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import {Nav} from 'react-bootstrap';

import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBView, MDBMask, MDBCardTitle, MDBCardText, MDBBtn, MDBAnimation, MDBIcon, MDBCardImage, MDBCarouselInner,MDBCarouselItem, MDBCarousel, MDBCarouselCaption, MDBNavLink } from 'mdbreact';


// const ref = React.createRef();

/* import ImageGallery from 'react-image-gallery';
//import 'react-image-gallery/styles/scss/image-gallery.scss';
import 'react-image-gallery/styles/css/image-gallery.css';

const images = [
  {
    original: 'https://picsum.photos/id/1018/1000/600/',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
  },
  {
    original: 'https://picsum.photos/id/1015/1000/600/',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
  },
  {
    original: 'https://picsum.photos/id/1019/1000/600/',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  },
]; */


export class Home extends Component{
  constructor(props){
    super(props);
    this.state={
      toggle_sidebar: true,
    }
    this.togge_side = this.togge_side.bind(this);
  };

  togge_side(){
    this.setState({toggle_sidebar: !this.state.toggle_sidebar});
  };

    render(){
      return(
        <div className="principal_home">
          
          <section className="text-center my-5">
    {/* <h2 className="h1-responsive font-weight-bold my-5">
      Our best projects
    </h2>
    <p className="grey-text w-responsive mx-auto mb-5">
      Duis aute irure dolor in reprehenderit in voluptate velit esse
      cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
      cupidatat non proident, sunt in culpa qui officia deserunt mollit
      est laborum.
    </p> */}
    <MDBContainer  className="d-flex flex-wrap">
      <MDBCol md="12" className="mx-auto mb-4">
        <MDBCard className="card-image card_principal" >
          <div className="text-white text-center d-flex align-items-center rgba-black-light py-5 px-4 rounded">
            <div>
              <h5 className="texto-rojo py-4 h3 font-weight-bold">
                <MDBIcon icon="map" />
                <strong> Encuesta de Vulnerabilidad Sísmica</strong>
              </h5>
              <h3 className="py-5 font-weight-bold">
                <strong>Bienvenidos a la <br/>Encuesta de Vulnerabilidad Sísmica para Edificaciones de Albañilería </strong>
              </h3>
              <p className="pb-5 mx-4 font-weight-bold h5">
                El Colegio de Ingenieros del Perú - Consejo Departamental de Lima y el CIDALL (Centro de Investigación y Desarrollo "Allpanchis"), les invitan a realizar la encuesta de vulnerabilidad de su edificación. 
              </p>
              <MDBBtn color="red" rounded size="md">
                <Nav.Link href="/Encuesta" className="navlink-portada"><MDBIcon far icon="eye" className="left"/> Ir a la Encuesta</Nav.Link>
              </MDBBtn>
            </div>
          </div>
        </MDBCard>
      </MDBCol>
      {/* <MDBCol md="6" className="md-0 mb-4">
        <h6 className="blue-text py-1"> <MDBIcon icon="th-large" /><strong> Secciones del Portal</strong></h6>
        <MDBCarousel
        activeItem={1}
        length={3}
        showControls={true}
        showIndicators={true}
        className="z-depth-1"
        interval="2000"
      >
        <MDBCarouselInner>
          <MDBCarouselItem itemId="1">
          <MDBCard className="card-image card-file">
            <div className="text-white text-center d-flex align-items-center rgba-black-light py-3 px-4 rounded">
              <div>
                <h5 className="pink-text">
                  <MDBIcon icon="chart-pie" />
                  <strong> Documentación</strong>
                </h5>
                <h3 className="py-3 font-weight-bold">
                  <strong>Documentación</strong>
                </h3>
                <p className="pb-3">
                  Describe los conceptos claves, empleados en el Catálogo de Intensidades.
                </p>
                <MDBBtn color="pink" rounded size="md">
                  <MDBNavLink className="navlink-portada" to="/Documentacion" >
                    <MDBIcon far icon="eye" className="left" /> Visitar
                  </MDBNavLink>
                </MDBBtn>
              </div>
            </div>
          </MDBCard>
          </MDBCarouselItem>
          <MDBCarouselItem itemId="2">
          <MDBCard className="card-image card-catalogo">
            <div className="text-white text-center d-flex align-items-center rgba-black-light py-3 px-4 rounded">
              <div>
                <h5 className="green-text">
                  <MDBIcon icon="eye" />
                  <strong> Catálogo</strong>
                </h5>
                <h3 className="py-3 font-weight-bold">
                  <strong>Catálogo Sísmico</strong>
                </h3>
                <p className="pb-3">
                  Muestra el catálogo de intensidades sismica de América del Sur, el cual fue recopilado y calculado por el CITDI.
                </p>
                <MDBBtn color="success" rounded size="md">
                  <MDBNavLink className="navlink-portada" to="/Catalogo" >
                    <MDBIcon far icon="eye" className="left" /> 
                    Acceder
                  </MDBNavLink>
                </MDBBtn>
              </div>
            </div>
          </MDBCard>
          </MDBCarouselItem>
          <MDBCarouselItem itemId="3">
            <MDBCard className="card-image card-equipo">
            <div className="text-white text-center d-flex align-items-center rgba-black-light py-3 px-4 rounded">
              <div>
                <h5 className="blue-text">
                  <MDBIcon icon="eye" />
                  <strong> Equipo de Trabajo</strong>
                </h5>
                <h3 className="py-3 font-weight-bold">
                  <strong>Desarrolladores</strong>
                </h3>
                <p className="pb-3">
                  Muestra al personal encargado de la recopilación de información sismica y desarrollo del portal.
                </p>
                <MDBBtn color="primary" rounded size="md">
                  <MDBNavLink className="navlink-portada" to="/Equipo" >
                    <MDBIcon far icon="eye" className="left" /> Ver
                  </MDBNavLink>
                </MDBBtn>
              </div>
            </div>
          </MDBCard>
          </MDBCarouselItem>
        </MDBCarouselInner>
        </MDBCarousel>
    
      </MDBCol>
      <MDBCol md="6" className="md-0 mb-4">
      <h6 className="pink-text py-1"> <MDBIcon icon="building" /><strong> Instituciones</strong></h6>
      <MDBCarousel
      activeItem={1}
      length={3}
      showControls={true}
      showIndicators={true}
      className="z-depth-1"
      interval="3500"
    >
      <MDBCarouselInner>
        <MDBCarouselItem itemId="1">
        <MDBCard className="card-image card-uni">
          <div className="text-white text-center d-flex align-items-center rgba-black-light py-3 px-4 rounded">
            <div>
              <h5 className="pink-text">
                <MDBIcon icon="chart-pie" />
                <strong> UNI</strong>
              </h5>
              <h3 className="py-3 font-weight-bold">
                <strong>C.I.T.D.I - UNI</strong>
              </h3>
              <p className="pb-3">
                Centro de Transformación Digital en Ingeniería de la Universidad Nacional de Ingeniería CITDI - UNI.
              </p>
              <MDBBtn color="pink" rounded size="md" href="http://www.red-acelerografica-peru.uni.edu.pe" target="_blank">
                <MDBIcon far icon="eye" className="left" /> Visitar
              </MDBBtn>
            </div>
          </div>
        </MDBCard>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="2">
        <MDBCard className="card-image card-one">
          <div className="text-white text-center d-flex align-items-center rgba-black-light py-3 px-4 rounded">
            <div>
              <h5 className="green-text">
                <MDBIcon icon="eye" />
                <strong> Redes</strong>
              </h5>
              <h3 className="py-3 font-weight-bold">
                <strong>Redes de Monitoreo</strong>
              </h3>
              <p className="pb-3">
              Redes de Monitoreo en Ciencias de la Tierra - CITDI - Red de Acelerografos Perú.
              </p>
              <MDBBtn color="success" rounded size="md" href="http://www.red-acelerografica-peru.uni.edu.pe/es/simple/stations" target="_blank">
                <MDBIcon far icon="eye" className="left" /> 
                Acceder
              </MDBBtn>
            </div>
          </div>
        </MDBCard>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="3">
          <MDBCard className="card-image adjust_image card-ceresis">
          <div className="text-white text-center d-flex align-items-center rgba-black-light py-3 px-4 rounded">
            <div>
              <h6 className="blue-text">
                <MDBIcon icon="eye" />
                <strong> CERESIS</strong>
              </h6>
              <h3 className="py-3 font-weight-bold">
                <strong>CERESIS</strong>
              </h3>
              <p className="pb-3">
              Centro Regional de Sismología para América del Sur (CERESIS) es un Organismo Internacional, creado el año 1966.
              </p>
              <MDBBtn color="primary" rounded size="md" href="http://www.ceresis.org/" target="_blank">
                <MDBIcon far icon="eye" className="left" /> Ver
              </MDBBtn>
            </div>
          </div>
        </MDBCard>
        </MDBCarouselItem>
      </MDBCarouselInner>
    </MDBCarousel>
    

      </MDBCol>
      <MDBCol md="12" className="md-0 mb-4">
        <br/>
        <h4 className="black-text"><strong>APLICACIONES SIMILARES</strong></h4>
      </MDBCol>
      <MDBCol md="4" className="md-0 mb-4">
        <MDBCard className="card-image adjust_image card-acelerograma">
          <div className="text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4 rounded">
            <div>
              <h5 className="blue-text">
                <MDBIcon icon="globe-americas" />
                <strong> Red Acelerográfica </strong>
              </h5>
              <h3 className="py-4 font-weight-bold">
                <strong>Red Acelerográfica</strong>
              </h3>
              <p className="pb-3 py-5">
                Red de Monitoreo Sísmico que tiene como objetivo principal generar un mayor conocimiento del comportamiento dinámico de los suelos
              </p>
              <MDBBtn color="primary" rounded size="md" href="http://www.red-acelerografica-peru.uni.edu.pe/es/simple/stations" target="_blank">
                <MDBIcon far icon="eye" className="left" /> Visitar
              </MDBBtn>
            </div>
          </div>
        </MDBCard>
      </MDBCol>
      <MDBCol md="4" className="md-0 mb-4">
        <MDBCard className="card-image adjust_image card-vulnerab">
          <div className="text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4 rounded">
            <div>
              <h5 className="red-text">
                <MDBIcon icon="home" />
                <strong> Vulnerabilidad</strong>
              </h5>
              <h3 className="py-4 font-weight-bold">
                <strong>Vulnerabilidad Sísmica</strong>
              </h3>
              <p className="pb-3 py-5">
              Plataforma virtual de vulnerabilidad sísmica de edificaciones, aplicando tecnologías de información, desarrollado por la Red Acelerográfica.
              </p>
              <MDBBtn color="danger" rounded size="md" href="https://vulnerabilidad-sismica.uni.edu.pe" target="_blank">
                <MDBIcon far icon="eye" className="left" /> Acceder
              </MDBBtn>
            </div>
          </div>
        </MDBCard>
      </MDBCol>
      <MDBCol md="4" className="md-0 mb-4">
        <MDBCard className="card-image adjust_image card-cursos">
          <div className="text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4 rounded">
            <div>
              <h5 className="blue-text">
                <MDBIcon icon="eye" />
                <strong> Aula Virtual</strong>
              </h5>
              <h3 className="py-4 font-weight-bold">
                <strong>e-Learning C.I.T.D.I</strong>
              </h3>
              <p className="pb-4 py-5">
                Plataforma Virtual de Aprendizaje implementado por el Centro de Investigación en Transformación Digital en Ingeniería - Red Acelerográfica.
              </p>
              <MDBBtn color="primary" rounded size="md" href="https://red-acelerografica-meet.ga/moodle/" target="_blank">
                <MDBIcon far icon="eye" className="left" /> Matricula
              </MDBBtn>
            </div>
          </div>
        </MDBCard>
      </MDBCol> */}
    </MDBContainer>
  </section>




            </div>
        );
    }
}