import React, {Component} from 'react';
import { Button } from 'react-bootstrap';
import './Nosotros.css';
import { MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink, MDBIcon, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBBtn, MDBView, MDBCardImage, MDBCard, MDBAnimation, MDBRow, MDBMask } from "mdbreact";
import {CardsInstituciones} from './CardsInstituciones';

import axios from 'axios';

export class Instituciones extends Component{
    constructor(props){
        super(props);
        this.state = {
            desliza: "no_deslizador",
            activeItemClassicTabs3: "1",
            institucion:[],
        }
    };
    
    async institu(){
        await axios.get('encuesta/instituciones')
        .then(response =>{
          console.log(response.data);
          this.setState({
            institucion: response.data
          })
        })
        .catch(e => {})
      }
    
    componentDidMount(){
        this.institu();
    }
    render(){
        return(
            <div className="documentacion_nosotros">
                <MDBContainer>
                    <MDBCol md={12}>
                        <h3 className="Titulo_Equipo">Instituciones Aportantes</h3>
                    </MDBCol>
                    <Institucione institucion={this.state.institucion}/>
                    
                </MDBContainer>
            </div>
        );
    }
}

function Institucione(props){
    return(<MDBCol md={12}>
        {props.institucion.map(({linkcontacto,nombre,description,maps,url_maps,image})=><CardsInstituciones 
        cardone = "9"
        cardtwo = "3"
        linkcontacto={linkcontacto}
        nombre = {nombre}
        descripcion = {description}
        maps = {maps}
        url_maps = {url_maps}
        url_photo = {"https://eval-vulnerabilidad.cdlima.org.pe/"+image}
    />)}
    </MDBCol>
    )
}