import React from 'react';
import logo from './logo.svg';
import './App.css';

import {Home} from './components/HomeVulne/Home';
//import {Navegador} from './components/NavBar/Navegador';
import {Documentacion} from './components/DocumentacionVulne/Documentacion';
import {Encuesta} from './components/Encuesta/Encuesta';
import {Catalogo} from './components/Catalogo/Catalogo';
import {Estadisticas} from './components/Estadisticas/Estadisticas';
import {Team} from './components/Nosotros/Team';
import {Instituciones} from './components/Nosotros/Instituciones';
import Mapavulnerabilidad from './components/Mapvulne/Mapvulne';
import Navegador from './components/NavBar/Navega';
import Cabecera from './components/NavBar/Cabecera';

import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {Isosistas} from './components/Isosistas/Isosistas';

function App(){
  return(
    <BrowserRouter className="App">
      {/*<Navegador/>*/}
      <Cabecera/>

      <Switch>
        <Route path='/' component={Home} exact/>
        <Route path='/Encuesta' component={Encuesta} exact/>
        <Route path='/Catalogo' component={Catalogo} exact/>
        <Route path='/Isosistas' component={Isosistas} exact/>
        <Route path='/Documentacion' component={Documentacion} exact/>
        <Route path='/Estadisticas' component={Estadisticas} exact/>
        <Route path='/Team' component={Team} exact/>
        <Route path='/Instituciones' component={Instituciones} exact/>
        <Route path='/Mapavulnerabilidad' component={Mapavulnerabilidad} exact/>
      </Switch>
    </BrowserRouter>
  );
}

export default App;