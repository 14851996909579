import React, {Component} from 'react';

import {XYZ} from 'ol/source';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import { fromLonLat } from 'ol/proj';
import {transform} from 'ol/proj';
import Zoom from 'ol/control/Zoom';
import ZoomToExtent from 'ol/control/ZoomToExtent';
import ZoomSlider from 'ol/control/ZoomSlider';
import FullScreen from 'ol/control/FullScreen';
import Rotate from 'ol/control/Rotate';
import ScaleLine from 'ol/control/ScaleLine';
import OSM from 'ol/source/OSM';
import Map from 'ol/Map';

import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {Fill, Stroke, Circle, Text, RegularShape, Icon} from 'ol/style';
import Style from 'ol/style/Style';
import Geolocation from 'ol/Geolocation';

// import icons
import { FaSearchLocation, FaLayerGroup, FaRegBookmark, FaList, FaRev, FaCloudDownloadAlt,FaRegEyeSlash, FaRegEye, FaGem, FaHeart, FaHome, FaExpand, FaHotTub, FaMarker, FaFulcrum, FaMap, FaMapMarkedAlt, FaGlobeAmericas, FaGlobe, FaHotjar, FaEyeDropper, FaEye, FaDownload, FaSave, FaPrint} from 'react-icons/fa';
import { BsGrid, BsFillLayersFill, BsLayersHalf, BsSquare, BsSquareHalf, BsGeoAlt, BsGeo } from "react-icons/bs";
import { FiFilter, FiLayers } from "react-icons/fi";
import { MdZoomOutMap, MdFilterList, MdGroup, MdCenterFocusStrong, MdRefresh } from "react-icons/md";
import {CgClose, CgExternal, CgHomeAlt} from 'react-icons/cg';
import { AiOutlineBranches} from "react-icons/ai";
import { BiStats} from "react-icons/bi";
import {GiUnionJack, GiPositionMarker, GiWorld} from "react-icons/gi";
//Geocode
import Geocode from "react-geocode";


//import robot from "./robot_laptop.png";
//import "./Encuesta.css";

class Mapavulnerabilidad extends Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.cambia_direccion = this.cambia_direccion.bind(this);
      this.busca_direccion = this.busca_direccion.bind(this);
      //Mapa vista Open Street Map
      this.OSMAP = new TileLayer({
        source: new OSM(),
        visible: false,
        className: "OSM_Map",
      });

      //Mapa vista Google Maps
      this.GMaps = new TileLayer({
        title: "Google Maps",
        visible: true,
        source: new XYZ({
            url: 'http://mt.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
        })
      });
      //View
      this.view = new View({
        center: fromLonLat([-76.048, -9.4913]),
        zoom: 5.8,
        minZoom: 2,
        maxZoom: 28,
    });
    //Data
    this.fillClicktwo = new Fill({color: 'rgba(0,240,240,0.9)'});
    this.strokeClicktwo = new Stroke({color: 'rgba(0,255,255,0.8)',width: 2});
    
    this.color_point_vulnerabilidad = (hipocentro_sismo) =>{
      if (hipocentro_sismo=="Muy Baja Vulnerabilidad") {
        return 'rgba(0, 176, 80, 0.99)'
      }
      if (hipocentro_sismo=="Baja Vulnerabilidad") {
        return 'rgba(169, 208, 142, 0.99)'
      }
      if (hipocentro_sismo=="Media Vulnerabilidad") {
        return 'rgba(255, 255, 0, 0.99)'
      }
      if (hipocentro_sismo=="Alta Vulnerabilidad") {
        return 'rgba(255, 0, 0, 0.99)'
      }
      if (hipocentro_sismo=="Muy Alta Vulnerabilidad") {
        return 'rgba(192, 0, 0, 0.99)'
      }
    };

    this.sourceData2 = new VectorSource({
      url: 'https://eval-vulnerabilidad.cdlima.org.pe/encuesta/vulnerabilidadperu',
      format: new GeoJSON()
    });

    this.styleCacheData2 ={};
    this.vectorData2 = new VectorLayer({
      name: 'Vulnerabilidad',
      source: this.sourceData2,
      style: (feature) => {
        this.vulnerabilidad = feature.get('name');
        this.style = this.styleCacheData2[this.radius];
        if (!this.style) {
          this.style = new Style({
            image: new Circle({
              radius: 8,
              //points: 4,
              //angle: Math.PI / 4,
              fill: new Fill({color: this.color_point_vulnerabilidad(this.vulnerabilidad),}),
              stroke: new Stroke({color:'rgba(0, 0, 0, 0.9)', width: 1,}),
            }),
          });
          //this.styleCacheHipocentro[this.radius] = this.style;              
        }
        return this.style;
      },
      visible: true
    });
    //Mapa
    this.map =  new Map({
      view: this.view,
      controls: [ 
        new Zoom({zoomInTipLabel:'Acercar Mapa', zoomOutTipLabel:'Alejar Mapa'}), 
        new ZoomSlider({duration:100,}), 
        new FullScreen({tipLabel:'Pantalla Completa'}), 
        new ScaleLine({minWidth:100}),
        //new OverviewMap({ layers:[this.OSM,this.WaterColor,this.bingmap,this.ByN,this.terreno],collapsible:true, tipLabel:"Sobre vista del mapa"}),
        new Rotate({autoHide:true, tipLabel:'Orientar Mapa'})],
      layers: [this.OSMAP, this.GMaps,this.vectorData2],
    });

    this.actualiza_ubicacion = (evt) =>{
        this.caracteristicas = this.map.getFeaturesAtPixel(evt.pixel);
        if (this.caracteristicas.length>=1) {
            //Listo para mostrar detalles de la zona
        }
        else{
            console.log("entre a la seccion de mover punto");
            this.click = this.map.getCoordinateFromPixel(evt.pixel);
            this.click_coordinates = transform(this.click,'EPSG:900913','EPSG:4326');
            
        }
    };

    this.map.on("singleclick", this.actualiza_ubicacion);
    //GeoPosicionamiento
    this.strokePosition = new Stroke({color: 'rgba(255,0,0,0.9)', width: 5});
    this.fillPosition = new Fill({color: 'rgba(0,255,0,0.9)', width: 5});
    this.geoposition = new Geolocation({
      trackingOptions: { enableHighAccuracy: true },
      projection: this.view.getProjection()
    });

    }
    actua_coord=(latitud, longitud)=>{
        this.props.updatecoord(latitud, longitud);
    }

    //GeoPosicionamiento
    GeoPositionInMap=()=>{
      this.geoposition.setTracking('true');
      this.geoposition.setProjection();
      // this.geoposition.getPosition();
      this.geoposition.on('change',this.ObtenUbicacion);
    };
    ObtenUbicacion=()=>{
      console.log("Aparezco cuando acepta el tracking");
      //console.log(this.geoposition.getPosition());
      this.coordinates= transform(this.geoposition.getPosition(),'EPSG:3857','EPSG:4326');
      this.geoposition.setTracking(false);
      console.log(this.coordinates[0]);
      console.log(this.coordinates[1]);

      this.map.setView(
        new View({
          center: fromLonLat(this.coordinates),
          zoom: 15
        })
      );
    };
    //Buscador de Direcciones
    cambia_direccion(event) {
        this.setState({value: event.target.value});
        console.log(this.state.value);
    }
    busca_direccion(event) {
        this.convert_direccion(this.state.value);
        event.preventDefault();
    }
    convert_direccion(direccion){
        Geocode.setApiKey("AIzaSyBEN1zVUqxX4a32voiBUrmEl_8BeOIt4_I");
        Geocode.setLanguage("latam");
        Geocode.setRegion("pe");
        Geocode.enableDebug();
        Geocode.fromAddress(direccion).then(
          response => {
            const { lat, lng } = response.results[0].geometry.location;
            console.log(lat, lng);
            this.center_marker(lat,lng,17);
          },
          error => {
            console.error(error);
          }
        );
    }
    center_marker(latitud, longitud, zoom_acercar) {
        this.map.setView(
          new View({
            center: fromLonLat([longitud,latitud]),
            zoom: zoom_acercar
          })
        );
    }
      
    componentDidMount ()  {
        this.map.setTarget(this.refs.MapaResultadovulne);
    }
    


    render() {
        return (
            <div className="seccionmapavulne">
              <div className="mapaResultadovulne" id="MapaResultadovulne" ref="MapaResultadovulne"/>
              <button className="geoposition_encuesta" onClick={this.GeoPositionInMap} title="Geoposicionamiento"><BsGeo/></button>
              <form onSubmit={this.busca_direccion}>
                <label className="label-busca-direccion-encuesta" title="Escribe tu dirección para encontrarte">
                    <input  className="input-busca-direccion" type="text" value={this.state.value} onChange={this.cambia_direccion} placeholder="Ingresa direción a buscar" title="Escribe tu dirección para encontrarte"/>
                    <FaSearchLocation className="icon-busca-direccion" onClick={this.busca_direccion} title="Escribe tu dirección para encontrarte"/>
                </label>
              </form>
              <label className="leyenda_mapa px-2 py-2" title="Leyenda">
                <h6 className="px-4"><strong>Leyenda: </strong></h6>
                <span className="ley_ma px-1">O</span><span className="px-2">Muy Alta Vulnerabilidad</span><br/>
                <span className="ley_a px-1">O</span><span className="px-2">Alta Vulnerabilidad</span><br/>
                <span className="ley_m px-1">O</span><span className="px-2">Mediana Vulnerabilidad</span><br/>
                <span className="ley_b px-1">O</span><span className="px-2">Baja Vulnerabilidad</span><br/>
                <span className="ley_mb px-1">O</span><span className="px-2">Muy Baja Vulnerabilidad</span>
              </label>
            </div>
        );
    }
}
export default Mapavulnerabilidad;