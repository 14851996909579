import React, {Component} from 'react';
import './Catalogo.css';

import View from 'ol/View';
import Projection from 'ol/proj/Projection';
import Map from 'ol/Map';
import Tile from 'ol/layer/Tile';
import Heatmap from 'ol/layer/Heatmap';
import TileLayer from 'ol/layer/Tile';
import Vector from 'ol/layer/Vector';
import OSM from 'ol/source/OSM';
import Stamen from 'ol/source/Stamen';
import Control from 'ol/control/Control';
import Attribution from 'ol/control/Attribution';
import Zoom from 'ol/control/Zoom';
import ZoomToExtent from 'ol/control/ZoomToExtent';
import ZoomSlider from 'ol/control/ZoomSlider';
import FullScreen from 'ol/control/FullScreen';
import OverviewMap from 'ol/control/OverviewMap';
import Rotate from 'ol/control/Rotate';
import ScaleLine from 'ol/control/ScaleLine';


import {ImageArcGISRest, XYZ} from 'ol/source';
import {Image as ImageLayer} from 'ol/layer';
//import ZoomSlider from 'ol/source/ZoomSlider';
//importar estilos
import Style from 'ol/style/Style';
import BingMaps from 'ol/source/BingMaps';
//import para vectores

import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {Fill, Stroke, Text, Circle, RegularShape} from 'ol/style';
import BaseLayer from 'ol/layer/Base';

//importar para geolocalizar 
import Geolocation from 'ol/Geolocation';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Icon from 'ol/style/Icon';
import { fromLonLat } from 'ol/proj';
import {transform} from 'ol/proj';


//Import DatePicker
import DatePicker from "react-datepicker";

//Importar para dibujar
import Draw, {createBox, createRegularPolygon} from 'ol/interaction/Draw';

//Importar para Medir
import {getArea, getDistance, getLength} from 'ol/sphere';

//importar para popup
import Overlay from 'ol/Overlay';

// import icons
import { FaSearchLocation, FaLayerGroup, FaRegBookmark, FaList, FaRev, FaCloudDownloadAlt,FaRegEyeSlash, FaRegEye, FaGem, FaHeart, FaHome, FaExpand, FaHotTub, FaMarker, FaFulcrum, FaMap, FaMapMarkedAlt, FaGlobeAmericas, FaGlobe, FaHotjar, FaEyeDropper, FaEye, FaDownload, FaSave, FaPrint} from 'react-icons/fa';
import { BsGrid, BsFillLayersFill, BsLayersHalf, BsSquare, BsSquareHalf, BsGeoAlt, BsGeo } from "react-icons/bs";
import { FiFilter, FiLayers } from "react-icons/fi";
import { MdZoomOutMap, MdFilterList, MdGroup, MdCenterFocusStrong, MdRefresh } from "react-icons/md";
import {CgClose, CgExternal, CgHomeAlt} from 'react-icons/cg';
import { AiOutlineBranches} from "react-icons/ai";
import { BiStats} from "react-icons/bi";
import {GiUnionJack, GiPositionMarker, GiWorld} from "react-icons/gi";

//importar SideNav
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarContent, SidebarFooter, SidebarHeader } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';

//Import Modal
import {Modal, Button, Container, Row, Col, OverlayTrigger, Popover, Tooltip} from 'react-bootstrap';

//Geocode
import Geocode from "react-geocode";

//Imagenes
import leyenda_intensidades from './intensidad.jpg';
import Imm from './Formulacion_Imm.jpeg';

//Generador de PDF
import Pdf from 'react-to-pdf';

//import axios
import axios from 'axios';
import { MDBBtn, MDBIcon, MDBInput, MDBTooltip } from 'mdbreact';

import escala_magnitud from './escala_magnitud.png';
import fallas_leyenda from './fallas_leyenda.png';

//Importa Estilo Open Layers
require('ol/ol.css');


const ref = React.createRef();


export class Catalogo extends Component{
    ano=String(new Date().getFullYear());
    constructor(props){
        super(props);
        this.state = {
          //Dates date-picker
          date_ini: new Date("01/01/"+this.ano),
          date_end: new Date(),
          //Modal
          toggle_modal: false,
          //SideBar
          sidebar_state: false,
          //Slide
          slide_detail: 'Caracteristicas_popover',
          //Capas de Mapas
          mapa_calor:"capa_inactivac",
          mapa_epicentral:"capa_activac",
          mapa_epicentralred:"capa_activac",
          mapa_hipocentros:"capa_inactivac",
          mapa_hipocentroshistoricos:"capa_inactivac",
          mapa_hipocentrosUSGS:"capa_inactivac",
          mapa_hipocentrosIGP:"capa_inactivac",
          fallas_geo:"capa_inactivac",
          //Detalles
          nombre_detalle: '',
          latitud_detalle: '',
          longitud_detalle: '',
          magnitud_detalle: '',
          unidad_mag_detalle: '',
          profundidad_detalle: '',
          fecha_detalle: '',
          referencia_detalle: '',
          recopilado_detalle:'',
          catalogo_detalle:'',
          //Mapa Intensidades
          mapa_intensidades_absolute: 'MapaIntensidades_oculto',
          //Source Map
          raiz_source: 'https://datacatalogo.citdi.uni.edu.pe/',
          map_source: 'https://datacatalogo.citdi.uni.edu.pe/sismos.geojson',
          raiz_source_intensidad: 'https://datacatalogo.citdi.uni.edu.pe/intensidades.geojson/',
          raiz_source_hipocentros: 'https://datacatalogo.citdi.uni.edu.pe/hipocentros.geojson/',

          //Contenido Hipocentros
          loading_hipocentros: true,
          matriz_hipocentros: [],
          //Contenido Intensidades
          loading_intensidad: true,
          matriz_intensidades: [],
          loading_intensidad_PDF: true,
          matriz_intensidades_PDF: [],

          //Filtros del mapa
          lat_filter: '-10.5634',
          lon_filter:'-74.6789',
          type_filter: "fuente",
          filter_fecha: "oculta_filtro_fecha",
          filter_pais: "oculta_filtro_pais",
          filter_area: "oculta_filtro_area",
          filter_fuente: "muestra_filtro_fuente",
          filter_magnitud: "oculta_filtro_magnitud",
          type_geometry_filter: "circular",
          type_geometry_radio:"1",
          type_fuente: "USGS",
          type_magnitud: "2.5",
          type_pais: "PERU",

          //Leyenda
          t_leyenda_fallas: "sin_leyenda",
          leyenda_fallas:"sin_leyenda"
        };
        this.toggle_side = this.toggle_side.bind(this);
        this.cambia_direccion = this.cambia_direccion.bind(this);
        this.busca_direccion = this.busca_direccion.bind(this);
        this.select_filter_type = this.select_filter_type.bind(this);
        this.select_geometry_filter = this.select_geometry_filter.bind(this);
        this.select_geometry_radio = this.select_geometry_radio.bind(this);
        this.select_fuente = this.select_fuente.bind(this);
        this.select_magnitud = this.select_magnitud.bind(this);
        this.select_pais = this.select_pais.bind(this);
        this.aplicar_filtro_mapa = this.aplicar_filtro_mapa.bind(this);
        this.cambia_lat_filter = this.cambia_lat_filter.bind(this);
        this.cambia_lon_filter = this.cambia_lon_filter.bind(this);

        //Mapa vista Open Street Map
        this.OSM = new TileLayer({
          source: new OSM(),
          visible: false
        });

        //Mapa vista Open Street Map Nocturno
        this.Nocturno = new TileLayer({
          source: new OSM(),
          className: "OSM_Map",
          visible: false
        });

        //Mapa vista Color de Agua
        this.WaterColor = new TileLayer({
            source: new Stamen({
              layer: 'watercolor',
            }),
            visible: false
        });

        //Mapa vista BingMap
        this.bingmap = new TileLayer({
            source: new BingMaps({
                key:'Akf58DQVppAPmtBLUolPS8RS9tBvBWJyyiT4cvgMwZIwMUGsAADIkedByoa6l_K1 ',
                imagerySet:'AerialWithLabelsOnDemand',//AerialWithLabelsOnDemand
            }),
            visible: false
        });

        //Mapa vista RoadMap
        this.bingmapRoad = new TileLayer({
          source: new BingMaps({
              key:'Akf58DQVppAPmtBLUolPS8RS9tBvBWJyyiT4cvgMwZIwMUGsAADIkedByoa6l_K1 ',
              imagerySet:'Road',//AerialWithLabelsOnDemand
          }),
          visible: true
        });

        //Mapa vista Blanco y Negro
        this.ByN = new TileLayer({
          source: new Stamen({
            layer: 'toner',
          }),
          visible: false
        });

        //Mapa vista Terreno
        this.terreno = new TileLayer({
          source: new Stamen({
            layer: 'terrain',
          }),
          visible: false
        });
        
        //Mapa vista Google Maps
        this.GMaps = new TileLayer({
          title: "Google Maps",
          visible: false,
          source: new XYZ({
              url: 'https://mt.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
          })
        });
        
        //Mapa vista GMaps Terreno
        this.GMapsTerreno = new TileLayer({
          title: "Google Terrain",
          visible: false,
          source: new XYZ({
              url: 'https://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}'
          })
        });
        
        //Mapa vista GMaps Satelite
        this.GMapsSatelite = new TileLayer({
          title: "Google Satellite",
          visible: false,
          source: new XYZ({
              url: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
          })
        });
        
        //Etiquetas del Mapa
        this.Etiquetas = new TileLayer({
          source: new Stamen({
            layer: 'terrain-labels',
          }),
          visible: true
        });


        //Vector HeatMap
        this.sourceHeatMap = new VectorSource({
            url: 'https://datacatalogo.citdi.uni.edu.pe/catalogohipocentrosUSGS.geojson',
            format: new GeoJSON()
          });

        //Click
        this.fillClick = new Fill({color: 'rgba(255,255,0,0.9)'});
        this.strokeClick = new Stroke({color: 'rgba(255,0,0,0.9)',width: 2});
        this.styleClick = new Style({
          image: new RegularShape({
            fill: this.fillClick,
            stroke: this.strokeClick,
            points: 4,
            radius: 12,
            radius2: 6,
            angle: 0
            }),
          stroke: this.strokePosition,
          fill: this.fillPosition,
          text: new Text({
            text: "Referencia !!",
            font: 'bold 11px sans-serif',
            color: 'blue',
          })
        });
        this.sourceClick = new VectorSource({
          url: 'https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/mapaclic/latitude=-7.45783&&longitude=-65.75823',
          format: new GeoJSON()
        });
        this.vectorClick = new VectorLayer({
          name: 'Manual',
          source: this.sourceClick,
          style: this.styleClick,
          visible: false
        });
        
        //  Grafica el mapa de calor sismico
        this.vectorHeatMap = new Heatmap({
            name: 'Manual',
            source: this.sourceHeatMap,
            //style:this [this.stylePerforacion(12), this.stylePerforacion(7), this.stylePerforacion(3), this.stylePerforacion(0.5)],
            //style: this.stylePerforacion(this.sourcePerforacion),
            blur: 15,
            radius: 5.1,
            weight: (feature) => {
              this.magnitu = feature.get('magnitud')
              return (parseFloat(this.magnitu))*4;
            },
            visible: false
        });
        
        //Grafica el mapa por magnitud CERESIS
        this.sourceMagnitud = new VectorSource({
            url: 'https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/filtermagnitude/compiled=CERESIS&magnitude=0.0',//'http://190.119.192.183:5000/api/reportes/query/?starttime=2015-02-01T05:00:00Z&endtime=2020-09-13T00:18:09Z',//https://datacatalogo.citdi.uni.edu.pe/sismos.geojson,
            format: new GeoJSON()
          });
        
        //Grafica el mapa por magnitud RED ACELEROGRAFICA
        this.sourceMagnitudRED = new VectorSource({
          url: 'https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/filtermagnitude/compiled=REDACELEROGRAFICA&magnitude=0.0',//'http://190.119.192.183:5000/api/reportes/query/?starttime=2015-02-01T05:00:00Z&endtime=2020-09-13T00:18:09Z',//https://datacatalogo.citdi.uni.edu.pe/sismos.geojson,
          format: new GeoJSON()
        });

        //Grafica el mapa por magnitud HIPOCENTROS CERESIS
        this.sourceCatalogoHipocentros = new VectorSource({
          url: 'https://datacatalogo.citdi.uni.edu.pe/catalogohipocentros.geojson',//'http://190.119.192.183:5000/api/reportes/query/?starttime=2015-02-01T05:00:00Z&endtime=2020-09-13T00:18:09Z',//https://datacatalogo.citdi.uni.edu.pe/sismos.geojson,
          format: new GeoJSON()
        });

        //Grafica el mapa por magnitud HIPOCENTROS HISTORICOS CERESIS
        this.sourceCatalogoHipocentrosHistoricos = new VectorSource({
          url: 'https://datacatalogo.citdi.uni.edu.pe/catalogohipocentroshistoricos.geojson',//'http://190.119.192.183:5000/api/reportes/query/?starttime=2015-02-01T05:00:00Z&endtime=2020-09-13T00:18:09Z',//https://datacatalogo.citdi.uni.edu.pe/sismos.geojson,
          format: new GeoJSON()
        });

        //Grafica el mapa por magnitud HIPOCENTROS USGS
        this.sourceCatalogoHipocentrosUSGS = new VectorSource({
          //url: 'https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/filtersourcehipo/compiled=REDACELEROGRAFICA&source=USGS',
          url:'https://datacatalogo.citdi.uni.edu.pe/catalogohipocentrosUSGS.geojson',
          format: new GeoJSON()
        });

        //Grafica el mapa por magnitud HIPOCENTROS IGP
        this.sourceCatalogoHipocentrosIGP = new VectorSource({
          url: 'https://datacatalogo.citdi.uni.edu.pe/catalogohipocentrosIGP.geojson',
          format: new GeoJSON()
        });

        this.color_point = (magnitud_sismo) =>{
            if (magnitud_sismo>=8) {
              return 'rgba(255, 0, 0, 0.99)'
            }
            if (magnitud_sismo>=7 && magnitud_sismo<8) {
              return 'rgba(213, 0, 249, 0.85)'
            }
            if (magnitud_sismo>=6 && magnitud_sismo<7) {
              return 'rgba(255, 128, 0, 0.99)'
            }
            if (magnitud_sismo>=5 && magnitud_sismo<6) {
              return 'rgba(255, 255, 0, 0.99)'
            }
            if (magnitud_sismo>=4 && magnitud_sismo<5) {
              return 'rgba(0, 128, 0, 0.99)'
            }
            if (magnitud_sismo>=3 && magnitud_sismo<4) {
              return 'rgba(57, 255, 20, 0.99)'
            }
            if (magnitud_sismo>=1 && magnitud_sismo<3) {
              return 'rgba(81, 209, 246, 0.99)'
            }
            else {
              return 'rgba(210, 210, 210, 0.99)'
            }
          };
          this.radio_point = (valor) => {
            if (valor < 0.1) {
              return 9
            }
            else{
              return valor*2
            }
          };
          this.styleCacheMagnitud ={};
          this.vectorMagnitud = new VectorLayer({
            name: 'Magnitud CERESIS',
            source: this.sourceMagnitud,
            style: (feature) => {
              this.magnitu = feature.get('magnitud')
              this.radi = this.radio_point(parseFloat(this.magnitu));
              this.style = this.styleCacheMagnitud[this.radi];
              if (!this.style) {
                this.style = new Style({
                  image: new Circle({
                    radius: this.radi,
                    fill: new Fill({color: this.color_point(this.magnitu),}),
                    stroke: new Stroke({color:'rgba(0, 0, 0, 0.9)', width: 1,}),
                  }),
                });
                this.styleCacheMagnitud[this.radi] = this.style;              
              }
              return this.style;
            },
            visible: true
          });

          this.styleCacheMagnitudRED ={};
          this.vectorMagnitudRED = new VectorLayer({
            name: 'Magnitud RED ACELEROGRAFICA',
            source: this.sourceMagnitudRED,
            style: (feature) => {
              this.magnitu = feature.get('magnitud')
              this.radi = (parseFloat(this.magnitu))*2;
              this.style = this.styleCacheMagnitudRED[this.radi];
              if (!this.style) {
                this.style = new Style({
                  image: new Circle({
                    radius: this.radi,
                    fill: new Fill({color: this.color_point(this.magnitu),}),
                    stroke: new Stroke({color:'rgba(0, 0, 0, 0.9)', width: 1,}),
                  }),
                });
                this.styleCacheMagnitudRED[this.radi] = this.style;              
              }
              return this.style;
            },
            visible: true
          });
          
          this.styleCacheCatalogoHipocentros ={};
          this.vectorCatalogoHipocentros = new VectorLayer({
            name: 'Hipocentros',
            source: this.sourceCatalogoHipocentros,
            style: (feature) => {
              this.magnitu = feature.get('magnitud')
              this.radi = (parseFloat(this.magnitu))*2;
              this.style = this.styleCacheCatalogoHipocentros[this.radi];
              if (!this.style) {
                this.style = new Style({
                  image: new Circle({
                    radius: this.radi,
                    fill: new Fill({color: this.color_point(this.magnitu),}),
                    stroke: new Stroke({color:'rgba(0, 0, 0, 0.9)', width: 1,}),
                  }),
                });
                this.styleCacheCatalogoHipocentros[this.radi] = this.style;
              }
              return this.style;
            },
            visible: false
          });
          
          this.styleHH = new Style({
            image: new Circle({
              radius: 8,
              fill: new Fill({color: 'rgba(210,210,210, 0.9)'}),
              stroke: new Stroke({color:'rgba(0, 0, 0, 0.9)', width: 1,}),
            }),
          });
          this.vectorCatalogoHipocentrosHistoricos = new VectorLayer({
            name: 'Hipocentros Históricos',
            source: this.sourceCatalogoHipocentrosHistoricos,
            style: this.styleHH,
            visible: false
          });

          this.styleCacheCatalogoHipocentrosUSGS ={};
          this.vectorCatalogoHipocentrosUSGS = new VectorLayer({
            name: 'Hipocentros USGS',
            source: this.sourceCatalogoHipocentrosUSGS,
            style: (feature) => {
              this.magnitu = feature.get('magnitud')
              this.radi = (parseFloat(this.magnitu))*2;
              this.style = this.styleCacheCatalogoHipocentrosUSGS[this.radi];
              if (!this.style) {
                this.style = new Style({
                  image: new Circle({
                    radius: this.radi,
                    fill: new Fill({color: this.color_point(this.magnitu),}),
                    stroke: new Stroke({color:'rgba(0, 0, 0, 0.9)', width: 1,}),
                  }),
                });
                this.styleCacheCatalogoHipocentrosUSGS[this.radi] = this.style;
              }
              return this.style;
            },
            visible: false
          });
                    
          this.styleCacheCatalogoHipocentrosIGP ={};
          this.vectorCatalogoHipocentrosIGP = new VectorLayer({
            name: 'Hipocentros IGP',
            source: this.sourceCatalogoHipocentrosIGP,
            style: (feature) => {
              this.magnitu = feature.get('magnitud')
              this.radi = (parseFloat(this.magnitu))*2;
              this.style = this.styleCacheCatalogoHipocentrosIGP[this.radi];
              if (!this.style) {
                this.style = new Style({
                  image: new Circle({
                    radius: this.radi,
                    fill: new Fill({color: this.color_point(this.magnitu),}),
                    stroke: new Stroke({color:'rgba(0, 0, 0, 0.9)', width: 1,}),
                  }),
                });
                this.styleCacheCatalogoHipocentrosIGP[this.radi] = this.style;
              }
              return this.style;
            },
            visible: false
          });

        //View
        this.view = new View({
            center: fromLonLat([-76.048, -9.4913]),
            zoom: 6,
            minZoom: 2,
            maxZoom: 28,
        });

        //Control Extend
        this.extend = new ZoomToExtent({
            extent: [
                813079.7791264898, 5929220.284081122,
                848966.9639063801, 5936863.986909639
              ],
            label: "E",
            tipLabel: "Extender para centrar mapa"
        });

        //Dibuja Caracteristicas
        this.source = new VectorSource({wrapX: false});
        this.vector = new VectorLayer({
          source: this.source,
        });
        //Fallas
        this.fallaLayer = new ImageLayer({
          title: 'Geological Faults',
          source: new ImageArcGISRest({
              ratio: 1,
              params: {layers:'show:1'},
              url: "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_NEOTECTONICA/MapServer",
          }),
          visible: false,
        });
        //Mapa
        this.map =  new Map({
            view: this.view,
            controls: [ 
              new Zoom({zoomInTipLabel:'Acercar Mapa', zoomOutTipLabel:'Alejar Mapa'}), 
              new ZoomSlider({duration:100,}), 
              new FullScreen({tipLabel:'Pantalla Completa'}), 
              new ScaleLine({minWidth:100}),
              //new OverviewMap({ layers:[this.OSM,this.WaterColor,this.bingmap,this.ByN,this.terreno],collapsible:true, tipLabel:"Sobre vista del mapa"}),
              new Rotate({autoHide:true, tipLabel:'Orientar Mapa'})],
            layers: [this.OSM, this.WaterColor, this.Etiquetas, this.bingmap, this.bingmapRoad, this.ByN, this.terreno, this.Nocturno, this.GMaps, this.GMapsTerreno, this.GMapsSatelite, this.fallaLayer, this.vectorHeatMap, this.vectorMagnitud, this.vectorMagnitudRED, this.vectorCatalogoHipocentros, this.vectorCatalogoHipocentrosHistoricos, this.vectorCatalogoHipocentrosUSGS, this.vectorCatalogoHipocentrosIGP, this.vector, this.vectorClick],
        });

        //Puntos de intensidad Modal
        this.sourceIntensidad = new VectorSource({
          url: 'https://datacatalogo.citdi.uni.edu.pe/intensidades.geojson/4000',
          format: new GeoJSON()
        });
        this.sourceHipocentros = new VectorSource({
          url: 'https://datacatalogo.citdi.uni.edu.pe/hipocentros.geojson/4000',
          format: new GeoJSON()
        });
        this.color_point_intensidades = (intensidad_sismo) =>{
          if (intensidad_sismo>11) {
            return 'rgba(0, 0, 0, 0.99)'
          }
          if (intensidad_sismo>10 && intensidad_sismo<=11) {
            return 'rgba(132, 3, 8, 0.99)'
          }
          if (intensidad_sismo>9 && intensidad_sismo<=10) {
            return 'rgba(199, 0, 3, 0.99)'
          }
          if (intensidad_sismo>8 && intensidad_sismo<=9) {
            return 'rgba(253, 1, 0, 0.99)'
          }
          if (intensidad_sismo>7 && intensidad_sismo<=8) {
            return 'rgba(254, 145, 0, 0.99)'
          }
          if (intensidad_sismo>6 && intensidad_sismo<=7) {
            return 'rgba(255, 199, 1, 0.99)'
          }
          if (intensidad_sismo>5 && intensidad_sismo<=6) {
            return 'rgba(253, 254, 2, 0.99)'
          }
          if (intensidad_sismo>4 && intensidad_sismo<=5) {
            return 'rgba(127, 247, 149, 0.99)'
          }
          if (intensidad_sismo>3 && intensidad_sismo<=4) {
            return 'rgba(128, 254, 253, 0.99)'
          }
          if (intensidad_sismo>2 && intensidad_sismo<=3) {
            return 'rgba(154, 153, 255, 0.99)'
          }
          if (intensidad_sismo>1 && intensidad_sismo<=2) {
            return 'rgba(192, 204, 255, 0.99)'
          }
          if (intensidad_sismo<=1) {
            return 'rgba(233, 255, 232, 0.99)'
          }
        };
        this.color_point_hipocentros = (hipocentro_sismo) =>{
          if (hipocentro_sismo=="CERESIS") {
            return 'rgba(3, 0, 130, 0.99)'
          }
          if (hipocentro_sismo=="IGP") {
            return 'rgba(80, 74, 214, 0.99)'
          }
          if (hipocentro_sismo=="USGS") {
            return 'rgba(0, 114, 46, 0.99)'
          }
          if (hipocentro_sismo=="IGEPN") {
            return 'rgba(212, 146, 67, 0.99)'
          }
          if (hipocentro_sismo=="CSN") {
            return 'rgba(66, 136, 183, 0.99)'
          }
        };
        
        this.styleCacheIntensidad ={};
        this.vectorIntensidad= new VectorLayer({
          name: 'Intensidad',
          source: this.sourceIntensidad,
          style: (feature) => {
            this.intensidad = feature.get('intensidad')
            this.radius = (parseFloat(this.intensidad))*2;
            this.style = this.styleCacheIntensidad[this.radius];
            if (!this.style) {
              this.style = new Style({
                image: new RegularShape({
                  radius: this.radius,
                  points:3,
                  fill: new Fill({color: this.color_point_intensidades(this.intensidad),}),
                  stroke: new Stroke({color:'rgba(0, 0, 0, 0.9)', width: 1,}),
                }),
              });
              this.styleCacheIntensidad[this.radius] = this.style;              
            }
            return this.style;
          },
          visible: true
        });
        this.styleCacheHipocentro ={};
        this.vectorHipocentro= new VectorLayer({
          name: 'Hipocentro',
          source: this.sourceHipocentros,
          style: (feature) => {
            this.hipocentro = feature.get('magnitud');
            this.fuente_hipo = feature.get('nombre');
            this.style = this.styleCacheHipocentro[this.radius];
            if (!this.style) {
              this.style = new Style({
                image: new RegularShape({
                  radius: 16,
                  radius2: 8,
                  points: 5,
                  angle:0,
                  fill: new Fill({color: this.color_point_hipocentros(this.fuente_hipo),}),
                  stroke: new Stroke({color:'rgba(0, 0, 0, 0.9)', width: 1,}),
                }),
              });
              //this.styleCacheHipocentro[this.radius] = this.style;              
            }
            return this.style;
          },
          visible: true
        });
        //Mapa vista RoadMap Modal
        this.bingmapRoadModal = new TileLayer({
          source: new BingMaps({
              key:'Akf58DQVppAPmtBLUolPS8RS9tBvBWJyyiT4cvgMwZIwMUGsAADIkedByoa6l_K1 ',
              imagerySet:'Road',//AerialWithLabelsOnDemand
          }),
          visible: true
        });
        //View Modal
        this.view_modal = new View({
          center: fromLonLat([-70.048, -9.4913]),
          zoom: 5.0,
          minZoom: 2,
          maxZoom: 28,
        });
        //Mapa Modal
        this.map_modal =  new Map({
          view: this.view_modal,
          controls: [ 
            new Zoom({zoomInTipLabel:'Acercar Mapa', zoomOutTipLabel:'Alejar Mapa'}), 
            new ZoomSlider({duration:100,}), 
            new FullScreen({tipLabel:'Pantalla Completa'}), 
            new ScaleLine({minWidth:100}),
            //new OverviewMap({ layers:[this.OSM,this.WaterColor,this.bingmap,this.ByN,this.terreno],collapsible:true, tipLabel:"Sobre vista del mapa"}),
            new Rotate({autoHide:true, tipLabel:'Orientar Mapa'})],
          layers: [this.bingmapRoadModal, this.vectorIntensidad, this.vectorHipocentro],
        });

        //Click sobre una caracteristica
        this.simpleclic = (evt) => {
          this.caracteristicas = this.map.getFeaturesAtPixel(evt.pixel);
          if (this.caracteristicas.length>=1) {
            this.caracteristica = this.caracteristicas[0];
            console.log(this.caracteristica);
            //alert(this.caracteristica.get('name')); 
            if (this.caracteristica.get('magnitud')==0) {
              this.mag_draw="-"
            }
            if (this.caracteristica.get('magnitud')=="0") {
              this.mag_draw="-"
            }
            else{
              this.mag_draw=this.caracteristica.get('magnitud')
            }
            this.setState({
              slide_detail:'Caracteristicas_popover_expanded',
              nombre_detalle: this.caracteristica.get('nombre'),
              latitud_detalle: this.caracteristica.get('latitud'),
              longitud_detalle: this.caracteristica.get('longitud'),
              magnitud_detalle: this.mag_draw,
              unidad_mag_detalle: this.caracteristica.get('unit_mag'),
              profundidad_detalle: this.caracteristica.get('profundidad'),
              fecha_detalle: this.caracteristica.get('fecha'),
              referencia_detalle: this.caracteristica.get('referencia'),
              recopilado_detalle: this.caracteristica.get('recopilado'),
              catalogo_detalle: this.caracteristica.get('catalogo'),
              lat_filter: this.caracteristica.get('latitud'),
              lon_filter: this.caracteristica.get('longitud'),
            });
            this.sourceIntensidad.setUrl('https://datacatalogo.citdi.uni.edu.pe/intensidades.geojson/'+this.caracteristica.get('id'));
            this.sourceIntensidad.refresh();
            this.map_modal.setView(
              new View({
                center: fromLonLat([this.caracteristica.get('longitud'),this.caracteristica.get('latitud')]),
                zoom: 5.6
              })
            );
            
            this.sourceHipocentros.setUrl('https://datacatalogo.citdi.uni.edu.pe/hipocentros.geojson/'+this.caracteristica.get('id'));
            this.sourceHipocentros.refresh();

            this.sourceClick.setUrl('https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/mapaclic/latitude='+this.caracteristica.get('latitud')+'&&longitude='+this.caracteristica.get('longitud'));
            this.sourceClick.refresh();     
            
            this.Obtener_Hipocentros('https://datacatalogo.citdi.uni.edu.pe/hipocentros.geojson/'+this.caracteristica.get('id'));
            this.Obtener_Intensidades('https://datacatalogo.citdi.uni.edu.pe/intensidades.geojson/'+this.caracteristica.get('id'));
            this.Obtener_Intensidades_PDF('https://datacatalogo.citdi.uni.edu.pe/intensidadesPDF.geojson/'+this.caracteristica.get('id'))
          }
          else{
            this.click = this.map.getCoordinateFromPixel(evt.pixel);
            this.click_coordinates = transform(this.click,'EPSG:900913','EPSG:4326');
            this.setState({
              slide_detail:'Caracteristicas_popover', 
              mapa_intensidades_absolute:'MapaIntensidades_oculto', 
              lat_filter: this.click_coordinates[1], 
              lon_filter: this.click_coordinates[0]
            })
            this.sourceClick.setUrl('https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/mapaclic/latitude='+this.click_coordinates[1]+'&&longitude='+this.click_coordinates[0]);
            this.sourceClick.refresh();
          }
        };
        this.map.on('singleclick', this.simpleclic);

        //Cambia cursor
        this.cambiacursor = (e) => {
          this.pixel = this.map.getEventPixel(e.originalEvent);
          this.hit = this.map.hasFeatureAtPixel(this.pixel);
          this.map.getTarget().style.cursor = this.hit ?'pointer':'';
        };
        this.map.on('pointermove',this.cambiacursor);

        //GeoPosicionamiento
        this.strokePosition = new Stroke({color: 'rgba(255,0,0,0.9)', width: 5});
        this.fillPosition = new Fill({color: 'rgba(0,255,0,0.9)', width: 5});
        this.geoposition = new Geolocation({
          trackingOptions: { enableHighAccuracy: true },
          projection: this.view.getProjection()
        });

        
    }

    //Genera Datos Hipocentrales
    Data_Hipocentros(){
      if (this.state.loading_hipocentros){
        return(<div className="spinner-border text-danger" role="status">
        <span className="sr-only">Loading...</span>
      </div>)
      }
      return(
        this.state.matriz_hipocentros.map(
          hipocentro => <tr><td>{hipocentro.properties.nombre}</td><td>{hipocentro.properties.latitud} </td><td>{hipocentro.properties.longitud}</td><td>{hipocentro.properties.profundidad}</td><td>{hipocentro.properties.magnitud} {hipocentro.properties.unit_mag}</td><td></td></tr>         
        )
      )
    };
    /* Data_Hipocentros_PDF(){
      if (this.state.loading_hipocentros){
        return(<span>Loading..</span>)
      }
      return(
        this.state.matriz_hipocentros.map(
          hipocentro => 
          <tr><td><small>{hipocentro.properties.nombre}</small></td><td><small>{hipocentro.properties.latitud}</small></td><td><small>{hipocentro.properties.longitud}</small></td><td><small>{hipocentro.properties.profundidad}</small></td><td><small>{hipocentro.properties.magnitud} {hipocentro.properties.unit_mag}</small></td><td></td></tr>   
        )
      )
    }; */
    //Obtiene Datos HIpocentrales
    async Obtener_Hipocentros(ruta_ingresada){
      await axios.get(ruta_ingresada)
      .then(response =>{
        console.log(response);
        this.setState({matriz_hipocentros: response.data.features, loading_hipocentros:false})
      })
      .catch(e => {console.log(e)})
    }

    //Genera Datos Intensidades
    Data_Intensidades(){
      if (this.state.loading_intensidad){
        return(<div className="spinner-border text-danger" role="status">
          <span className="sr-only">Loading...</span>
        </div>)
      }
      return(
        this.state.matriz_intensidades.map(
          intensida => <tr><td>{intensida.properties.id}</td><td>{intensida.properties.latitud}</td><td>{intensida.properties.longitud}</td><td>{intensida.properties.intensidad_letra}</td></tr>        
        )
      )
    };
    Data_Intensidades_PDF(){
      if (this.state.loading_intensidad_PDF){
        return(<div className="spinner-border text-danger" role="status">
          <span className="sr-only">Loading...</span>
        </div>)
      }
      return(
        this.state.matriz_intensidades_PDF.map(
          intensida_PDF => <tr><td><small>{intensida_PDF.properties.id}</small></td><td><small>{intensida_PDF.properties.latitud}</small></td><td><small>{intensida_PDF.properties.longitud}</small></td><td><small>{intensida_PDF.properties.intensidad_letra}</small></td><td><small>{intensida_PDF.properties.id2}</small></td><td><small>{intensida_PDF.properties.latitud2}</small></td><td><small>{intensida_PDF.properties.longitud2}</small></td><td><small>{intensida_PDF.properties.intensidad_letra2}</small></td></tr>        
        )
      )
    };
    //Obtiene Datos Intensidades
    async Obtener_Intensidades(ruta_ingresada){
      await axios.get(ruta_ingresada)
      .then(response =>{
        console.log(response);
        this.setState({matriz_intensidades: response.data.features, loading_intensidad:false})
      })
      .catch(e => {console.log(e)})
    }
    async Obtener_Intensidades_PDF(ruta_ingresada){
      await axios.get(ruta_ingresada)
      .then(response =>{
        console.log(response);
        this.setState({matriz_intensidades_PDF: response.data.features, loading_intensidad_PDF:false})
      })
      .catch(e => {console.log(e)})
    }


    componentDidMount ()  {
        this.map.setTarget(this.refs.mapaCatalogo);
        this.map_modal.setTarget(this.refs.mapaIntensidades);
        this.Obtener_Hipocentros();
        this.Obtener_Intensidades();
        this.Obtener_Intensidades_PDF();
    }

    //Sección de detalles del sismo
    Referencia_Intensidades(){
      const popover_ceresis = 
        <Popover id="popover-basic">
          <Popover.Title className="title_popover" as="h3"><strong>Calculo de Intensidades</strong></Popover.Title>
          <Popover.Content className="content_popover" >
            <p>Datos Obtenidos de la recopilación realizada por el <a target="_blank" href="https://www.ceresis.org/catalogo.php?id=53">CERESIS</a></p>
          </Popover.Content>
        </Popover>
      const popover_redacel = 
      <Popover id="popover-basic">
        <Popover.Title className="title_popover" as="h3"><strong>Calculo de Intensidades</strong></Popover.Title>
        <Popover.Content className="content_popover" >
            <p>Datos calculados con la formulación del <a target="_blank" href="https://pubs.er.usgs.gov/publication/ofr20091047">USGS</a>.</p>
            <p>Wald et al. (1999b), propuso la correlación entre el PGA y MMI</p>
            <img className="image_popover" src={Imm}/>
        </Popover.Content>
      </Popover>
    
      if (this.state.recopilado_detalle=="CERESIS") {
        return (
          <OverlayTrigger trigger="hover" placement="top" overlay={popover_ceresis}>
            <sup className="overlay_trigger" variant="transparent"><strong>**</strong></sup>
          </OverlayTrigger>
          
        )
      }
      else{
        return(
          <OverlayTrigger trigger="hover" placement="top" overlay={popover_redacel}>
            <sup className="overlay_trigger" variant="transparent"><strong>**</strong></sup>
        </OverlayTrigger>
        )
      }
    }

    //Abre mapa intensidades
    Open_Mapa_Intensidades(){
      if (this.state.catalogo_detalle=="INTENSIDADES") {
        return(<tr><td><strong>Intensidades:</strong></td><td  onClick={this.open_modal}><span> Ver Mapa <FaEye/></span></td></tr>)
      }
      else{
        return(<tr><td><strong>Catálogo:</strong></td><td><span> de Hipocentros</span></td></tr>)
      }
    }
    //Dibuja Poligono
    Draw_Polygon=()=>{
      this.draw = new Draw({
        source: this.source,
        type: 'Circle',
        geometryFunction: createBox(),
      });
      this.map.addInteraction(this.draw);
      this.dao = this.draw.geometry;
      console.log(this.dao);
    }
    Dibuja_Poligono=()=>{
      this.Draw_Polygon();
      this.setState({slide_detail: 'Caracteristicas_popover',})
      console.log(this.draw.getPointerCount());
    }
    Remove_Draw=()=>{
      console.log(this.draw.getPointerCount());
      this.map.removeInteraction(this.draw);

    }

    //GeoPosicionamiento
    GeoPositionInMap=()=>{
      this.geoposition.setTracking('true');
      this.geoposition.setProjection();
      // this.geoposition.getPosition();
      this.geoposition.on('change',this.ObtenUbicacion);
      this.setState({boton_geoposition:'Geo_Activo',estado_boton_geoposition:'Activo'});
    };
    ObtenUbicacion=()=>{
      console.log("Aparezco cuando acepta el tracking");
      //console.log(this.geoposition.getPosition());
      this.coordinates= transform(this.geoposition.getPosition(),'EPSG:3857','EPSG:4326');
      this.geoposition.setTracking(false);
      console.log(this.coordinates[0]);
      console.log(this.coordinates[1]);
      this.setState({lat_filter:this.coordinates[1], lon_filter:this.coordinates[0]});
      this.iconFeature = new Feature({
        geometry: new Point(fromLonLat([this.coordinates[0], this.coordinates[1]])),
        tipo_ensayo: 'Geo Posicionamiento',
        name: 'Geo Posicionamiento',
        coordUTM1: parseFloat(this.coordinates[0]).toFixed(4),
        coordUTM2: parseFloat(this.coordinates[1]).toFixed(4),
        fuente: <a href='https://www.linkedin.com/in/joseph-marlon-montero-inga-aa3aa7106/' target='_blank' rel="noopener noreferrer">Fuente del Programador</a>,
      });
      
      this.iconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 46],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          src: 'https://cdn.pixabay.com/photo/2016/01/14/14/37/marker-1140068_960_720.png',
          scale: 0.05
        }),
        stroke: this.strokePosition,
        fill: this.fillPosition,
        text: new Text({
          text: "Tú estas aquí !!",
				  font: 'bold 11px sans-serif',
        })
      });
      
      this.iconFeature.setStyle(this.iconStyle);
      
      this.vectorSourceGP = new VectorSource({
        features: [this.iconFeature]
      });
      
      this.vectorLayerGP = new VectorLayer({
        source: this.vectorSourceGP
      });
      
      this.map.setView(
        new View({
          center: fromLonLat(this.coordinates),
          zoom: 15
        })
      );
      this.map.addLayer(this.vectorLayerGP);
    };

    //Expande o contrae sidebar
    toggle_side(){this.setState({sidebar_state: !this.state.sidebar_state})}
    
    //activa capa del mapa de calor
    capa_calor = () => {
      if (this.vectorHeatMap.getVisible()) {
        this.vectorHeatMap.setVisible(false);
        this.setState({
          mapa_calor:"capa_inactivac"
        })
      }
      else{
        this.vectorHeatMap.setVisible(true);
        this.vectorMagnitud.setVisible(false);
        this.vectorMagnitudRED.setVisible(false);
        this.vectorCatalogoHipocentros.setVisible(false);
        this.vectorCatalogoHipocentrosHistoricos.setVisible(false);
        this.vectorCatalogoHipocentrosUSGS.setVisible(false);
        this.vectorCatalogoHipocentrosIGP.setVisible(false);
        this.setState({
          mapa_epicentral:"capa_inactivac",
          mapa_epicentralred:"capa_inactivac",
          mapa_calor:"capa_activac",
          mapa_hipocentros:"capa_inactivac",
          mapa_hipocentroshistoricos:"capa_inactivac",
          mapa_hipocentrosUSGS:"capa_inactivac",
          mapa_hipocentrosIGP:"capa_inactivac",
          slide_detail:'Caracteristicas_popover', 
          mapa_intensidades_absolute:'MapaIntensidades_oculto', 
        })
      }
    }

    //activa capa de hipocentros CERESIS
    capa_hipocentros = () => {
      if (this.vectorCatalogoHipocentros.getVisible()) {
        this.vectorCatalogoHipocentros.setVisible(false);
        this.setState({
          mapa_hipocentros:"capa_inactivac"
        })
      }
      else{
        this.vectorCatalogoHipocentros.setVisible(true);
        this.vectorHeatMap.setVisible(false);
        this.vectorMagnitud.setVisible(false);
        this.vectorMagnitudRED.setVisible(false);
        this.setState({
          mapa_epicentral:"capa_inactivac",
          mapa_epicentralred:"capa_inactivac",
          mapa_calor:"capa_inactivac",
          mapa_hipocentros:"capa_activac",
          slide_detail:'Caracteristicas_popover', 
          mapa_intensidades_absolute:'MapaIntensidades_oculto', 
        })
      }      
    }

    //activa capa de hipocentros históricos CERESIS
    capa_hipocentroshistoricos = () => {
      if (this.vectorCatalogoHipocentrosHistoricos.getVisible()) {
        this.vectorCatalogoHipocentrosHistoricos.setVisible(false);
        this.setState({
          mapa_hipocentroshistoricos:"capa_inactivac"
        })
      }
      else{
        this.vectorCatalogoHipocentrosHistoricos.setVisible(true);
        this.vectorHeatMap.setVisible(false);
        this.vectorMagnitud.setVisible(false);
        this.vectorMagnitudRED.setVisible(false);
        this.setState({
          mapa_epicentral:"capa_inactivac",
          mapa_epicentralred:"capa_inactivac",
          mapa_calor:"capa_inactivac",
          mapa_hipocentroshistoricos:"capa_activac",
          slide_detail:'Caracteristicas_popover', 
          mapa_intensidades_absolute:'MapaIntensidades_oculto', 
        })
      }      
    }

    //activa capa de hipocentros USGS
    capa_hipocentrosUSGS = () => {
      if (this.vectorCatalogoHipocentrosUSGS.getVisible()) {
        this.vectorCatalogoHipocentrosUSGS.setVisible(false);
        this.setState({
          mapa_hipocentrosUSGS:"capa_inactivac"
        })
      }
      else{
        this.vectorCatalogoHipocentrosUSGS.setVisible(true);
        this.vectorCatalogoHipocentrosIGP.setVisible(false)
        this.vectorHeatMap.setVisible(false);
        this.vectorMagnitud.setVisible(false);
        this.vectorMagnitudRED.setVisible(false);
        this.setState({
          mapa_epicentral:"capa_inactivac",
          mapa_epicentralred:"capa_inactivac",
          mapa_calor:"capa_inactivac",
          mapa_hipocentrosUSGS:"capa_activac",
          mapa_hipocentrosIGP:"capa_inactivac",
          slide_detail:'Caracteristicas_popover', 
          mapa_intensidades_absolute:'MapaIntensidades_oculto', 
        })
      }      
    }
    
    //activa capa de hipocentros IGP
    capa_hipocentrosIGP = () => {
      if (this.vectorCatalogoHipocentrosIGP.getVisible()) {
        this.vectorCatalogoHipocentrosIGP.setVisible(false);
        this.setState({
          mapa_hipocentrosIGP:"capa_inactivac"
        })
      }
      else{
        this.vectorCatalogoHipocentrosIGP.setVisible(true);
        this.vectorCatalogoHipocentrosUSGS.setVisible(false)
        this.vectorHeatMap.setVisible(false);
        this.vectorMagnitud.setVisible(false);
        this.vectorMagnitudRED.setVisible(false);
        this.setState({
          mapa_epicentral:"capa_inactivac",
          mapa_epicentralred:"capa_inactivac",
          mapa_calor:"capa_inactivac",
          mapa_hipocentrosUSGS:"capa_inactivac",
          mapa_hipocentrosIGP:"capa_activac",
          slide_detail:'Caracteristicas_popover', 
          mapa_intensidades_absolute:'MapaIntensidades_oculto', 
        })
      }      
    }

    //activa capa del mapa epicentral CERESIS
    capa_hipoc = () => {
      if (this.vectorMagnitud.getVisible()) {
        this.vectorHeatMap.setVisible(false);
        this.vectorMagnitud.setVisible(false);
        this.vectorCatalogoHipocentros.setVisible(false);
        this.vectorCatalogoHipocentrosHistoricos.setVisible(false);
        this.vectorCatalogoHipocentrosUSGS.setVisible(false);
        this.vectorCatalogoHipocentrosIGP.setVisible(false);
        this.setState({
          mapa_epicentral:"capa_inactivac",
          mapa_calor:"capa_inactivac",
          mapa_hipocentros:"capa_inactivac",
          mapa_hipocentroshistoricos:"capa_inactivac",
          mapa_hipocentrosUSGS:"capa_inactivac",
          mapa_hipocentrosIGP:"capa_inactivac"
        })
      }
      else{
        this.vectorHeatMap.setVisible(false);
        this.vectorMagnitud.setVisible(true);
        this.vectorCatalogoHipocentros.setVisible(false);
        this.vectorCatalogoHipocentrosHistoricos.setVisible(false);
        this.vectorCatalogoHipocentrosUSGS.setVisible(false);
        this.vectorCatalogoHipocentrosIGP.setVisible(false);
        this.setState({
          mapa_epicentral:"capa_activac",
          mapa_calor:"capa_inactivac",
          mapa_hipocentros:"capa_inactivac",
          mapa_hipocentroshistoricos:"capa_inactivac",
          mapa_hipocentrosUSGS:"capa_inactivac",
          mapa_hipocentrosIGP:"capa_inactivac",
          slide_detail:'Caracteristicas_popover', 
          mapa_intensidades_absolute:'MapaIntensidades_oculto',           
        })
      }
    }
    //activa capa del mapa epicentral RED ACELEROGRAFICA
    capa_hipoc_red = () => {
      if (this.vectorMagnitudRED.getVisible()) {
        this.vectorHeatMap.setVisible(false);
        this.vectorMagnitudRED.setVisible(false);
        this.vectorCatalogoHipocentros.setVisible(false);
        this.vectorCatalogoHipocentrosHistoricos.setVisible(false);
        this.vectorCatalogoHipocentrosUSGS.setVisible(false);
        this.vectorCatalogoHipocentrosIGP.setVisible(false);
        this.setState({
          mapa_epicentralred:"capa_inactivac",
          mapa_calor:"capa_inactivac",
          mapa_hipocentros:"capa_inactivac",
          mapa_hipocentroshistoricos:"capa_inactivac",
          mapa_hipocentrosUSGS:"capa_inactivac",
          mapa_hipocentrosIGP:"capa_inactivac",
        })
      }
      else{
        this.vectorHeatMap.setVisible(false);
        this.vectorMagnitudRED.setVisible(true);
        this.vectorCatalogoHipocentros.setVisible(false);
        this.vectorCatalogoHipocentrosHistoricos.setVisible(false);
        this.vectorCatalogoHipocentrosUSGS.setVisible(false);
        this.vectorCatalogoHipocentrosIGP.setVisible(false);
        this.setState({
          mapa_epicentralred:"capa_activac",
          mapa_calor:"capa_inactivac",
          mapa_hipocentros:"capa_inactivac",
          mapa_hipocentroshistoricos:"capa_inactivac",
          mapa_hipocentrosUSGS:"capa_inactivac",
          mapa_hipocentrosIGP:"capa_inactivac",
          slide_detail:'Caracteristicas_popover', 
          mapa_intensidades_absolute:'MapaIntensidades_oculto', 
        })
      }
    }
    //activa capa del mapa epicentral
    capa_fallas = () => {
      if (this.fallaLayer.getVisible()) {
        this.fallaLayer.setVisible(false);
        this.setState({fallas_geo:"capa_inactivac", t_leyenda_fallas:"sin_leyenda", leyenda_fallas:"sin_leyenda"})
      }
      else{
        this.fallaLayer.setVisible(true);
        this.setState({fallas_geo:"capa_activac", t_leyenda_fallas:"titulo_submenu", leyenda_fallas:"leyenda"})
      }      
    }
    //Cambia Mapas
    toggleShowBM=()=>{
      this.bingmap.setVisible(true);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(false);
      this.terreno.setVisible(false);
      this.Nocturno.setVisible(false);
      this.GMaps.setVisible(false);
      this.GMapsTerreno.setVisible(false);
      this.GMapsSatelite.setVisible(false);
    };
    toggleShowBMR=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(true);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(false);
      this.terreno.setVisible(false);
      this.Nocturno.setVisible(false);
      this.GMaps.setVisible(false);
      this.GMapsTerreno.setVisible(false);
      this.GMapsSatelite.setVisible(false);
    };
    toggleShowWC=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(true);
      this.ByN.setVisible(false);
      this.terreno.setVisible(false);
      this.Nocturno.setVisible(false);
      this.GMaps.setVisible(false);
      this.GMapsTerreno.setVisible(false);
      this.GMapsSatelite.setVisible(false);
    };
    toggleShowOSM=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(true);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(false);
      this.terreno.setVisible(false);
      this.Nocturno.setVisible(false);
      this.GMaps.setVisible(false);
      this.GMapsTerreno.setVisible(false);
      this.GMapsSatelite.setVisible(false);
    };
    toggleShowByN=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(true);
      this.terreno.setVisible(false);
      this.Nocturno.setVisible(false);
      this.GMaps.setVisible(false);
      this.GMapsTerreno.setVisible(false);
      this.GMapsSatelite.setVisible(false);
    }
    toggleShowTerreno=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(false);
      this.terreno.setVisible(true);
      this.Nocturno.setVisible(false);
      this.GMaps.setVisible(false);
      this.GMapsTerreno.setVisible(false);
      this.GMapsSatelite.setVisible(false);
    }
    toggleShowNocturno=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(false);
      this.terreno.setVisible(false);
      this.Nocturno.setVisible(true);
      this.GMaps.setVisible(false);
      this.GMapsTerreno.setVisible(false);
      this.GMapsSatelite.setVisible(false);
    }
    toggleShowGMaps=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(false);
      this.terreno.setVisible(false);
      this.Nocturno.setVisible(false);
      this.GMaps.setVisible(true);
      this.GMapsTerreno.setVisible(false);
      this.GMapsSatelite.setVisible(false);
    }
    toggleShowGMapsTerreno=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(false);
      this.terreno.setVisible(false);
      this.Nocturno.setVisible(false);
      this.GMaps.setVisible(false);
      this.GMapsTerreno.setVisible(true);
      this.GMapsSatelite.setVisible(false);
    }
    toggleShowGMapsSatelite=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(false);
      this.terreno.setVisible(false);
      this.Nocturno.setVisible(false);
      this.GMaps.setVisible(false);
      this.GMapsTerreno.setVisible(false);
      this.GMapsSatelite.setVisible(true);
    }

    //Buscador de Direcciones
    cambia_direccion(event) {
      this.setState({value: event.target.value});
      console.log(this.state.value);
    }
    busca_direccion(event) {
      this.convert_direccion(this.state.value);
      event.preventDefault();
    }
    convert_direccion(direccion){
      Geocode.setApiKey("AIzaSyBEN1zVUqxX4a32voiBUrmEl_8BeOIt4_I");
      Geocode.setLanguage("latam");
      Geocode.setRegion("pe");
      Geocode.enableDebug();
      Geocode.fromAddress(direccion).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location;
          console.log(lat, lng);
          this.center_marker(lat,lng,17);
        },
        error => {
          console.error(error);
        }
      );
    }
    center_marker(latitud, longitud, zoom_acercar) {
      this.map.setView(
        new View({
          center: fromLonLat([longitud,latitud]),
          zoom: zoom_acercar
        })
      );
    }
    
    //Hacer Zoom
    hacer_zoom = () =>{
      this.coordenadasS = [this.state.longitud_zoom, this.state.latitud_zoom];
      this.map.setView(
        new View({
          center: fromLonLat(this.coordenadasS),
          zoom: 18
        })
      );
    }

    //Centrar Mapa -70.048 -9.4913 5.5
    centrar_mapa = (lat, lon, zoom) =>{
      this.map.setView(
        new View({
          center: fromLonLat([lon , lat]),
          zoom: zoom ,
        })
      );
    }

    //Close Características
    close_caracteristicas = () => {
      this.setState({slide_detail: 'Caracteristicas_popover'})
    }

    //Close Modal
    close_modal = () => {
      //this.setState({toggle_modal:false});
      this.setState({mapa_intensidades_absolute:'MapaIntensidades_oculto'});
      //this.map_modal.updateSize();
    }

    //Abrir Modal
    open_modal = () => {
      //this.setState({toggle_modal:true});
      this.setState({mapa_intensidades_absolute:'MapaIntensidades'})
      //this.sourceIntensidad.refresh();
      //this.Obtener_Hipocentros('https://datacatalogo.citdi.uni.edu.pe/hipocentros.geojson/4');
      //this.Obtener_Intensidades('https://datacatalogo.citdi.uni.edu.pe/intensidades.geojson/4')
    }

    //Date Pickers Inicio y Fin
    handleDateChange = (value) =>{
      console.log("Selecciona Fecha", value)
      this.setState({
        date_ini: value
      })
    }
    handleDateSelect = (value) =>{
      console.log("Selecciona Fecha", value)
      this.setState({
        date_ini: value
      })
    }
    handleDateChange_end = (value) =>{
      console.log("Selecciona Fecha", value)
      this.setState({
        date_end: value
      })
    }
    handleDateSelect_end = (value) =>{
      console.log("Selecciona Fecha", value)
      this.setState({
        date_end: value
      })
    }

    set_state_filtrador = (valor) =>{
      this.filtros = ["fecha","pais","area","magnitud","fuente"];
      this.filtros.forEach(element => {
        let filtrador = 'filter_' + element;
        if (element == valor) {
          this.setState({[filtrador]:"muestra_filtro_"+valor})
        } else {
          this.setState({[filtrador]:"oculta_filtro_"+valor})
        }
      });
      if (valor=="area"){this.vectorClick.setVisible(true)}else{this.vectorClick.setVisible(false)}
    }
    select_filter_type = (event) =>{
      this.setState({type_filter: event.target.value});
      this.set_state_filtrador(event.target.value)
      // this.filter_selected = event.target.value;
      // this.setState({
      //   type_filter: this.filter_selected
      // });
      // if (this.filter_selected=="pais") {
      //   this.set_state("pais")
      // }
      // if (this.filter_selected=="fuente") {
      //   this.set_state('fuente')
      // }
      // else {
        
      // }
    }
    select_geometry_filter = (event) =>{
      this.setState({type_geometry_filter: event.target.value})
    }
    
    select_geometry_radio = (event) =>{
      this.setState({type_geometry_radio: event.target.value})
    }
    select_fuente = (event) =>{
      this.setState({type_fuente: event.target.value})
    }
    select_magnitud = (event) =>{
      this.setState({type_magnitud: event.target.value})
    }
    select_pais = (event) =>{
      this.setState({type_pais: event.target.value})
    }

    region=(pais)=>{
      if (pais=='PERU') {
        this.centrar_mapa(-9.4913, -70.048, 5.5)
      } if (pais=='CHILE') {
        this.centrar_mapa(-34.4913, -70.048, 5.0)
      } if (pais=='ARGENTINA') {
        this.centrar_mapa(-35.4913, -65.048, 5.0)
      } if (pais=='ECUADOR') {
        this.centrar_mapa(-0.9, -77.048, 7.0)
      } if (pais=='COLOMBIA') {
        this.centrar_mapa(3.5, -74.048, 6.1)
      } if (pais=='BRASIL') {
        this.centrar_mapa(-15.4913, -55.048, 4.7)
      } if (pais=='BOLIVIA') {
        this.centrar_mapa(-16.4913, -66.048, 6.2)
      } if (pais=='VENEZUELA') {
        this.centrar_mapa(6.0, -67.048, 6.5)
      }
    }

    aplicar_filtro_mapa(){
      if (this.state.type_filter=="fuente") {
        //Filtro por fuente
        console.log("Filter by Source");
        this.sourceMagnitud.setUrl("https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/filtersource/compiled=CERESIS&source="+this.state.type_fuente);
        this.sourceMagnitud.refresh();
        this.sourceMagnitudRED.setUrl("https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/filtersource/compiled=REDACELEROGRAFICA&source="+this.state.type_fuente);
        this.sourceMagnitudRED.refresh();
        this.sourceHeatMap.setUrl("https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/filtersource/compiled=HEAT&source="+this.state.type_fuente);
        this.sourceHeatMap.refresh();
        this.centrar_mapa(-20.4913, -66.048, 4.2)

      }if (this.state.type_filter=="fecha") {
        //Filtro por fecha
        console.log("Filter by Date");
        this.sourceMagnitud.setUrl("https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/filterdate/compiled=CERESIS&date_ini="+this.state.date_ini.toISOString().slice(0,10)+"&&date_end="+this.state.date_end.toISOString().slice(0,10));
        this.sourceMagnitud.refresh();
        this.sourceMagnitudRED.setUrl("https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/filterdate/compiled=REDACELEROGRAFICA&date_ini="+this.state.date_ini.toISOString().slice(0,10)+"&&date_end="+this.state.date_end.toISOString().slice(0,10));
        this.sourceMagnitudRED.refresh();
        this.sourceCatalogoHipocentros.setUrl("https://datacatalogo.citdi.uni.edu.pe/catalogohipocentros.geojson/filterdate/date_ini="+this.state.date_ini.toISOString().slice(0,10)+"&&date_end="+this.state.date_end.toISOString().slice(0,10));
        this.sourceCatalogoHipocentros.refresh();
        this.sourceCatalogoHipocentrosUSGS.setUrl("https://datacatalogo.citdi.uni.edu.pe/catalogohipocentrosUSGS.geojson/filterdate/date_ini="+this.state.date_ini.toISOString().slice(0,10)+"&&date_end="+this.state.date_end.toISOString().slice(0,10));
        this.sourceCatalogoHipocentrosUSGS.refresh();
        this.sourceCatalogoHipocentrosHistoricos.setUrl("https://datacatalogo.citdi.uni.edu.pe/catalogohipocentroshistoricos.geojson/filterdate/date_ini="+this.state.date_ini.toISOString().slice(0,10)+"&&date_end="+this.state.date_end.toISOString().slice(0,10));
        this.sourceCatalogoHipocentrosHistoricos.refresh();
        this.sourceHeatMap.setUrl("https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/filterdate/compiled=HEAT&date_ini="+this.state.date_ini.toISOString().slice(0,10)+"&&date_end="+this.state.date_end.toISOString().slice(0,10));
        this.sourceHeatMap.refresh();
        console.log("https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/filterdate/date_ini="+this.state.date_ini.toISOString().slice(0,10)+"&&date_end="+this.state.date_end.toISOString().slice(0,10));
        this.centrar_mapa(-20.4913, -66.048, 4.2)

      }if (this.state.type_filter=="pais") {
        //Filtro por pais
        console.log("Filter by Country:"+this.state.type_pais);

        this.sourceMagnitud.setUrl("https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/filtercountry/compiled=CERESIS&country="+this.state.type_pais);
        this.sourceMagnitud.refresh();
        this.sourceMagnitudRED.setUrl("https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/filtercountry/compiled=REDACELEROGRAFICA&country="+this.state.type_pais);
        this.sourceMagnitudRED.refresh();
        this.sourceCatalogoHipocentros.setUrl("https://datacatalogo.citdi.uni.edu.pe/catalogohipocentros.geojson/filtercountry/country="+this.state.type_pais);
        this.sourceCatalogoHipocentros.refresh();
        this.sourceCatalogoHipocentrosUSGS.setUrl("https://datacatalogo.citdi.uni.edu.pe/catalogohipocentrosUSGS.geojson/filtercountry/country="+this.state.type_pais);
        this.sourceCatalogoHipocentrosUSGS.refresh();
        this.sourceCatalogoHipocentrosHistoricos.setUrl("https://datacatalogo.citdi.uni.edu.pe/catalogohipocentroshistoricos.geojson/filtercountry/country="+this.state.type_pais);
        this.sourceCatalogoHipocentrosHistoricos.refresh();
        this.sourceHeatMap.setUrl("https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/filtercountry/compiled=HEAT&country="+this.state.type_pais);
        this.sourceHeatMap.refresh();
        this.region(this.state.type_pais)

      }if (this.state.type_filter=="area") {
        //Filtro por area
        console.log("Filter by Area");
        this.sourceMagnitud.setUrl("https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/filterarea/compiled=CERESIS&latitude="+String(this.state.lat_filter)+"&&longitude="+String(this.state.lon_filter)+"&&type="+this.state.type_geometry_filter+"&&radio="+this.state.type_geometry_radio);
        this.sourceMagnitud.refresh();
        this.sourceMagnitudRED.setUrl("https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/filterarea/compiled=REDACELEROGRAFICA&latitude="+String(this.state.lat_filter)+"&&longitude="+String(this.state.lon_filter)+"&&type="+this.state.type_geometry_filter+"&&radio="+this.state.type_geometry_radio);
        this.sourceMagnitudRED.refresh();
        this.sourceCatalogoHipocentros.setUrl("https://datacatalogo.citdi.uni.edu.pe/catalogohipocentros.geojson/filterarea/latitude="+String(this.state.lat_filter)+"&&longitude="+String(this.state.lon_filter)+"&&type="+this.state.type_geometry_filter+"&&radio="+this.state.type_geometry_radio);
        this.sourceCatalogoHipocentros.refresh();
        this.sourceCatalogoHipocentrosUSGS.setUrl("https://datacatalogo.citdi.uni.edu.pe/catalogohipocentrosUSGS.geojson/filterarea/latitude="+String(this.state.lat_filter)+"&&longitude="+String(this.state.lon_filter)+"&&type="+this.state.type_geometry_filter+"&&radio="+this.state.type_geometry_radio);
        this.sourceCatalogoHipocentrosUSGS.refresh();
        this.sourceCatalogoHipocentrosHistoricos.setUrl("https://datacatalogo.citdi.uni.edu.pe/catalogohipocentroshistoricos.geojson/filterarea/latitude="+String(this.state.lat_filter)+"&&longitude="+String(this.state.lon_filter)+"&&type="+this.state.type_geometry_filter+"&&radio="+this.state.type_geometry_radio);
        this.sourceCatalogoHipocentrosHistoricos.refresh();
        this.sourceHeatMap.setUrl("https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/filterarea/compiled=HEAT&latitude="+String(this.state.lat_filter)+"&&longitude="+String(this.state.lon_filter)+"&&type="+this.state.type_geometry_filter+"&&radio="+this.state.type_geometry_radio);
        this.sourceHeatMap.refresh();

      } if (this.state.type_filter=="magnitud") {
        //Filtro por magnitud
        console.log("Filter by Magnitude");
        this.sourceMagnitud.setUrl("https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/filtermagnitude/compiled=CERESIS&magnitude="+this.state.type_magnitud);
        this.sourceMagnitud.refresh();
        this.sourceMagnitudRED.setUrl("https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/filtermagnitude/compiled=REDACELEROGRAFICA&magnitude="+this.state.type_magnitud);
        this.sourceMagnitudRED.refresh();
        this.sourceHeatMap.setUrl("https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/filtermagnitude/compiled=HEAT&magnitude="+this.state.type_magnitud);
        this.sourceHeatMap.refresh();
        this.sourceCatalogoHipocentros.setUrl("https://datacatalogo.citdi.uni.edu.pe/catalogohipocentros.geojson/filtermagnitude/magnitude="+this.state.type_magnitud);
        this.sourceCatalogoHipocentros.refresh();
        this.sourceCatalogoHipocentrosUSGS.setUrl("https://datacatalogo.citdi.uni.edu.pe/catalogohipocentrosUSGS.geojson/filtermagnitude/magnitude="+this.state.type_magnitud);
        this.sourceCatalogoHipocentrosUSGS.refresh();
        this.centrar_mapa(-20.4913, -66.048, 4.2)
      }
      
    }

    cambia_lat_filter=(event)=>{
      this.setState({lat_filter: event.target.value});
      this.sourceClick.setUrl('https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/mapaclic/latitude='+String(event.target.value)+'&&longitude='+this.state.lon_filter);
      this.sourceClick.refresh();
    }
    cambia_lon_filter=(event)=>{
      this.setState({lon_filter: event.target.value})
      this.sourceClick.setUrl('https://datacatalogo.citdi.uni.edu.pe/sismos.geojson/mapaclic/latitude='+this.state.lat_filter+'&&longitude='+String(event.target.value));
      this.sourceClick.refresh();
    }
    Type_Imm(){
      if (this.state.recopilado_detalle=="CERESIS") {
        return "INTENSIDAD"
      }
      if (this.state.recopilado_detalle=="RED ACELEROGRAFICA") {
        return "INTENSIDAD INSTRUMENTAL"
      }
    }

    render(){
        return(
          <div className="panel">
            <div className="catalogo">
              <div className="SideNavMap">
                <ProSidebar collapsed={this.state.sidebar_state} breakPoint='xs' className="pro-side-bar">
                  <SidebarHeader>
                    <Menu iconShape="round">
                        <MenuItem icon={<CgHomeAlt />} /*onClick={this.toggle_side}*/ title="Catálogo implementado por el Centro de Investigación en Transformación Digital en Ingeniería"><strong className="titulo_header_side"> <a href="/">Portada C.I.T.D.I.</a></strong></MenuItem>
                      </Menu>
                  </SidebarHeader>
                  <SidebarContent>
                    <Menu iconShape="circle">
                      
                      <SubMenu title="Mapas" className="mapas_titulo" icon={< FaMap />}>
                      <p className="maps_type_web">
                        <button onClick={this.toggleShowBM} title="Mapa Satelital" className="boton_satelital">Satelital</button>
                        <button onClick={this.toggleShowBMR} title="Mapa Vial" className="boton_road">Vial</button>
                        <button onClick={this.toggleShowWC} title="Mapa Fondo de Agua" className="boton_wc">Agua</button>
                        <button onClick={this.toggleShowOSM} title="Mapa Básico" className="boton_osm">OSM</button>
                        <button onClick={this.toggleShowByN} title="Mapa de Fondo Blanco y Negro" className="boton_byn">Binario</button>
                        <button onClick={this.toggleShowTerreno} title="Mapa Vista de Terreno" className="boton_terreno">Terreno</button>

                        <button onClick={this.toggleShowNocturno} title="Mapa Vista Nocturna" className="boton_nocturno">Nocturno</button>
                        <button onClick={this.toggleShowGMaps} title="Mapa Vista Google Maps" className="boton_gmaps">G Maps</button>
                        <button onClick={this.toggleShowGMapsTerreno} title="Mapa Vista Google Maps Terreno" className="boton_gmapsterreno">GM Terreno</button>
                        <button onClick={this.toggleShowGMapsSatelite} title="Mapa Vista Google Maps Satelital" className="boton_gmapssatelite">GM Satelite</button>
                      </p>
                      </SubMenu>
                      <SubMenu title="Catálogo" className="capas_titulo" icon={<FaLayerGroup />}>
                        <MenuItem onClick={this.capa_hipoc}> <strong className="titulo_capa"><FaGlobeAmericas className={this.state.mapa_epicentral}/> Intensidades CERESIS <br/><small>(Datos, desde: 1541 - 1985)</small></strong></MenuItem>
                        <MenuItem onClick={this.capa_hipocentros}> <strong className="titulo_capa"><BsGeo className={this.state.mapa_hipocentros}/> Hipocentros  CERESIS <br/><small>(Datos, desde: 1541 - 1991)</small></strong></MenuItem>
                        <MenuItem onClick={this.capa_hipocentroshistoricos}> <strong className="titulo_capa"><BsGeo className={this.state.mapa_hipocentroshistoricos}/> Hipocentros CERESIS <br/><small>(Históricos, desde: 1471 - 1991)</small></strong> </MenuItem>
                        <MenuItem onClick={this.capa_hipocentrosUSGS}> <strong className="titulo_capa"><BsGeo className={this.state.mapa_hipocentrosUSGS}/> Hipocentros  USGS <br/><small>(Datos, desde: 1900 - 2021)</small><br/><small>(Mapa, Inicial: 2010 - 2021)</small></strong> </MenuItem>
                        <MenuItem onClick={this.capa_hipocentrosIGP}> <strong className="titulo_capa"><BsGeo className={this.state.mapa_hipocentrosIGP}/> Hipocentros  IGP <br/><small>(Datos, desde: 2016 - 2021)</small></strong> </MenuItem>
                        <MenuItem onClick={this.capa_hipoc_red}> <strong className="titulo_capa"><FaGlobeAmericas className={this.state.mapa_epicentralred}/> Intensidades CITDI <br/><small className="aporte">(Aporte, Datos: 2016 - 2021)</small></strong> </MenuItem>
                        <MenuItem onClick={this.capa_calor}> <strong className="titulo_capa"><FaHotjar className={this.state.mapa_calor}/> Mapa de Calor  <br/><small className="aporte">(Aporte: Hip. USGS 2010 - 2021)</small></strong> </MenuItem>
                        <MenuItem onClick={this.capa_fallas}> <strong className="titulo_capa"><BiStats className={this.state.fallas_geo}/> Fallas Geológicas  <br/><small>(Solo PERÚ, Fuente: Ingemmet)</small></strong> </MenuItem>
                      </SubMenu>
                      <SubMenu title="Buscar Dirección" className="capas_titulo" icon={<FaSearchLocation />}>
                        <form onSubmit={this.busca_direccion} className="form-busca-direccion" title="Escribe tu dirección para encontrarte">
                          Busca una Dirección
                          <label className="label-busca-direccion" title="Escribe tu dirección para encontrarte">
                            <input  className="input-busca-direccion" type="text" value={this.state.value} onChange={this.cambia_direccion} placeholder="Ingresa direción a buscar" title="Escribe tu dirección para encontrarte"/>
                            <FaSearchLocation className="icon-busca-direccion" onClick={this.busca_direccion} title="Escribe tu dirección para encontrarte"/>
                          </label>
                        </form>
                      </SubMenu>
                      <SubMenu title="Filtros" className="capas_titulo" icon={<MdFilterList/>} >
                        <form>
                          <label>
                            <span className="titulo_submenu">Filtrar por: </span>
                            <select value={this.state.type_filter} onChange={this.select_filter_type}>
                              <option value="area">Área</option>
                              <option value="magnitud">Magnitud</option>
                              <option value="pais">Pais</option>
                              <option value="fuente">Fuente</option>
                              <option value="fecha">Fecha</option>
                            </select>
                          </label>

                          <div className={this.state.filter_fecha}>
                            <br/>
                            <span className="titulo_submenu">Seleccione Rango: </span><br/>
                            <Row className="name_datepicker">
                              <strong >Inicio: </strong>
                              <DatePicker selected={this.state.date_ini} onSelect={this.handleDateSelect} onChange={this.handleDateChange} className = "date_mapa" dateFormatCalendar = "yyyy" showYearPicker/>
                              </Row> 
                              <Row className="name_datepicker">
                              <strong>Final : </strong>
                              <DatePicker selected={this.state.date_end} onSelect={this.handleDateSelect_end} onChange={this.handleDateChange_end}  className = "date_mapa" dateFormatCalendar = "yyyy" showYearPicker />
                              </Row>
                              <br/><br/>
                          </div>

                          <div className={this.state.filter_pais}>
                            <br/>
                            <span className="titulo_submenu">País: </span>
                            <select value={this.state.type_pais} onChange={this.select_pais}>
                              <option value="PERU">Perú</option>
                              <option value="CHILE">Chile</option>
                              <option value="ARGENTINA">Argentina</option>
                              <option value="ECUADOR">Ecuador</option>
                              <option value="COLOMBIA">Colombia</option>
                              <option value="BRASIL">Brasil</option>
                              <option value="BOLIVIA">Bolivia</option>
                              <option value="VENEZUELA">Venezuela</option>
                            </select>
                            <br/><br/>
                          </div>

                          <div className={this.state.filter_area}>
                            <br/>
                            <span className="titulo_submenu">Área:</span><br/>
                            <span className="label_area">Clic en el mapa o ingrese coordenadas:</span><label className="label_area">Lat : <input className="input_area" onChange={this.cambia_lat_filter} value={this.state.lat_filter} label="Latitud" type="text"/></label><label className="label_area">Lon: <input className="input_area"  onChange={this.cambia_lon_filter} value={this.state.lon_filter} label="Longitud" type="text"/></label>
                            <span className="label_area">Seleccione Tipo: </span>
                            <select value={this.state.type_geometry_filter} onChange={this.select_geometry_filter}>                              
                              <option value="circular">Circular</option>
                              <option value="cuadrado">Cuadrado</option>
                            </select>
                            <span className="label_area">Seleccione Radio: </span>
                            <select value={this.state.type_geometry_radio} onChange={this.select_geometry_radio}>                              
                              <option value="1">1 Km</option>
                              <option value="5">5 Km</option>
                              <option value="10">10 Km</option>
                              <option value="20">20 Km</option>
                              <option value="50">50 km</option>
                              <option value="100">100 Km</option>
                              <option value="500">500 Km</option>
                            </select>
                            <br/><br/>
                          </div>

                          <div className={this.state.filter_fuente}>
                            <br/>
                            <span className="titulo_submenu">Fuente: </span>
                            <select value={this.state.type_fuente} onChange={this.select_fuente}>                              
                              <option value="IGP">IGP</option>
                              <option value="USGS">USGS</option>
                              <option value="CERESIS">CERESIS</option>
                              <option value="IGEPN">IGEPN</option>
                              <option value="CSN">CSN</option>
                            </select>
                            <br/><br/>
                          </div>
                          <div className={this.state.filter_magnitud}>
                            <br/>
                            <span className="titulo_submenu">Magnitud: </span>
                            <select value={this.state.type_magnitud} onChange={this.select_magnitud}>                              
                              <option value="2.5">+ 2.5 </option>
                              <option value="3.5">+ 3.5 </option>
                              <option value="5.0">+ 5.0 </option>
                              <option value="7.0">+ 7.0 </option>
                            </select>
                            <br/><br/>
                          </div>
                          <MDBBtn className="text_filtrar" color="white" rounded size="md" onClick={this.aplicar_filtro_mapa}>
                            <MDBIcon far icon="eye" className="left" /> Filtrar
                          </MDBBtn>
                          <br/>
                          {/* <div> 
                            <MDBBtn className="titulo_submenu" color="transparent" rounded size="md" onClick={this.filtrar_fuente_mapa}>
                              <MDBIcon far icon="eye" className="left" /> Avanzado
                            </MDBBtn>
                          </div> */}
                        </form>
                        {/* <MenuItem> <a onClick={this.Dibuja_Poligono}>Filtro por Área</a></MenuItem>
                        <MenuItem> <a onClick={this.Remove_Draw}> Filtro por Magnitud </a></MenuItem>
                        <MenuItem> Filtro por País </MenuItem>
                        <MenuItem> Filtro por Fuente </MenuItem>
                        <MenuItem> Filtro por Fecha </MenuItem> */}

                      </SubMenu>
                      <MenuItem icon={<BsGeoAlt />} title="GeoPosicionamiento"  onClick={this.GeoPositionInMap}><strong className="titulo_content_side"> GeoPosicionamiento </strong></MenuItem>
                      <MenuItem icon={<MdCenterFocusStrong/>} title="Centrar Mapa" onClick={()=>this.centrar_mapa(-9.4913, -70.048, 5.5)}><strong className="titulo_content_side">Centrar Mapa</strong></MenuItem>
                      {/* <MenuItem icon={<MdRefresh />} title="Restablecer Mapa"  onClick={this.RestableceMapa}><strong className="titulo_content_side"> Restablecer Mapa </strong></MenuItem> */}
                    </Menu>
                  </SidebarContent>
                  <SidebarFooter>
                    <Menu iconShape="round">
                      
                      <span className="Fecha"><p className="titulo_leyenda_fallas"><strong >Acceso:</strong></p>
                      {String(Date()).slice(0,32)}</span>
                      {/*<SubMenu title={Date()} icon={<FaList />} className="leyenda_titulo" >
                           <h6 className="titulo_submenu"><strong>Magnitud</strong></h6>
                          <img className="leyenda_magnitud" src="http://www.red-acelerografica-peru.uni.edu.pe/img/gis/escala_magnitud.png"/> 
                      </SubMenu>*/}
                    </Menu>
                  </SidebarFooter>
                </ProSidebar>
              </div>
              
              <div className="mapa" id="mapaCatalogo" ref="mapaCatalogo"/>

            </div>
            <div className="leyenda_general">
              <h6 className="titulo_leyenda_fallas"><strong>Magnitud</strong></h6>
              <img className="leyenda_magnitud" src={escala_magnitud}/>
              <div className={this.state.leyenda_fallas}>
                <h6 className="titulo_leyenda_fallas"><strong>Fallas Geológicas</strong></h6>
                <img className="leyenda_image_fallas" src={fallas_leyenda}/>             
              </div>
            </div>
            <div className={this.state.slide_detail}>
              <div className="titulo_detail"><div className="Titulo_d">Caracteristicas del Sismo </div><CgClose className="cerrar_detail" onClick={this.close_caracteristicas}/></div>
              <div className="content_detail">
                <tr><td><strong>Nombre:</strong></td><td>{this.state.nombre_detalle}</td></tr>
                <tr><td><strong>Latitud:</strong></td><td>{this.state.latitud_detalle} °</td></tr>
                <tr><td><strong>Longitud:</strong></td><td>{this.state.longitud_detalle} °</td></tr>
                <tr><td><strong>Profundidad</strong></td><td>{this.state.profundidad_detalle} Km</td></tr>
                <tr><td><strong>Magnitud:</strong></td><td>{this.state.magnitud_detalle} {this.state.unidad_mag_detalle}</td></tr>
                <tr><td><strong>Fecha Local:</strong></td><td>{this.state.fecha_detalle}</td></tr>
                <tr><td><strong>Referencia:</strong></td><td>{this.state.referencia_detalle}</td></tr>
                <tr><td><strong>Fuente:</strong></td><td>{this.state.recopilado_detalle}</td></tr>
                {this.Open_Mapa_Intensidades()}
                {/* <tr><td><strong>Intensidades:</strong></td><td><span> Ver Mapa <FaEye onClick={this.open_modal}/></span></td></tr> */}
              </div>
            </div>
            <div className={this.state.mapa_intensidades_absolute}>
              <Modal.Header className="Header_Modal" closeButton variant="primary" onHide={this.close_modal}>
                <Modal.Title className="Title_Modal" variant="primary"> Intensidades  </Modal.Title>
              </Modal.Header>
              <Container className='contenedor_modal' size='xl'>
                <Row className='fila_modal'>
                  <Col xs={12} md={8} className='mapa_intensidad'>
                    <div className="mapa_int" id="mapaIntensidades" ref="mapaIntensidades"/>
                    <img className="legend_intensidad" src={leyenda_intensidades}/>
                  </Col>
                  <Col xs={12} md={4} className='tabla_intensidad'>
                  <div className="Datos_Sismos">
                    <h5 className="Titulo_Modal"><strong>Datos</strong></h5>
                    <p className="seccion_tabla">
                      <table className="tabla_datos">
                        <tr><th>Nombre: </th><td> {this.state.nombre_detalle}</td></tr>            
                        <tr><th>Fecha: </th><td> {this.state.fecha_detalle}</td></tr>
                      </table>
                    </p>
                  </div>
                  <div className="Hipocentrales">
                    <h5 className="Titulo_Modal"><strong>Hipocentros</strong></h5>
                    <p className="seccion_tabla">
                      <table className="tabla_hipocentros">
                        <tr><th>Fuente</th><th>Latitud</th><th>Longitud</th><th>Prof.</th><th>Magnitud</th></tr>
                        <tbody>
                          {this.Data_Hipocentros()}
                          {/* <tr><td>IGP</td><td>{this.state.latitud_detalle} </td><td>{this.state.longitud_detalle}</td><td>{this.state.profundidad_detalle}</td><td>{this.state.magnitud_detalle} {this.state.unidad_mag_detalle}</td><td></td></tr>
                          <tr><td>USGS</td><td>{this.state.latitud_detalle} </td><td>{this.state.longitud_detalle}</td><td>{this.state.profundidad_detalle}</td><td>{this.state.magnitud_detalle} {this.state.unidad_mag_detalle}</td><td></td></tr> */}
                        </tbody>
                      </table>
                      </p>
                  </div>
                  <div className="Intensidades">
                    <h5 className="Titulo_Modal"><strong>{this.Type_Imm()}</strong>{this.Referencia_Intensidades()}</h5>
                    <p className="seccion_tabla">
                    <table className="tabla_intensidades">
                    <tr><th>Id</th><th>Latitud</th><th>Longitud</th><th>Intensidad</th></tr>
                    <tbody>
                      {this.Data_Intensidades()}
                    {/* <tr><td>1</td><td>-17.5891</td><td>-74.2565</td><td>VII</td></tr>
                    <tr><td>2</td><td>-16.8891</td><td>-74.2565</td><td>VI</td></tr>
                    <tr><td>3</td><td>-18.0891</td><td>-74.2565</td><td>V</td></tr>
                    <tr><td>4</td><td>-19.9891</td><td>-74.2565</td><td>I</td></tr>
                    <tr><td>5</td><td>-18.891</td><td>-74.2565</td><td>II</td></tr>
                    <tr><td>1</td><td>-17.5891</td><td>-74.2565</td><td>VII</td></tr>
                    <tr><td>2</td><td>-16.8891</td><td>-74.2565</td><td>VI</td></tr>
                    <tr><td>3</td><td>-18.0891</td><td>-74.2565</td><td>V</td></tr>
                    <tr><td>4</td><td>-19.9891</td><td>-74.2565</td><td>I</td></tr>
                    <tr><td>5</td><td>-18.891</td><td>-74.2565</td><td>II</td></tr>
                    <tr><td>1</td><td>-17.5891</td><td>-74.2565</td><td>VII</td></tr>
                    <tr><td>2</td><td>-16.8891</td><td>-74.2565</td><td>VI</td></tr>
                    <tr><td>3</td><td>-18.0891</td><td>-74.2565</td><td>V</td></tr>
                    <tr><td>4</td><td>-19.9891</td><td>-74.2565</td><td>I</td></tr>
                    <tr><td>5</td><td>-18.891</td><td>-74.2565</td><td>II</td></tr> */}
                    </tbody>
                    </table>
                    </p>
                  </div>
                  <div className="Opciones">
                  <Pdf targetRef={ref} filename="catalogo_sismico.pdf">
                    {({ toPdf }) => <FaPrint onClick={toPdf}/>}
                  </Pdf>
                   {/* <FaCloudDownloadAlt/> */}
                  </div>
                  </Col>
                </Row>
              </Container>
              
              <Modal.Footer className="Footer_Modal">
                <Button className="Close_Modal" variant="danger" onClick={this.close_modal}>
                  Cerrar
                </Button>
              </Modal.Footer>
            </div>
            <div className="PDF_Intensidades">
              <div className="imprimir_pdf" ref={ref}>
                <div className="Header_PDF">
                <img src="./uni.png" className="logo_uni_PDF"/><strong> <h2 className="texto_T_PDF2">Catálogo Sísmico C.I.T.D.I<br/>Red Acelerográfica</h2> </strong><img src="./logo_Ceresis_Oficial.jpg" className="logo_ceresis_PDF2"/>
                {/* <img src="./redacel.png" className="logo_red_PDF"/> */}
                </div>
                <div className="Content_PDF">
                  <br/>
                  <p><strong className="sub_titulos">Fecha de Emisión:</strong>   {Date()}</p>
                  <p className="sub_titulos">Datos del Sismo</p>
                  <table className="tabla_datos">
                    <tr><th className="sub_titulos">Nombre: </th><td> {this.state.nombre_detalle}</td></tr>            
                    <tr><th className="sub_titulos">Fecha: </th><td> {this.state.fecha_detalle}</td></tr>
                  </table>
                  <br/>
                  <p  className="sub_titulos">Epicentros</p>
                  <table className="tabla_hipocentros">
                    <tr><th className="sub_titulos">Fuente</th><th className="sub_titulos">Latitud</th><th className="sub_titulos">Longitud</th><th className="sub_titulos">Prof. (km)</th><th className="sub_titulos">Magnitud</th></tr>
                    <tbody>
                      {this.Data_Hipocentros()}
                      {/* <tr><td><small>IGP</small></td><td><small>{this.state.latitud_detalle}</small></td><td><small>{this.state.longitud_detalle}</small></td><td><small>{this.state.profundidad_detalle}</small></td><td><small>{this.state.magnitud_detalle} {this.state.unidad_mag_detalle}</small></td><td></td></tr>
                      <tr><td><small>USGS</small></td><td><small>{this.state.latitud_detalle}</small></td><td><small>{this.state.longitud_detalle}</small></td><td><small>{this.state.profundidad_detalle}</small></td><td><small>{this.state.magnitud_detalle} {this.state.unidad_mag_detalle}</small></td><td></td></tr> */}
                    </tbody>
                  </table><br/>
                  <p  className="sub_titulos">Intensidades</p>               
                  <table className="tabla_intensidades">
                  <tr><th className="sub_titulos">Id</th><th className="sub_titulos">Latitud <br/>(°)</th><th className="sub_titulos">Longitud <br/>(°)</th><th className="sub_titulos">Intensidad <br/>(MM)</th><th className="sub_titulos">Id</th><th className="sub_titulos">Latitud <br/>(°)</th><th className="sub_titulos">Longitud <br/>(°)</th><th className="sub_titulos">Intensidad <br/>(MM)</th></tr>
                  <tbody className="body_table">
                    {this.Data_Intensidades_PDF()}
                    {/* <tr><td><small>1</small></td><td><small>-17.5891</small></td><td><small>-74.2565</small></td><td><small>VII</small></td><td><small>2</small></td><td><small>-16.8891</small></td><td><small>-74.2565</small></td><td><small>VI</small></td></tr>
                    <tr><td><small>3</small></td><td><small>-17.5891</small></td><td><small>-74.2565</small></td><td><small>VII</small></td><td><small>3</small></td><td><small>-16.8891</small></td><td><small>-74.2565</small></td><td><small>VI</small></td></tr>
                    <tr><td><small>5</small></td><td><small>-17.5891</small></td><td><small>-74.2565</small></td><td><small>VII</small></td><td><small>6</small></td><td><small>-16.8891</small></td><td><small>-74.2565</small></td><td><small>VI</small></td></tr>
                    <tr><td><small>7</small></td><td><small>-17.5891</small></td><td><small>-74.2565</small></td><td><small>VII</small></td><td><small>8</small></td><td><small>-16.8891</small></td><td><small>-74.2565</small></td><td><small>VI</small></td></tr>
                    <tr><td><small>1</small></td><td><small>-17.5891</small></td><td><small>-74.2565</small></td><td><small>VII</small></td><td><small>2</small></td><td><small>-16.8891</small></td><td><small>-74.2565</small></td><td><small>VI</small></td></tr>
                    <tr><td><small>3</small></td><td><small>-17.5891</small></td><td><small>-74.2565</small></td><td><small>VII</small></td><td><small>3</small></td><td><small>-16.8891</small></td><td><small>-74.2565</small></td><td><small>VI</small></td></tr>
                    <tr><td><small>5</small></td><td><small>-17.5891</small></td><td><small>-74.2565</small></td><td><small>VII</small></td><td><small>6</small></td><td><small>-16.8891</small></td><td><small>-74.2565</small></td><td><small>VI</small></td></tr>
                    <tr><td><small>7</small></td><td><small>-17.5891</small></td><td><small>-74.2565</small></td><td><small>VII</small></td><td><small>8</small></td><td><small>-16.8891</small></td><td><small>-74.2565</small></td><td><small>VI</small></td></tr>
                    <tr><td><small>1</small></td><td><small>-17.5891</small></td><td><small>-74.2565</small></td><td><small>VII</small></td><td><small>2</small></td><td><small>-16.8891</small></td><td><small>-74.2565</small></td><td><small>VI</small></td></tr>
                    <tr><td><small>3</small></td><td><small>-17.5891</small></td><td><small>-74.2565</small></td><td><small>VII</small></td><td><small>3</small></td><td><small>-16.8891</small></td><td><small>-74.2565</small></td><td><small>VI</small></td></tr>
                    <tr><td><small>5</small></td><td><small>-17.5891</small></td><td><small>-74.2565</small></td><td><small>VII</small></td><td><small>6</small></td><td><small>-16.8891</small></td><td><small>-74.2565</small></td><td><small>VI</small></td></tr>
                    <tr><td><small>7</small></td><td><small>-17.5891</small></td><td><small>-74.2565</small></td><td><small>VII</small></td><td><small>8</small></td><td><small>-16.8891</small></td><td><small>-74.2565</small></td><td><small>VI</small></td></tr>
                    <tr><td><small>1</small></td><td><small>-17.5891</small></td><td><small>-74.2565</small></td><td><small>VII</small></td><td><small>2</small></td><td><small>-16.8891</small></td><td><small>-74.2565</small></td><td><small>VI</small></td></tr>
                    <tr><td><small>3</small></td><td><small>-17.5891</small></td><td><small>-74.2565</small></td><td><small>VII</small></td><td><small>3</small></td><td><small>-16.8891</small></td><td><small>-74.2565</small></td><td><small>VI</small></td></tr>
                    <tr><td><small>5</small></td><td><small>-17.5891</small></td><td><small>-74.2565</small></td><td><small>VII</small></td><td><small>6</small></td><td><small>-16.8891</small></td><td><small>-74.2565</small></td><td><small>VI</small></td></tr>
                     */}
                  </tbody>
                  </table>
                  <br/>
                  <img src="./redacel.png" className="logo_red_PDF2"/>
                  <div className="informacion"><small className="span_info">Si desea más información, por favor, pongase en contacto con el Centro de Investigación en Transformación Digital en Ingeniería - Red Acelerográfica.</small></div>
                </div>
                
                <div className="Footer_PDF">
                  <small><strong>
                  <tr>Centro de Investigación en Transformación Digital en Ingeniería CITDI</tr>
                  <tr>http://red-acelerografica-peru.uni.edu.pe</tr>
                  <tr>Contacto: Central Telefónica (01) 4811070 Anexo:3909, Lima - Perú</tr>
                  <tr>Email: red-acelerografica@uni.edu.pe - ceresis.direccionejecutiva@gmail.com</tr>
                  <tr>Ubicación: Av. Túpac Amaru 210 - Rímac.</tr>
                  </strong></small>
                </div>
              </div>
            </div>
            {/* <Modal show={this.state.toggle_modal} onHide={this.close_modal} aria-labelledby="contained-modal-title-vcenter" dialogClassName="joseph" animation='false' size='xl' centered>
              <Modal.Header className="Header_Modal" closeButton variant="primary">
                <Modal.Title className="Title_Modal" variant="primary">Mapa de Intensidades:  {this.state.nombre_detalle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container className='contenedor_modal'>
                  <Row className="fila_modal">
                    <Col xs={12} md={8} className='mapa_intensidad'>
                    </Col>
                    <Col xs={6} md={4} className='tabla_intensidad'>
                      <div className="Hipocentrales">
                        <h5 className="Titulo_Modal"><strong>Hipocentros</strong></h5>
                        <p className="seccion_tabla">
                          <table className="tabla_hipocentros">
                            <tr><th>Fuente</th><th>Latitud</th><th>Longitud</th><th>Prof.</th><th>Magnitud</th></tr>
                            <tbody>
                              <tr><td>IGP</td><td>{this.state.latitud_detalle} </td><td>{this.state.longitud_detalle}</td><td>{this.state.profundidad_detalle}</td><td>{this.state.magnitud_detalle} {this.state.unidad_mag_detalle}</td><td></td></tr>
                              <tr><td>USGS</td><td>{this.state.latitud_detalle} </td><td>{this.state.longitud_detalle}</td><td>{this.state.profundidad_detalle}</td><td>{this.state.magnitud_detalle} {this.state.unidad_mag_detalle}</td><td></td></tr>
                            </tbody>
                          </table>
                          </p>
                      </div>
                      <div className="Intensidades">
                        <h5 className="Titulo_Modal"><strong>Intensidades</strong></h5>
                        <p className="seccion_tabla">
                        <table className="tabla_intensidades">
                        <tr><th>Id</th><th>Latitud</th><th>Longitud</th><th>Intensidad</th></tr>
                        <tbody>
                        <tr><td>1</td><td>-17.5891</td><td>-74.2565</td><td>VII</td></tr>
                        <tr><td>2</td><td>-16.8891</td><td>-74.2565</td><td>VI</td></tr>
                        <tr><td>3</td><td>-18.0891</td><td>-74.2565</td><td>V</td></tr>
                        <tr><td>4</td><td>-19.9891</td><td>-74.2565</td><td>I</td></tr>
                        <tr><td>5</td><td>-18.891</td><td>-74.2565</td><td>II</td></tr>
                        <tr><td>1</td><td>-17.5891</td><td>-74.2565</td><td>VII</td></tr>
                        <tr><td>2</td><td>-16.8891</td><td>-74.2565</td><td>VI</td></tr>
                        <tr><td>3</td><td>-18.0891</td><td>-74.2565</td><td>V</td></tr>
                        <tr><td>4</td><td>-19.9891</td><td>-74.2565</td><td>I</td></tr>
                        <tr><td>5</td><td>-18.891</td><td>-74.2565</td><td>II</td></tr>
                        <tr><td>1</td><td>-17.5891</td><td>-74.2565</td><td>VII</td></tr>
                        <tr><td>2</td><td>-16.8891</td><td>-74.2565</td><td>VI</td></tr>
                        <tr><td>3</td><td>-18.0891</td><td>-74.2565</td><td>V</td></tr>
                        <tr><td>4</td><td>-19.9891</td><td>-74.2565</td><td>I</td></tr>
                        <tr><td>5</td><td>-18.891</td><td>-74.2565</td><td>II</td></tr>
                        </tbody>
                        </table>
                        </p>
                      </div>
                      <div className="Opciones"><strong></strong> <FaPrint/> <FaCloudDownloadAlt/></div>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer className="Footer_Modal">
                <Button className="Close_Modal" variant="danger" onClick={this.close_modal}>
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal> */}
          </div>
        );
    }
}