import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import ciplimafinal from './cabecera4.png';

import './navegador.css';

function Cabecera() {
  return (
    <Navbar bg="#2d2d2d" expand="lg" className="navegador_final">
      <img src={ciplimafinal} className="logo_red"/>
      {/* <Container> */}
        <Navbar.Brand href="/">
          <strong className="white-text texto_nombre" title="Encuesta ">Encuesta de Vulnerabilidad Sísmica para Edificaciones de Albañilería</strong>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" right/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          <Nav.Link href="/" active className="items_f"><span className="nomb">Home</span></Nav.Link>
          <Nav.Link href="/Encuesta" active className="items_f"><span className="nomb">Encuesta</span></Nav.Link>
          <Nav.Link href="/Mapavulnerabilidad" active className="items"><span className="nomb">Mapa</span></Nav.Link>
          <NavDropdown bg="light" title="Nosotros" id="basic-nav-dropdown" active className="items_ff">
            <NavDropdown.Item href="/Instituciones">Instituciones</NavDropdown.Item>
            <NavDropdown.Item href="/Team">Equipo Trabajo</NavDropdown.Item>
            <NavDropdown.Divider />
          </NavDropdown>
          <Nav.Link href="/Documentacion" active className="items_f"><span className="nomb">Documentación</span></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      {/* </Container> */}
    </Navbar>
  );
}

export default Cabecera;