import React, {Component} from 'react';

import {XYZ} from 'ol/source';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import { fromLonLat } from 'ol/proj';
import {transform} from 'ol/proj';
import Zoom from 'ol/control/Zoom';
import ZoomToExtent from 'ol/control/ZoomToExtent';
import ZoomSlider from 'ol/control/ZoomSlider';
import FullScreen from 'ol/control/FullScreen';
import Rotate from 'ol/control/Rotate';
import ScaleLine from 'ol/control/ScaleLine';
import OSM from 'ol/source/OSM';
import Map from 'ol/Map';

import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {Fill, Stroke, Text, RegularShape, Icon} from 'ol/style';
import Style from 'ol/style/Style';
import Geolocation from 'ol/Geolocation';

// import icons
import { FaSearchLocation, FaLayerGroup, FaRegBookmark, FaList, FaRev, FaCloudDownloadAlt,FaRegEyeSlash, FaRegEye, FaGem, FaHeart, FaHome, FaExpand, FaHotTub, FaMarker, FaFulcrum, FaMap, FaMapMarkedAlt, FaGlobeAmericas, FaGlobe, FaHotjar, FaEyeDropper, FaEye, FaDownload, FaSave, FaPrint} from 'react-icons/fa';
import { BsGrid, BsFillLayersFill, BsLayersHalf, BsSquare, BsSquareHalf, BsGeoAlt, BsGeo } from "react-icons/bs";
import { FiFilter, FiLayers } from "react-icons/fi";
import { MdZoomOutMap, MdFilterList, MdGroup, MdCenterFocusStrong, MdRefresh } from "react-icons/md";
import {CgClose, CgExternal, CgHomeAlt} from 'react-icons/cg';
import { AiOutlineBranches} from "react-icons/ai";
import { BiStats} from "react-icons/bi";
import {GiUnionJack, GiPositionMarker, GiWorld} from "react-icons/gi";
//Geocode
import Geocode from "react-geocode";

import axios from 'axios';

import robot from "./robot_laptop.png"
import "./Encuesta.css";

class MapaEncuesta extends Component{
    constructor(props) {
      super(props);
      this.state = {};
      this.cambia_direccion = this.cambia_direccion.bind(this);
      this.busca_direccion = this.busca_direccion.bind(this);
      //Mapa vista Open Street Map
      this.OSMAP = new TileLayer({
        source: new OSM(),
        visible: false,
        className: "OSM_Map",
      });

      //Mapa vista Google Maps
      this.GMaps = new TileLayer({
        title: "Google Maps",
        visible: true,
        source: new XYZ({
            url: 'http://mt.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
        })
      });
      //View
      this.view = new View({
        center: fromLonLat([-76.048, -9.4913]),
        zoom: 6,
        minZoom: 2,
        maxZoom: 28,
    });
    //Click
    this.fillClick = new Fill({color: 'rgba(240,240,240,0.9)'});
    this.strokeClick = new Stroke({color: 'rgba(255,255,255,0.8)',width: 4});
    this.fillClicktwo = new Fill({color: 'rgba(0,240,240,0.9)'});
    this.strokeClicktwo = new Stroke({color: 'rgba(0,255,255,0.8)',width: 2});
    this.styleClick = new Style({
      image: new Icon({
        anchor: [0.5, 46],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        anchorOrigin: 'bottom-left',
        
        crossOrigin: 'anonymous',
        src: robot,//"./robot_laptop.png",//robot,//'https://assets.stickpng.com/thumbs/580b57fbd9996e24bc43be10.png',
        scale: .2,
        color: 'rgb(150,150,255,0.1)'
        
      }),
      stroke: this.strokePosition,
      fill: this.fillPosition,
      text: new Text({
        text: "Usted está aquí",
        font: '14px sans-serif',
        color: 'blue',
        offsetY: -90,
        backgroundFill:this.fillClick,
        backgroundStroke: this.strokeClick,
        fill: new Fill({
          color: 'rgba(0,0,0,0.9)'
        }),
        stroke: new Stroke({
            color: 'gray',
            lineCap: 'butt',
            width: 1
        }),
      })
    });
    // this.strokePositiontwo=
    // this.fillPosition=
    this.styleClicktwo = new Style({
      image: new RegularShape({
        fill: this.fillClicktwo,
        stroke: this.strokeClicktwo,
        points: 16,
        radius: 12,
        radius2: 6,
        angle: 0
        }),
      stroke: this.strokePosition,
      fill: this.fillPosition,
    });
    this.sourceClick = new VectorSource({
      url: props.url_referencia,
      format: new GeoJSON()
    });
    this.sourceClicktwo = new VectorSource({
      url: props.url_referencia,
      format: new GeoJSON()
    });
    this.vectorClick = new VectorLayer({
      name: 'Manual',
      source: this.sourceClick,
      style: this.styleClick,
      visible: true
    });
    this.vectorClicktwo = new VectorLayer({
      name: 'Manual',
      source: this.sourceClicktwo,
      style: this.styleClicktwo,
      visible: true
    });

    //Mapa
    this.map =  new Map({
      view: this.view,
      controls: [ 
        new Zoom({zoomInTipLabel:'Acercar Mapa', zoomOutTipLabel:'Alejar Mapa'}), 
        new ZoomSlider({duration:100,}), 
        new FullScreen({tipLabel:'Pantalla Completa'}), 
        new ScaleLine({minWidth:100}),
        //new OverviewMap({ layers:[this.OSM,this.WaterColor,this.bingmap,this.ByN,this.terreno],collapsible:true, tipLabel:"Sobre vista del mapa"}),
        new Rotate({autoHide:true, tipLabel:'Orientar Mapa'})],
      layers: [this.OSMAP, this.GMaps, this.vectorClick, this.vectorClicktwo],
    });

    this.actualiza_ubicacion = (evt) =>{
      console.log(evt)
        this.caracteristicas = this.map.getFeaturesAtPixel(evt.pixel);
        if (this.caracteristicas.length>=1) {
            //Listo para mostrar detalles de la zona
        }
        else{
            console.log("entre a la seccion de mover punto");
            this.click = this.map.getCoordinateFromPixel(evt.pixel);
            this.click_coordinates = transform(this.click,'EPSG:900913','EPSG:4326');
            this.sourceClick.setUrl('https://eval-vulnerabilidad.cdlima.org.pe/encuesta/mapaclic/latitude='+this.click_coordinates[1]+'&&longitude='+this.click_coordinates[0]);
            this.sourceClick.refresh();
            this.sourceClicktwo.setUrl('https://eval-vulnerabilidad.cdlima.org.pe/encuesta/mapaclic/latitude='+this.click_coordinates[1]+'&&longitude='+this.click_coordinates[0]);
            this.sourceClicktwo.refresh();
            //props.updatecoord(this.click_coordinates[1], this.click_coordinates[0]);
            this.actua_coord(this.click_coordinates[1], this.click_coordinates[0]);
        }
    };

    this.map.on("singleclick", this.actualiza_ubicacion);
    //GeoPosicionamiento
    this.strokePosition = new Stroke({color: 'rgba(255,0,0,0.9)', width: 5});
    this.fillPosition = new Fill({color: 'rgba(0,255,0,0.9)', width: 5});
    this.geoposition = new Geolocation({
      trackingOptions: { enableHighAccuracy: true },
      projection: this.view.getProjection()
    });

    }

    async check_peru(latitud, longitud){
      await axios.get('https://eval-vulnerabilidad.cdlima.org.pe/encuesta/mapaclic/latitude='+latitud+'&&longitude='+longitud)
      .then(response =>{
        if (response.data['into']==true) {
          this.props.updatecoord(latitud, longitud);
        }else{
          this.props.updatecoord('', '');
          alert("Usted está tratando de medir la vulnerabilidad en una zona fuera del territorio peruano o una zona no cubierta, este sistema es aplicable al territorio peruano, por favor, cambie de ubicación. ...!!")
        }
        console.log(response.data);
      })
      .catch(e => {})
    }

    actua_coord = (latitud, longitud) => {
        this.check_peru(latitud, longitud)
    }

    //GeoPosicionamiento
    GeoPositionInMap=()=>{
      this.geoposition.setTracking('true');
      this.geoposition.setProjection();
      // this.geoposition.getPosition();
      this.geoposition.on('change',this.ObtenUbicacion);
    };
    ObtenUbicacion=()=>{
      console.log("Aparezco cuando acepta el tracking");
      //console.log(this.geoposition.getPosition());
      this.coordinates= transform(this.geoposition.getPosition(),'EPSG:3857','EPSG:4326');
      this.geoposition.setTracking(false);
      console.log(this.coordinates[0]);
      console.log(this.coordinates[1]);
      this.sourceClick.setUrl('https://eval-vulnerabilidad.cdlima.org.pe/encuesta/mapaclic/latitude='+this.coordinates[1]+'&&longitude='+this.coordinates[0]);
      this.sourceClick.refresh();
      this.sourceClicktwo.setUrl('https://eval-vulnerabilidad.cdlima.org.pe/encuesta/mapaclic/latitude='+this.coordinates[1]+'&&longitude='+this.coordinates[0]);
      this.sourceClicktwo.refresh();
      this.actua_coord(this.coordinates[1], this.coordinates[0]);

      this.map.setView(
        new View({
          center: fromLonLat(this.coordinates),
          zoom: 15
        })
      );
    };
    //Buscador de Direcciones
    cambia_direccion(event) {
        this.setState({value: event.target.value});
        console.log(this.state.value);
    }
    busca_direccion(event) {
        this.convert_direccion(this.state.value);
        event.preventDefault();
    }
    convert_direccion(direccion){
        Geocode.setApiKey("AIzaSyBEN1zVUqxX4a32voiBUrmEl_8BeOIt4_I");
        Geocode.setLanguage("latam");
        Geocode.setRegion("pe");
        Geocode.enableDebug();
        Geocode.fromAddress(direccion).then(
          response => {
            const { lat, lng } = response.results[0].geometry.location;
            console.log(lat, lng);
            this.center_marker(lat,lng,17);
            this.sourceClick.setUrl('https://eval-vulnerabilidad.cdlima.org.pe/encuesta/mapaclic/latitude='+lat+'&&longitude='+lng);
            this.sourceClick.refresh();
            this.sourceClicktwo.setUrl('https://eval-vulnerabilidad.cdlima.org.pe/encuesta/mapaclic/latitude='+lat+'&&longitude='+lng);
            this.sourceClicktwo.refresh();
            this.actua_coord(lat, lng)
          },
          error => {
            console.error(error);
          }
        );
    }
    center_marker(latitud, longitud, zoom_acercar) {
        this.map.setView(
          new View({
            center: fromLonLat([longitud,latitud]),
            zoom: zoom_acercar
          })
        );
    }
      
    componentDidMount ()  {
      this.check_peru();
      this.map.setTarget(this.refs.mapaEncuesta);
      this.GeoPositionInMap();
    }
    


    render() {
        return (
            <div className="seccionmapaencuesta">
                <div className="mapaEncuesta" id="mapaEncuesta" ref="mapaEncuesta"/>
                <button className="geoposition_encuesta" onClick={this.GeoPositionInMap} title="Geoposicionamiento"><BsGeo/></button>
                <form onSubmit={this.busca_direccion}>
                <label className="label-busca-direccion-encuesta" title="Escribe tu dirección para encontrarte">
                    <input  className="input-busca-direccion" type="text" value={this.state.value} onChange={this.cambia_direccion} placeholder="Ingresa direción a buscar" title="Escribe tu dirección para encontrarte"/>
                    <FaSearchLocation className="icon-busca-direccion" onClick={this.busca_direccion} title="Escribe tu dirección para encontrarte"/>
                </label>
                </form>
            </div>
        );
    }
}
export default MapaEncuesta;