import React, { Component } from 'react';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { Button } from 'react-bootstrap';

class ModalPage extends Component {
    constructor(props){
    super(props);
    this.state = {
        modal14: props.estado
      }
    };


toggle = nr => () => {
  let modalNumber = 'modal' + nr
  this.setState({
    [modalNumber]: !this.state[modalNumber]
  });
}

render() {
  return (
      <MDBContainer>
        <button color="danger" type="button" className="btn button boton_encima" onClick={this.toggle(14)}>+</button>
        <MDBModal isOpen={this.state.modal14} toggle={this.toggle(14)} centered>
            <MDBModalHeader className="tittle_modal" toggle={this.toggle(14)}>
               <div md="12" className="text_title_modal">{this.props.titulo}</div>
            </MDBModalHeader>
          <MDBModalBody>
          <img src={this.props.src_modal} class="images_modal" alt="Responsive image"/>
          <Descriptionbetter texto={this.props.texto.split("<br/>")} />
          {/*<p className="content_modal">{this.props.texto.split("<br/>")[0].split("*")[0]} <strong>{this.props.texto.split("<br/>")[0].split("*")[1]}</strong> {this.props.texto.split("<br/>")[0].split("*")[2]}</p>
          <p className="content_modal"><strong>{this.props.texto.split("**")[1]}</strong>{this.props.texto.split("**")[2]}</p>*/}
          </MDBModalBody>
          <MDBModalFooter>
            <div className="footer_modal">
                <Button className="button_modal danger" variant="danger" onClick={this.toggle(14)}>CERRAR</Button>
            </div>
            {/* <MDBBtn color="primary">Save changes</MDBBtn> */}
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default ModalPage;

function Descriptionbetter(props){
  return(<p>
    {props.texto.map((x) => <span>
        {x.split("*")[0]} <strong className="style_font_additional">{x.split("*")[1]}</strong> {x.split("*")[2]} <strong className="style_font_additional">{x.split("*")[3]}</strong> {x.split("*")[4]} <strong className="style_font_additional">{x.split("*")[5]}</strong> {x.split("*")[6]} <strong className="style_font_additional">{x.split("*")[7]}</strong> {x.split("*")[8]} <strong className="style_font_additional">{x.split("*")[9]}</strong> {x.split("*")[10]} <strong className="style_font_additional">{x.split("*")[11]}</strong> {x.split("*")[12]}
        <br/>
      </span>
      )}
    </p>
  )
}