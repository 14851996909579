import React, {Component} from 'react';

import MapaEncuesta from './mapaencuesta';
import MapaResultado from './maparesultado';
import ModalPage from './Modal';
import ModalPageIntro from './Modalintro';
import roca from './roca.png';

import { MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink, MDBIcon, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBBtn, MDBView, MDBCardImage, MDBCard, MDBAnimation, MDBRow, MDBMask } from "mdbreact";


import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';

import "./Encuesta.css";
import ReCAPTCHA from "react-google-recaptcha";
import { FaSearchLocation, FaLayerGroup, FaRegBookmark, FaList, FaRev, FaCloudDownloadAlt,FaRegEyeSlash, FaRegEye, FaGem, FaHeart, FaHome, FaExpand, FaHotTub, FaMarker, FaFulcrum, FaMap, FaMapMarkedAlt, FaGlobeAmericas, FaGlobe, FaHotjar, FaEyeDropper, FaEye, FaDownload, FaSave, FaPrint} from 'react-icons/fa';

import axios from 'axios';

//Generador de PDF
import Pdf from 'react-to-pdf';
const ref = React.createRef();



export class Encuesta extends Component{
    constructor(props) {
      super(props);
      this.state = {
        currentStep: 1,
        siguiente: true,

        email:  '',
        username: '',
        password: '', 
        steps:['Ubicación de la Vivienda', 
        'Datos de la Edificación', 
        'Tipo de Suelo', 
        'Pendiente del Terreno', 
        'Construcción de la Edificación', 
        'Tipo de ladrillos', 
        'Techo o cobertura', 
        'Muros y Fachada', 
        'Estado de Conservación', 
        'Distribución Muros Confinados',
        'Diafragmas de la Edificación', 
        'Configuración en Planta', 
        'Configuración en Elevación o Altura'],

        ruta_django: "https://eval-vulnerabilidad.cdlima.org.pe",
        skipped: '',
        latitud: '',
        longitud: '',
        inicio_construccion: '',
        fin_construccion: '',
        area_edificacion: '',
        pisos_construidos: '',
        pisos_proyectados: '',
        altura_pisos: '',
        uso_edificacion: 'Vivienda',
        uso_edificacion_otro:'',
        estado_edificacion_otro: "form-control_escondido",
        tipo_suelo: '',
        type_earth: [ ],
        pendiente_terreno: '',
        type_pendient: [ ],

        contruccion_edificacion: '',
        type_construccion: [],

        ladrillos_muros: '',
        type_ladrillos: [ ],

        techo_cobertura: '',
        type_cobertura: [ ],

        muros_fachada: [],
        type_muros_fachada: [ ],

        conservacion_edificacion: [],
        type_conservacion: [ ],

        muros_confinados: '',
        matriz_m_conf_X:  [['','',"Si"],['','',"Si"] ],
        cantidad_ejes: 2,
        lista_eje_X: [1, 2],
        matriz_m_conf_Y:  [['','',"Si"],['','',"Si"] ],
        cantidad_ejes_Y: 2,
        lista_eje_Y: [1, 2],
        first_select_ejes: true,
        
        cantidad_x1: '', 
        longitud_x1: '', 
        espesor_x1: '',
        pared_x1: 'Si',
        cantidad_x2: '', 
        longitud_x2: '', 
        espesor_x2: '',
        pared_x2: 'Si',
        cantidad_x3: '', 
        longitud_x3: '', 
        espesor_x3: '',
        pared_x3: 'Si',

        cantidad_y1: '', 
        longitud_y1: '', 
        espesor_y1: '',
        pared_y1: 'Si',
        cantidad_y2: '', 
        longitud_y2: '', 
        espesor_y2: '',
        pared_y2: 'Si',
        cantidad_y3: '', 
        longitud_y3: '', 
        espesor_y3: '',
        pared_y3: 'Si',
        espesor_juntas:1,

        tipo_diafragmas: '',
        type_diafragmas: [ ],

        tipo_configuracion_edificacion: '',
        nombre_configuracion_edificacion: '',
        
        type_configuracion_edificacion: [],
        AA: '',
        BB: '',
        aa: '',
        bb: '',
        a1: '',
        b1: '',
        aa_real: '',
        bb_real: '',
        state_AA: true,
        state_BB: true,
        state_aa: true,
        state_bb: true,
        state_a1: true,
        state_b1: true, 

        tipo_configuracion_edificacion_altura: [],
        type_configuracion_edificacion_altura: [ ],

        resultado_encuesta:{},
        // asesoramiento_tecnico: '',
        // sistema_estructural: '',
        // ano_construccion: new Date().getFullYear(),
        // numero_pisos: 1,
        // irregularidad_horizontal: '',
        // ih_regular:'',
        // ih_esquinas_entrantes:'',
        // ih_proporciones_inadecuadas:'',
        // ih_discontinuidad_piso:'',
        // irregularidad_planta: '',
        // ia_regular: '',
        // ia_geometria_vertical: '',
        // ia_piso_blando: '',
        // ia_masa_peso: '',
        // cantidad_muros:'',
        // estado_conservacion: '',
        // separacion_edificaciones: 'Desconocido',
        // elementos_no_estructurales: '',
        // presencia_t_inclinado: '',

        ano_actual: new Date().getFullYear(),
        url_clic:'https://eval-vulnerabilidad.cdlima.org.pe/encuesta/mapaclic/latitude=&&longitude=',
        

        ih_pregone:false,
        ih_pregtwo:false,
        ih_pregthr:false,
        ih_pregfou:false,

        ia_pregone:false,
        ia_pregtwo:false,
        ia_pregthr:false,
        ia_pregfou:false,
        
        cm_pregone:false,
        cm_pregtwo:false,

        ec_pregone:false,
        ec_pregtwo:false,
        ec_pregthr:false,
        
        ene_pregone:false,
        ene_pregtwo:false,
        
        ti_pregone:false,
        ti_pregtwo:false,
      };

      

    }

    handleChange = event => {
      const {name, value} = event.target
      this.setState({
        [name]: value
      })    
      
    }
    
    handleChangetexts = event => {
      const {name, value} = event.target
      this.setState({
        [name]: value
      })


      //Matriz de Muros
      if (name == "cantidad_ejes"){
        console.log('Haras un cambio en la cantidad de ejes X')
        if ( Number(value) <= Number(this.state.matriz_m_conf_X.length)){
          console.log('Estas disminuyendo la cantidad de ejes X')
          const vector_x = []
          const matrix_x = []
          for (let index = 0; index < value; index++) {
            let longitud = 'longitud_x' + String(index+1)
            let espesor = 'espesor_x' + String(index+1)
            let pared = 'pared_x' + String(index+1)
            vector_x.push(index+1)
            this.setState({
              [longitud]: this.state.matriz_m_conf_X[index][0],
              [espesor]: this.state.matriz_m_conf_X[index][1],
              [pared]: this.state.matriz_m_conf_X[index][2], 
            })
            matrix_x.push([this.state.matriz_m_conf_X[index][0],this.state.matriz_m_conf_X[index][1],this.state.matriz_m_conf_X[index][2]])
          }
          this.setState({lista_eje_X:vector_x, matriz_m_conf_X:matrix_x})
        } else {
          console.log('Estas aumentando la cantidad de ejes X')
          const vector_x = []
          const matrix_x = []
          for (let index = 0; index < this.state.matriz_m_conf_X.length; index++) {
            let longitud = 'longitud_x' + String(index+1)
            let espesor = 'espesor_x' + String(index+1)
            let pared = 'pared_x' + String(index+1)
            vector_x.push(index+1)
            this.setState({
              [longitud]: this.state.matriz_m_conf_X[index][0],
              [espesor]: this.state.matriz_m_conf_X[index][1],
              [pared]: this.state.matriz_m_conf_X[index][2], 
            })
            matrix_x.push([this.state.matriz_m_conf_X[index][0],this.state.matriz_m_conf_X[index][1],this.state.matriz_m_conf_X[index][2]])
          }
          for (let index = this.state.matriz_m_conf_X.length; index < value; index++) {
            let longitud = 'longitud_x' + String(index+1)
            let espesor = 'espesor_x' + String(index+1)
            let pared = 'pared_x' + String(index+1)
            vector_x.push(index+1)
            this.setState({
              [longitud]: '',
              [espesor]: '',
              [pared]: 'Si', 
            })
            matrix_x.push(['','',"Si"])
          }
          this.setState({lista_eje_X:vector_x, matriz_m_conf_X:matrix_x})
        }           

      }


      // FUNCION PARA ACTUALIZAR LOS VALORES (EJE X) MIENTRAS LOS VAN ESCRIBIENDO
      let splitX = name.split("_x")
      if (splitX.length == 2) {
        console.log('cual es mi funcion en X al hacer split')
        if( splitX[0]=="longitud"){
          const temp_mat_sect_x = this.state.matriz_m_conf_X
          temp_mat_sect_x[Number(splitX[1])-1][0] = value
          this.setState({matriz_m_conf_X:temp_mat_sect_x})
        }
        if( splitX[0]=="espesor"){
          const temp_mat_sect_x = this.state.matriz_m_conf_X
          temp_mat_sect_x[Number(splitX[1])-1][1] = value
          this.setState({matriz_m_conf_X:temp_mat_sect_x})
        }
        if( splitX[0]=="pared"){
          const temp_mat_sect_x = this.state.matriz_m_conf_X
          temp_mat_sect_x[Number(splitX[1])-1][2] = value
          this.setState({matriz_m_conf_X:temp_mat_sect_x})
        }
      }

      
      //Matriz de Muros Eje Y
      if (name == "cantidad_ejes_Y"){
        console.log('Haras un cambio en la cantidad de ejes Y')
        if ( Number(value) <= Number(this.state.matriz_m_conf_Y.length)){
          console.log('Estas disminuyendo la cantidad de ejes Y')
          const vector_y = []
          const matrix_y = []
          for (let index = 0; index < value; index++) {
            let longitud = 'longitud_y' + String(index+1)
            let espesor = 'espesor_y' + String(index+1)
            let pared = 'pared_y' + String(index+1)
            vector_y.push(index+1)
            this.setState({
              [longitud]: this.state.matriz_m_conf_Y[index][0],
              [espesor]: this.state.matriz_m_conf_Y[index][1],
              [pared]: this.state.matriz_m_conf_Y[index][2], 
            })
            matrix_y.push([this.state.matriz_m_conf_Y[index][0],this.state.matriz_m_conf_Y[index][1],this.state.matriz_m_conf_Y[index][2]])
          }
          this.setState({lista_eje_Y:vector_y, matriz_m_conf_Y:matrix_y})
        } else {
          console.log('Estas aumentando la cantidad de ejes Y')
          const vector_y = []
          const matrix_y = []
          for (let index = 0; index < this.state.matriz_m_conf_Y.length; index++) {
            let longitud = 'longitud_y' + String(index+1)
            let espesor = 'espesor_y' + String(index+1)
            let pared = 'pared_y' + String(index+1)
            vector_y.push(index+1)
            this.setState({
              [longitud]: this.state.matriz_m_conf_Y[index][0],
              [espesor]: this.state.matriz_m_conf_Y[index][1],
              [pared]: this.state.matriz_m_conf_Y[index][2], 
            })
            matrix_y.push([this.state.matriz_m_conf_Y[index][0],this.state.matriz_m_conf_Y[index][1],this.state.matriz_m_conf_Y[index][2]])
          }
          for (let index = this.state.matriz_m_conf_Y.length; index < value; index++) {
            let longitud = 'longitud_y' + String(index+1)
            let espesor = 'espesor_y' + String(index+1)
            let pared = 'pared_y' + String(index+1)
            vector_y.push(index+1)
            this.setState({
              [longitud]: '',
              [espesor]: '',
              [pared]: 'Si', 
            })
            matrix_y.push(['','',"Si"])
          }
          this.setState({lista_eje_Y:vector_y, matriz_m_conf_Y:matrix_y})
        }   
      }

      let splitY = name.split("_y")
      if (splitY.length == 2) {

        if( splitY[0]=="longitud"){
          const temp_mat_sect_y = this.state.matriz_m_conf_Y
          temp_mat_sect_y[Number(splitY[1])-1][0] = value
          this.setState({matriz_m_conf_Y:temp_mat_sect_y})
        }
        if( splitY[0]=="espesor"){
          const temp_mat_sect_y = this.state.matriz_m_conf_Y
          temp_mat_sect_y[Number(splitY[1])-1][1] = value
          this.setState({matriz_m_conf_Y:temp_mat_sect_y})
        }
        if( splitY[0]=="pared"){
          const temp_mat_sect_y = this.state.matriz_m_conf_Y
          temp_mat_sect_y[Number(splitY[1])-1][2] = value
          this.setState({matriz_m_conf_Y:temp_mat_sect_y})
        }
      }


      if (name!="altura_pisos") {
        if (this.state.altura_pisos=='') {
          console.log("Esperando data de altura de pisos")
        }else{
          if (this.state.altura_pisos<2.1 || this.state.altura_pisos>5) {
            this.setState({
              altura_pisos: ''
            }) 
            alert("Estas ingresando un valor fuera del rango del calculo de altura de pisos.")            
          }
        }
      }
      if (name=="area_edificacion") {
        if (value=='') {
          this.setState({
            [name]: ''
          }) 
          alert("Estas ingresando un valor equivocado en el campo Area de la Edificación, debe corregir")           
        }
        if (value<0) {
          this.setState({
            [name]: ''
          }) 
          alert("Estas ingresando un valor equivocado en el campo Area de la Edificación, debe corregir")
        }
      }
      if (name == "uso_edificacion") {
        if (value == "Otros") {
          this.setState({
            estado_edificacion_otro: "form-control"
          })
        } else {
          this.setState({
            estado_edificacion_otro: "form-control_escondido"
          })
          
        }
      }
      console.log(name, " === >>> ",value)
    }
    
    
    handleChangetextsplanta = event => {
      const {name, value} = event.target
      this.setState({
        [name]: value
      })
      console.log(name, " === >>> ",value)
    }


    handleSubmit = event => {
      event.preventDefault()
      const { email, username, password } = this.state
      alert(`Your registration detail: \n 
             Email: ${email} \n 
             Username: ${username} \n
             Password: ${password}`)
    }
    
    _next = () => {
      let currentStep = this.state.currentStep
      if (currentStep==2){
        let inicio_construccion = parseInt(this.state.inicio_construccion)
        let fin_construccion = parseInt(this.state.fin_construccion)
        let area_edificacion = parseFloat(this.state.area_edificacion)
        console.log(inicio_construccion)
        
        if (this.state.altura_pisos<2.1 ||this.state.altura_pisos>5) {
          this.setState({
            altura_pisos: ''
          }) 
          alert("Estas ingresando un valor fuera del rango del calculo de altura de pisos.")            
        }
        else if (Number(this.state.pisos_proyectados) > 10 || Number(this.state.pisos_proyectados) < 0 ) {
          this.setState({
            pisos_proyectados: ''
          }) 
          alert("El número de pisos proyectados debe estar en el rango de 1 a 10.")
        }
        else if (Number(this.state.pisos_construidos) > 10 || Number(this.state.pisos_construidos) < 0 ) {
          this.setState({
            pisos_construidos: ''
          }) 
          alert("El número de pisos construidos debe estar en el rango de 1 a 10.")
        }
        else if (inicio_construccion > 2023 || inicio_construccion < 1920) {
          console.log(inicio_construccion)
          this.setState({inicio_construccion: ''})
          console.log(inicio_construccion)
          alert("El año de inicio de construcción no debe ser menor a 1920 ni mayor a 2023")
        }
        else if (fin_construccion > 2023 || fin_construccion < 1920) {
          this.setState({fin_construccion: ''})
          alert("El año de fin de construcción no debe ser menor a 1920 ni mayor a 2023")
        }
        else if (area_edificacion <= 0.0) {
          this.setState({area_edificacion: ''})
          alert("El área del primer piso de la edificación no debe ser menor a 0")
        }
        else{
          currentStep = currentStep >= 14? 15: currentStep + 1
          this.setState({
            currentStep: currentStep
          })
        }
      }
      else if (currentStep==10){
        let matriz_m_conf_X = this.state.matriz_m_conf_X
        let n_1_2X = 0
        let n_negsX = 0
        let matriz_m_conf_Y = this.state.matriz_m_conf_Y
        let n_1_2Y = 0
        let n_negsY = 0
        for (var i = 0; i < matriz_m_conf_X.length; i++) {
          let ejexi = matriz_m_conf_X[i]
          if (Number(ejexi[0])<1.2){
            matriz_m_conf_X[i][0] = ''
            console.log(matriz_m_conf_X[i])
            n_1_2X = n_1_2X + 1
          }
          if (Number(ejexi[1]) < 0.1 || Number(ejexi[1]) > 0.5){
            matriz_m_conf_X[i][1] = ''
            console.log(matriz_m_conf_X[i])
            n_negsX = n_negsX + 1
          }
        }
        for (var i = 0; i < matriz_m_conf_Y.length; i++) {
          let ejeyi = matriz_m_conf_Y[i]
          if (Number(ejeyi[0])<1.2){
            matriz_m_conf_Y[i][0] = ''
            console.log(matriz_m_conf_Y[i])
            n_1_2Y = n_1_2Y + 1
          }
          if (Number(ejeyi[1]) < 0.1 || Number(ejeyi[1]) > 0.5){
            matriz_m_conf_Y[i][1] = ''
            console.log(matriz_m_conf_Y[i])
            n_negsY = n_negsY + 1
          }
        }
        if (n_negsX > 0 || n_negsY > 0 || n_1_2X > 0 || n_1_2Y > 0){
          console.log('tengo valores que no cumplen')
          n_negsX = 0
          n_negsY = 0
          n_1_2X = 0
          n_1_2Y = 0 
          this.setState({matriz_m_conf_X:matriz_m_conf_X})
          this.setState({matriz_m_conf_Y:matriz_m_conf_Y})
          alert("Verificar que sus longitudes sean mayores a 1.2m y que sus espesores se encuentren en el rango de 0.1m a 0.5m")
        }
        else if (n_negsX == 0 && n_negsY == 0 && n_1_2X == 0 && n_1_2Y == 0){
          currentStep = currentStep >= 14? 15: currentStep + 1
          this.setState({
            currentStep: currentStep
          })
        }
      }
      else if (currentStep == 12){
          const arr = this.state.nombre_configuracion_edificacion
          console.log('Entre al evento')
          if (arr == "Tipo C" || arr == "Tipo L" || arr == "Tipo T" || arr == "Tipo +") {
            console.log('Soy de los 4 tipos')
            if (Number( this.state.aa) > 0.3*Number(this.state.AA)){
              console.log(typeof this.state.aa)
              this.setState({aa_real:`${0.3*Number(this.state.AA)}`})
            } else {
              this.setState({aa_real:this.state.aa})
            }
            if (Number( this.state.bb) > 0.3*Number(this.state.BB)){
              console.log(typeof this.state.bb)
              this.setState({bb_real:`${0.3*Number(this.state.BB)}`})
            } else {
              this.setState({bb_real:this.state.bb})
            }
            
            if (Number( this.state.AA) < Number(this.state.BB)) {
              this.setState({BB:''})
              alert("El valor de A no puede ser menor al valor de B")
            }
            // else if (Number( this.state.aa) > 0.3*Number(this.state.AA)) {
            //   console.log(typeof this.state.aa)
            //   this.setState({aa:''})
            //   alert("El valor de 'a' no puede ser mayor al valor de "+0.3*Number(this.state.AA))
            // }
            // else if (Number( this.state.bb) >  0.3*Number(this.state.BB)) {
            //   console.log(typeof this.state.bb)
            //   this.setState({bb:''})
            //   alert("El valor de 'b' no puede ser mayor al valor de "+0.3*Number(this.state.BB))
            // }
            else if (Number( this.state.AA)<0){
              this.setState({AA:''})
              alert("El valor de A no puede ser menor a 0")
            }
            else if (Number( this.state.BB)<0){
              this.setState({BB:''})
              alert("El valor de B no puede ser menor a 0")
            }
            else if (Number( this.state.aa)<0){
              this.setState({aa:''})
              alert("El valor de 'a' no puede ser menor a 0")
            }
            else if (Number( this.state.bb)<0){
              this.setState({bb:''})
              alert("El valor de 'b' no puede ser menor a 0")
            }
            else if (Number( this.state.a1)<0){
              this.setState({a1:''})
              alert("El valor de 'a1' no puede ser menor a 0")
            }
            else if (Number( this.state.b1)<0){
              this.setState({b1:''})
              alert("El valor de 'b1' no puede ser menor a 0")
            }
            else{
              console.log('voy a pasar al siguiente pasooo')
              console.log(this.state.aa)
              console.log(this.state.bb)
              currentStep = currentStep >= 14? 15: currentStep + 1
              this.setState({
                currentStep: currentStep
              })
            }
          }
          else if (arr == "Tipo Rectángulo"){
            currentStep = currentStep >= 14? 15: currentStep + 1
            this.setState({
              currentStep: currentStep
            })
          }
        }
      else{
        currentStep = currentStep >= 14? 15: currentStep + 1
          this.setState({
            currentStep: currentStep
          })
        }
      }
    
    
    habilita_step=()=>{
      let currentStep = this.state.currentStep
      if (currentStep==1 && this.state.latitud=='') {
        this.setState({siguiente:true})
      }
      else{
        this.setState({siguiente:false})
      }
    }

    _prev = () => {
      let currentStep = this.state.currentStep
      currentStep = currentStep <= 1? 1: currentStep - 1
      this.setState({
        currentStep: currentStep
      })
    }
    //Pregunta Ubicacion de la vivienda
    update_latlon=(lat, lon)=>{
      this.setState({
        latitud: lat,
        longitud: lon,
        url_clic:'https://eval-vulnerabilidad.cdlima.org.pe/encuesta/mapaclic/latitude='+String(lat)+'&&longitude='+String(lon),
      })
      //this.habilita_step()
    }
    //Pregunta sobre Tipo de Suelo    

    //{'id': 1, 'nombre': "Roca", 'state':false, 'img':"https://vulnerabilidad-sismica.uni.edu.pe/encuesta/tipo_suelo/roca.png", 'descripcion': "ashfhfds"},
    // Function section Kind of earth
    ver_data = (event) => {
      console.log("reconozco el evento: ", event.target.value)
      const {name, value} = event.target;
      const temporal = this.state.type_earth
      console.log(temporal)
      for (let index = 0; index < temporal.length; index++) {
        const element = temporal[index]['id'];
        console.log(element)
        if (element == value) {
          temporal[index]['stage']=true
        } else {
          temporal[index]['stage']=false
        }
      }
      console.log(temporal)
      this.setState({
        [name]: value,
        type_earth: temporal
      });
    }

    //Function pendient of earth
    ver_pendiente_terreno = (event) => {
      console.log("reconozco el evento: ", event.target.value)
      const {name, value} = event.target;
      const temporal = this.state.type_pendient
      console.log(temporal)
      for (let index = 0; index < temporal.length; index++) {
        const element = temporal[index]['id'];
        console.log(element)
        if (element == value) {
          temporal[index]['stage']=true
        } else {
          temporal[index]['stage']=false
        }
      }
      console.log(temporal)
      this.setState({
        [name]: value,
        type_pendient: temporal
      });
    }
    //Function to see contruction
    ver_construccion_edificacion = (event) => {
      console.log("reconozco el evento: ", event.target.value)
      const {name, value} = event.target;
      const temporal = this.state.type_construccion
      console.log(temporal)
      for (let index = 0; index < temporal.length; index++) {
        const element = temporal[index]['id'];
        console.log(element)
        if (element == value) {
          temporal[index]['stage']=true
        } else {
          temporal[index]['stage']=false
        }
      }
      console.log(temporal)
      this.setState({
        [name]: value,
        type_construccion: temporal
      });
    }
    //Function to see kind of lader user for the cunstruction
    ver_tipo_ladrillos = (event) => {
      console.log("reconozco el evento: ", event.target.value)
      const {name, value} = event.target;
      const temporal = this.state.type_ladrillos
      console.log(temporal)
      for (let index = 0; index < temporal.length; index++) {
        const element = temporal[index]['id'];
        console.log(element)
        if (element == value) {
          temporal[index]['stage']=true
        } else {
          temporal[index]['stage']=false
        }
      }
      console.log(temporal)
      this.setState({
        [name]: value,
        type_ladrillos: temporal
      });
    }
    
    //Function coberture type
    ver_tipo_techo_cobertura = (event) => {
      console.log("reconozco el evento: ", event.target.value)
      const {name, value} = event.target;
      const temporal = this.state.type_cobertura
      console.log(temporal)
      for (let index = 0; index < temporal.length; index++) {
        const element = temporal[index]['id'];
        console.log(element)
        if (element == value) {
          temporal[index]['stage']=true
        } else {
          temporal[index]['stage']=false
        }
      }
      console.log(temporal)
      this.setState({
        [name]: value,
        type_cobertura: temporal
      });
    }

    //Function wast type
    ver_tipo_muros_fachada = (event) => {
      console.log("reconozco el evento de tipo de muros: ", event.target.value)
      const {name, value} = event.target;
      const temporal = this.state.type_muros_fachada
      let muros_fachadas = this.state.muros_fachada
      console.log(temporal)
      for (let index = 0; index < temporal.length; index++) {
        const element = temporal[index]['id'];
        console.log(element)
        if (element == value) {
          if (temporal[index]['stage'] == true ) {
            temporal[index]['stage'] = false
            const mytemp = []
            for (let index1 = 0; index1 < muros_fachadas.length; index1++) {
              if(muros_fachadas[index1] == value){
                console.log("entre a valor puesto: ")
              }else{
                mytemp.push(muros_fachadas[index1])
              }
            }
            console.log(mytemp)
            muros_fachadas = mytemp
          } else {
            temporal[index]['stage'] = true
            muros_fachadas.push(value)
          }
        } 
      }
      console.log("Mi temporal: ", temporal)
      console.log("Mstriz muros: ", muros_fachadas)
      this.setState({
        muros_fachada: muros_fachadas,
        type_muros_fachada: temporal
      });
    }

    //Function building conservation
    ver_conservacion_construccion = (event) => {
      console.log("reconozco el evento estado contrucción: ", event.target.value)
      const {name, value} = event.target;
      const temporal = this.state.type_conservacion
      let conservacion_edificaciones = this.state.conservacion_edificacion
      console.log(temporal)
      for (let index = 0; index < temporal.length; index++) {
        const element = temporal[index]['id'];
        console.log(element)
        if (element == value) {
          if (temporal[index]['stage'] == true ) {
            temporal[index]['stage'] = false
            const mytemp = []
            for (let index1 = 0; index1 < conservacion_edificaciones.length; index1++) {
              if(conservacion_edificaciones[index1] == value){
                console.log("entre a valor puesto: ")
              }else{
                mytemp.push(conservacion_edificaciones[index1])
              }
            }
            console.log(mytemp)
            conservacion_edificaciones = mytemp
          }else {
          temporal[index]['stage']=true
          conservacion_edificaciones.push(value)
          }
        }
      }
      console.log("Mi temporal: ", temporal)
      console.log("Matriz conservacion: ", conservacion_edificaciones)
      this.setState({
        conservacion_edificacion: conservacion_edificaciones,
        type_conservacion: temporal
      });
    }

    ver_diafragmas_edificacion = (event) => {
      console.log("reconozco el evento: ", event.target.value)
      const {name, value} = event.target;
      const temporal = this.state.type_diafragmas
      console.log(temporal)
      for (let index = 0; index < temporal.length; index++) {
        const element = temporal[index]['id'];
        console.log(element)
        if (element == value) {
          temporal[index]['stage']=true
        } else {
          temporal[index]['stage']=false
        }
      }
      console.log(temporal)
      console.log(name, value)
      this.setState({
        [name]: value,
        type_diafragmas: temporal
      });
    }

    ver_configuracion_edificacion = (event) => {
      console.log("reconozco el evento: ", event.target.value)
      const {name, value} = event.target;
      const temporal = this.state.type_configuracion_edificacion
      console.log(temporal)
      for (let index = 0; index < temporal.length; index++) {
        const element = temporal[index]['id'];
        console.log(element)
        if (element == value) {
          temporal[index]['stage']=true
          this.setState({
            nombre_configuracion_edificacion: temporal[index]['name']
          })
          if (temporal[index]['tipo'] == '1') {
            this.setState({
              state_AA: false,
              state_BB: false,
              state_aa: true,
              state_bb: true,
              state_a1: true,
              state_b1: true,
              AA: '',
              BB: '',
              aa: '0',
              bb: '0',
              a1: '0',
              b1: '0',
              aa_real: '0',
              bb_real: '0',
            })
          } else if (temporal[index]['tipo'] == '2') {
            this.setState({
              state_AA: false,
              state_BB: false,
              state_aa: false,
              state_bb: false,
              state_a1: true,
              state_b1: true,
              AA: '',
              BB: '',
              aa: '',
              bb: '',
              a1: '0',
              b1: '0',
              aa_real: '',
              bb_real: '',
            })
          }
          else if (temporal[index]['tipo'] == '3'){
            this.setState({
              state_AA: false,
              state_BB: false,
              state_aa: false,
              state_bb: false,
              state_a1: false,
              state_b1: false,
              AA: '',
              BB: '',
              aa: '',
              bb: '',
              a1: '',
              b1: '',
              aa_real: '',
              bb_real: '',
            })
          }
        } else {
          temporal[index]['stage']=false
        }
      }
      console.log(temporal)
      console.log(name, value)
      this.setState({
        [name]: value,
        type_configuracion_edificacion: temporal
      });
    }
    
    ver_configuracion_edificacion_altura = (event) => {
      console.log("reconozco el evento de tipo de muros: ", event.target.value)
      const {name, value} = event.target;
      const temporal = this.state.type_configuracion_edificacion_altura
      let tipo_configuracion_edificacion_alturas = this.state.tipo_configuracion_edificacion_altura
      console.log(temporal)
      for (let index = 0; index < temporal.length; index++) {
        const element = temporal[index]['id'];
        console.log(element)
        if (element == value) {
          if (temporal[index]['stage'] == true ) {
            temporal[index]['stage'] = false
            const mytemp = []
            for (let index1 = 0; index1 < tipo_configuracion_edificacion_alturas.length; index1++) {
              if(tipo_configuracion_edificacion_alturas[index1] == value){
                console.log("entre a valor puesto: ")
              }else{
                mytemp.push(tipo_configuracion_edificacion_alturas[index1])
              }
            }
            console.log(mytemp)
            tipo_configuracion_edificacion_alturas = mytemp
          } else {
            temporal[index]['stage'] = true
            tipo_configuracion_edificacion_alturas.push(value)
          }
        } 
      }
      console.log("Mi temporal: ", temporal)
      console.log("Matriz Configuración Altura: ", tipo_configuracion_edificacion_alturas)
      this.setState({
        tipo_configuracion_edificacion_altura: tipo_configuracion_edificacion_alturas,
        type_configuracion_edificacion_altura: temporal
      });
    }


    //Pregunta sobre Tipo de Suelo    
    guarda_sistemaestructural = event => {
      console.log(event.target.value)
      const {name, value} = event.target;
      this.setState({
        [name]: value
      });    
      if (event.target.value=="Adobe") {
        this.setState({
          se_pregtwo:false,
          se_pregthr:false,
          se_pregfou:false,
          se_pregone:true
        })
      }
      if (event.target.value=="Albañileria Confinada") {
        this.setState({
          se_pregone:false,
          se_pregtwo:true,
          se_pregthr:false,
          se_pregfou:false,
        })
      }
      if (event.target.value=="Porticos de Concreto Armado") {
        this.setState({
          se_pregone:false,
          se_pregtwo:false,
          se_pregthr:true,
          se_pregfou:false,
        })
      }
      if (event.target.value=="Dual de Concreto Armado") {
        this.setState({
          se_pregone:false,
          se_pregtwo:false,
          se_pregthr:false,
          se_pregfou:true,
        })
      }
    }

    guarda_asesoramiento = event =>{
      console.log(event.target.value)
      const {name, value} = event.target;
      this.setState({
        [name]: value
      }); 
      if (event.target.value=="Si") {
        this.setState({
          at_pregtwo:false,
          at_pregone:true
        })
      }
      if (event.target.value=="No") {
        this.setState({
          at_pregone:false,
          at_pregtwo:true
        })
      }
    }
    
    years_generate = () => {
      //this.year_actual= this.state.ano_actual.getFullYear();
      //this.year_actual = this.state.ano_construccion;
      this.years = [];
      for (let index = 0; index < 80; index++) {
        this.years.push(this.state.ano_actual-index)
      }
      return(
        this.years.map(
          year => <option value={year}>{year}</option>         
        )
      )
    }

    guarda_irregularidadhorizontal = event => {
      console.log(event.target.value)
      /* const {name, value} = event.target;
      this.setState({
        [name]: value
      });     */
      if (event.target.value=="Regular") {
        this.setState({
          irregularidad_horizontal: "Regular",
          ih_regular: "ih_regular",
          ih_esquinas_entrantes:'',
          ih_proporciones_inadecuadas:'',
          ih_discontinuidad_piso:'',
          ih_pregtwo:false,
          ih_pregthr:false,
          ih_pregfou:false,
          ih_pregone:true
        })
      }
      if (event.target.value=="ih_esquinas_entrantes") {
        this.setState({
          irregularidad_horizontal: "Irregular",
          ih_regular:'',
          ih_esquinas_entrantes: "i_esquinas_entrantes",
          ih_pregone:false,
          ih_pregtwo:true
        })
      }
      if (event.target.value=="ih_proporciones_inadecuadas") {
        this.setState({
          irregularidad_horizontal: "Irregular",
          ih_regular:'',
          ih_proporciones_inadecuadas: "ih_proporciones_inadecuadas",
          ih_pregone:false,
          ih_pregthr:true,
        })
      }
      if (event.target.value=="ih_discontinuidad_piso") {
        this.setState({
          irregularidad_horizontal: "Irregular",
          ih_regular:'',
          ih_discontinuidad_piso: "ih_discontinuidad_piso",
          ih_pregone:false,
          ih_pregfou:true,
        })
      }
    }

    guarda_irregularidadaltura = event => {
      console.log(event.target.value)
      /* const {name, value} = event.target;
      this.setState({
        [name]: value
      });     */
      if (event.target.value=="Regular") {
        this.setState({
          irregularidad_planta: "Regular",
          ia_regular: "ia_regular",
          ia_geometria_vertical:'',
          ia_piso_blando:'',
          ia_masa_peso:'',
          ia_pregtwo:false,
          ia_pregthr:false,
          ia_pregfou:false,
          ia_pregone:true
        })
      }
      if (event.target.value=="ia_geometria_vertical") {
        this.setState({
          irregularidad_planta: "Irregular",
          ia_regular:'',
          ia_geometria_vertical: "ia_geometria_vertical",
          ia_pregone:false,
          ia_pregtwo:true
        })
      }
      if (event.target.value=="ia_piso_blando") {
        this.setState({
          irregularidad_planta: "Irregular",
          ia_regular:'',
          ia_piso_blando: "ia_piso_blando",
          ia_pregone:false,
          ia_pregthr:true,
        })
      }
      if (event.target.value=="ia_masa_peso") {
        this.setState({
          irregularidad_planta: "Irregular",
          ia_regular:'',
          ia_masa_peso: "ia_masa_peso",
          ia_pregone:false,
          ia_pregfou:true,
        })
      }
    }

    guarda_cantidadmuros = event =>{
      console.log(event.target.value)
      const {name, value} = event.target;
      this.setState({
        [name]: value
      }); 
      if (event.target.value=="muchos_muros") {
        this.setState({
          cm_pregtwo:false,
          cm_pregone:true
        })
      }
      if (event.target.value=="pocos_muros") {
        this.setState({
          cm_pregone:false,
          cm_pregtwo:true
        })
      }
    }
    
    guarda_estadoconservacion = event => {
      console.log(event.target.value)
      const {name, value} = event.target;
      this.setState({
        [name]: value
      });    
      if (event.target.value=="Mal Estado") {
        this.setState({
          ec_pregtwo:false,
          ec_pregthr:false,
          ec_pregone:true
        })
      }
      if (event.target.value=="Regular Estado") {
        this.setState({
          ec_pregone:false,
          ec_pregtwo:true,
          ec_pregthr:false
        })
      }
      if (event.target.value=="Buen Estado") {
        this.setState({
          ec_pregone:false,
          ec_pregtwo:false,
          ec_pregthr:true
        })
      }
    }

    guarda_elementosestructurales = event =>{
      console.log(event.target.value)
      const {name, value} = event.target;
      this.setState({
        [name]: value
      }); 
      if (event.target.value=="Muros no Arriostrados") {
        this.setState({
          ene_pregtwo:false,
          ene_pregone:true
        })
      }
      if (event.target.value=="Muros Arriostrados") {
        this.setState({
          ene_pregone:false,
          ene_pregtwo:true
        })
      }
    }

    guarda_techo = event =>{
      console.log(event.target.value)
      const {name, value} = event.target;
      this.setState({
        [name]: value
      }); 
      if (event.target.value=="Si") {
        this.setState({
          ti_pregtwo:false,
          ti_pregone:true
        })
      }
      if (event.target.value=="No") {
        this.setState({
          ti_pregone:false,
          ti_pregtwo:true
        })
      }
    }

    valida_recaptcha = value =>{
      console.log("El Valor del Recaptcha es: ", value)
    }
  /*
  * the functions for our buttons
  */
  previousButton() {
    let currentStep = this.state.currentStep;
    if(currentStep !==1 && currentStep <= 14){
      return (
        <button 
          className="btn btn-danger" 
          type="button" onClick={this._prev}>
        Previous
        </button>
      )
    }
    return null;
  }
  
  nextButton(){
    let currentStep = this.state.currentStep;
    if(currentStep==1 && this.state.latitud==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==2 && this.state.altura_pisos==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==2 && this.state.pisos_proyectados==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==2 && this.state.pisos_construidos==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==2 && this.state.fin_construccion==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==2 && this.state.area_edificacion==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==2 && this.state.inicio_construccion==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==3 && this.state.tipo_suelo==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==4 && this.state.pendiente_terreno==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==5 && this.state.contruccion_edificacion==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==6 && this.state.ladrillos_muros==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==7 && this.state.techo_cobertura==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==8 && this.state.muros_fachada.length==0){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==9 && this.state.conservacion_edificacion.length==0){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    // if(currentStep==10 && this.state.cantidad_x1==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==10 && this.state.longitud_x1==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==10 && this.state.espesor_x1==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    // if(currentStep==10 && this.state.cantidad_x2==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==10 && this.state.longitud_x2==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==10 && this.state.espesor_x2==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    //if(currentStep==10 && this.state.cantidad_x3==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    //if(currentStep==10 && this.state.longitud_x3==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    //if(currentStep==10 && this.state.espesor_x3==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    // if(currentStep==10 && this.state.cantidad_y1==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==10 && this.state.longitud_y1==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==10 && this.state.espesor_y1==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    // if(currentStep==10 && this.state.cantidad_y2==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==10 && this.state.longitud_y2==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==10 && this.state.espesor_y2==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    //if(currentStep==10 && this.state.cantidad_y3==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    //if(currentStep==10 && this.state.longitud_y3==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    //if(currentStep==10 && this.state.espesor_y3==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    
    
    if(currentStep==11 && this.state.tipo_diafragmas==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==12 && this.state.tipo_configuracion_edificacion==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==12 && this.state.AA==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==12 && this.state.BB==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==12 && this.state.aa==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==12 && this.state.bb==''){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep==13 && this.state.tipo_configuracion_edificacion_altura.length==0){return (<button className="btn btn-dark float-right" type="button" onClick={this._next} disabled={true}>Next</button>)}
    if(currentStep<14){
      return (
        <button 
          className="btn btn-dark float-right" 
          type="button" onClick={this._next} disabled={false}>
        Next
        </button>        
      )
    }
    return null;
  }

  async post_encuesta(ruta){
    await axios.post(ruta,{
      "latitud":this.state.latitud,
      "longitud":this.state.longitud,
      "inicio_construccion":this.state.inicio_construccion,
      "fin_construccion":this.state.fin_construccion,
      "area_edificacion":this.state.area_edificacion,
      "pisos_construidos":this.state.pisos_construidos,
      "pisos_proyectados":this.state.pisos_proyectados,
      "altura_pisos":this.state.altura_pisos,
      "uso_edificacion":this.state.uso_edificacion,
      "uso_edificacion_otro":this.state.uso_edificacion_otro,
      "tipo_suelo":this.state.tipo_suelo,
      "pendiente_terreno":this.state.pendiente_terreno,
      "contruccion_edificacion":this.state.contruccion_edificacion,
      "ladrillos_muros":this.state.ladrillos_muros,
      "techo_cobertura":this.state.techo_cobertura,
      "muros_fachada":this.state.muros_fachada,
      "conservacion_edificacion":this.state.conservacion_edificacion,

      "matriz_m_conf_X":this.state.matriz_m_conf_X,
      "matriz_m_conf_Y":this.state.matriz_m_conf_Y,

      "muros_confinados":this.state.muros_confinados,
      "cantidad_x1":this.state.cantidad_x1, 
      "longitud_x1":this.state.longitud_x1, 
      "espesor_x1":this.state.espesor_x1,
      "pared_x1":this.state.pared_x1,
      "cantidad_x2":this.state.cantidad_x2, 
      "longitud_x2":this.state.longitud_x2, 
      "espesor_x2":this.state.espesor_x2,
      "pared_x2":this.state.pared_x2,
      "cantidad_x3":this.state.cantidad_x3, 
      "longitud_x3":this.state.longitud_x3, 
      "espesor_x3":this.state.espesor_x3,
      "pared_x3":this.state.pared_x3,
      "espesor_juntas": this.state.espesor_juntas,

      "cantidad_y1":this.state.cantidad_y1, 
      "longitud_y1":this.state.longitud_y1, 
      "espesor_y1":this.state.espesor_y1,
      "pared_y1":this.state.pared_y1,
      "cantidad_y2":this.state.cantidad_y2, 
      "longitud_y2":this.state.longitud_y2, 
      "espesor_y2":this.state.espesor_y2,
      "pared_y2":this.state.pared_y2,
      "cantidad_y3":this.state.cantidad_y3, 
      "longitud_y3":this.state.longitud_y3, 
      "espesor_y3":this.state.espesor_y3,
      "pared_y3":this.state.pared_y3,
      "tipo_diafragmas":this.state.tipo_diafragmas,
      "tipo_configuracion_edificacion":this.state.tipo_configuracion_edificacion,
      "AA":this.state.AA,
      "BB":this.state.BB,
      "aa":this.state.aa_real,
      "bb":this.state.bb_real,
      "tipo_configuracion_edificacion_altura":this.state.tipo_configuracion_edificacion_altura})
    .then(response =>{
      console.log("Encuesta enviada: ",response.data);
      this.setState({
        resultado_encuesta: response.data['agregado']
      })
      if (response.data['respuesta'] == 'agregado correctamente') {
        this._next();        
      }
    })
    .catch(e => {

    })
  }
  
  request_calculo = () =>{
    console.log("Obtener calculo encuesta")
    // this.post_encuesta('http://localhost:4000/encuesta/calculo')
    this.post_encuesta('encuesta/calculo')
  };

  sendButton(){
    let currentStep = this.state.currentStep;
    if(currentStep == 14){
      return (
        <button className="btn btn-success float-right" type="button" onClick={this.request_calculo}>Envia</button>        
      )
    }
    return null;
  }
    
  //MUI Steepers
  isStepOptional(step){
    return step === 18;
  };
  isStepSkipped = (step) => {
    return this.state.step;
  };

  muestraprueb=()=>{
    console.log("Datos de la Encuesta: ")
    console.log(this.state.latitud)
    console.log(this.state.longitud)
    console.log(this.state.tipo_suelo)
    console.log(this.state.area_edificacion)
    console.log(this.state.numero_habitantes)
    console.log(this.state.uso_edificacion)
    console.log(this.state.asesoramiento_tecnico)
  };

  async get_tipo_suelo(){
    await axios.get('encuesta/tipossuelos')
    .then(response =>{
      console.log(response.data);
      const tipos_suelo =  response.data
      
      for (let index = 0; index < tipos_suelo.length; index++) {
          tipos_suelo[index]["stage"]=false
          tipos_suelo[index]["image"]=this.state.ruta_django + tipos_suelo[index]["image"]
      }
      this.setState({
        type_earth: tipos_suelo
      })
      console.log(tipos_suelo)
    })
    .catch(e => {})
  }

  async get_tipo_pendiente(){
    await axios.get('encuesta/tipospendientes')
    .then(response =>{
      console.log(response.data);
      const tipos_pendientes =  response.data
      
      for (let index = 0; index < tipos_pendientes.length; index++) {
          tipos_pendientes[index]["stage"]=false
          tipos_pendientes[index]["image"]=this.state.ruta_django + tipos_pendientes[index]["image"]
      }
      this.setState({
        type_pendient: tipos_pendientes
      })
      console.log("Entrando a ver pendientes ....!!!!!!")
      console.log(tipos_pendientes)
    })
    .catch(e => {})
  }

  async get_tipo_construccion(){
    await axios.get('encuesta/tiposconstrucciones')
    .then(response =>{
      console.log(response.data);
      const tipos_construcciones =  response.data
      
      for (let index = 0; index < tipos_construcciones.length; index++) {
          tipos_construcciones[index]["stage"]=false
          tipos_construcciones[index]["image"]=this.state.ruta_django + tipos_construcciones[index]["image"]
      }
      this.setState({
        type_construccion: tipos_construcciones
      })
      console.log("Entrando a ver pendientes ....!!!!!!")
      console.log(tipos_construcciones)
    })
    .catch(e => {})
  }

  async get_tipo_ladrillo(){
    await axios.get('encuesta/tiposladrillos')
    .then(response =>{
      console.log(response.data);
      const tipos_ladrillos =  response.data
      
      for (let index = 0; index < tipos_ladrillos.length; index++) {
          tipos_ladrillos[index]["stage"]=false
          tipos_ladrillos[index]["image"]=this.state.ruta_django + tipos_ladrillos[index]["image"]
      }
      this.setState({
        type_ladrillos: tipos_ladrillos
      })
      console.log("Entrando a ver pendientes ....!!!!!!")
      console.log(tipos_ladrillos)
    })
    .catch(e => {})
  }
  async get_tipo_cobertura(){
    await axios.get('encuesta/tiposcoberturas')
    .then(response =>{
      console.log(response.data);
      const tipos_coberturas =  response.data
      
      for (let index = 0; index < tipos_coberturas.length; index++) {
          tipos_coberturas[index]["stage"]=false
          tipos_coberturas[index]["image"]=this.state.ruta_django + tipos_coberturas[index]["image"]
      }
      this.setState({
        type_cobertura: tipos_coberturas
      })
      console.log("Entrando a ver pendientes ....!!!!!!")
      console.log(tipos_coberturas)
    })
    .catch(e => {})
  }
  async get_tipo_muros_fachada(){
    await axios.get('encuesta/tiposmurosfachada')
    .then(response =>{
      console.log(response.data);
      const tipos_muros_fachada =  response.data
      
      for (let index = 0; index < tipos_muros_fachada.length; index++) {
          tipos_muros_fachada[index]["stage"]=false
          tipos_muros_fachada[index]["image"]=this.state.ruta_django + tipos_muros_fachada[index]["image"]
      }
      this.setState({
        type_muros_fachada: tipos_muros_fachada
      })
      console.log("Entrando a ver muros fachadas ....!!!!!!")
      console.log(tipos_muros_fachada)
    })
    .catch(e => {})
  }
  
  async get_tipo_conservacion(){
    await axios.get('encuesta/tiposconservacion')
    .then(response =>{
      console.log(response.data);
      const tipos_conservacion =  response.data
      
      for (let index = 0; index < tipos_conservacion.length; index++) {
          tipos_conservacion[index]["stage"]=false
          tipos_conservacion[index]["image"]=this.state.ruta_django + tipos_conservacion[index]["image"]
      }
      this.setState({
        type_conservacion: tipos_conservacion
      })
      console.log("Entrando a ver pendientes ....!!!!!!")
      console.log(tipos_conservacion)
    })
    .catch(e => {})
  }

  async get_tipo_diafragmas(){
    await axios.get('encuesta/tiposdiafragmasedificacion')
    .then(response =>{
      console.log(response.data);
      const tipos_diafragmas =  response.data
      
      for (let index = 0; index < tipos_diafragmas.length; index++) {
        tipos_diafragmas[index]["stage"]=false
        tipos_diafragmas[index]["image"]=this.state.ruta_django + tipos_diafragmas[index]["image"]
      }
      this.setState({
        type_diafragmas: tipos_diafragmas
      })
      console.log("Entrando a ver pendientes ....!!!!!!")
      console.log(tipos_diafragmas)
    })
    .catch(e => {})
  }

  async get_tipo_configuracion_edificacion(){
    await axios.get('encuesta/tiposconfiguracionedificaciones')
    .then(response =>{
      console.log(response.data);
      const tipos_configuracion_edificacion =  response.data
      
      for (let index = 0; index < tipos_configuracion_edificacion.length; index++) {
        tipos_configuracion_edificacion[index]["stage"]=false
        tipos_configuracion_edificacion[index]["image"]=this.state.ruta_django + tipos_configuracion_edificacion[index]["image"]
      }
      this.setState({
        type_configuracion_edificacion: tipos_configuracion_edificacion
      })
      console.log("Entrando a ver pendientes ....!!!!!!")
      console.log(tipos_configuracion_edificacion)
    })
    .catch(e => {})
  }

  async get_tipo_configuracion_edificacion_altura(){
    await axios.get('encuesta/tiposconfiguracionedificacionealturas')
    .then(response =>{
      console.log(response.data);
      const tipos_configuracion_edificacion_altura =  response.data
      
      for (let index = 0; index < tipos_configuracion_edificacion_altura.length; index++) {
        tipos_configuracion_edificacion_altura[index]["stage"]=false
        tipos_configuracion_edificacion_altura[index]["image"]=this.state.ruta_django + tipos_configuracion_edificacion_altura[index]["image"]
      }
      this.setState({
        type_configuracion_edificacion_altura: tipos_configuracion_edificacion_altura
      })
      console.log("Entrando a ver muros fachadas ....!!!!!!")
      console.log(tipos_configuracion_edificacion_altura)
    })
    .catch(e => {})
  }
  

  componentDidMount(){
    this.get_tipo_suelo();
    this.get_tipo_pendiente();
    this.get_tipo_construccion();
    this.get_tipo_ladrillo();
    this.get_tipo_cobertura();
    this.get_tipo_muros_fachada();
    this.get_tipo_conservacion();
    this.get_tipo_diafragmas();
    this.get_tipo_configuracion_edificacion();
    this.get_tipo_configuracion_edificacion_altura();
    this.post_encuesta()
  }
  /* componentDidUpdate(){
    this.habilita_step()
  } */
  titulo_section(){
    if (this.state.currentStep<3) {
      return <p className="Titulo_Seccion">Datos Generales</p>
    }
    if (this.state.currentStep>=3 && this.state.currentStep<5) {
      return <p className="Titulo_Seccion">Aspectos Geotécnicos</p>
    }
    if (this.state.currentStep>=5 && this.state.currentStep<10) {
      return <p className="Titulo_Seccion">Aspectos Constructivos</p>
    }
    if (this.state.currentStep>=10 && this.state.currentStep<14) {
      return <p className="Titulo_Seccion">Aspectos Estructurales</p>
    }
    if (this.state.currentStep==14) {
      return <p className="Titulo_Seccion">Envia Respuesta</p>
    }
    return null
  }
  subtitulo(){
    if (this.state.currentStep<14) {
      return  <p><strong>Pregunta {this.state.currentStep}: {this.state.steps[this.state.currentStep - 1]}</strong></p>
    }
    return null
  }

  render() {    
      return (
        <React.Fragment>
        {/* <h1>React Wizard Form 🧙‍♂️</h1>  */}
        <MDBContainer fluid className="toot">
      <MDBRow className="tittle_general">
        <MDBCol sm={12}> Encuesta de Vulnerabilidad</MDBCol>
      </MDBRow>

      <MDBRow className="encuesta_seccion">  
      <MDBCol className="preguntas_encuesta" sm={10}>
        {/* <button onClick={this.muestraprueb}>prueba</button> */}
        {/* <form onSubmit={this.handleSubmit}> */}
        {/* 
          render the form steps and pass required props in
        */}
        <div className="instruccion">
            {this.titulo_section()}
            {this.subtitulo()}
            <ModalPageIntro titulo="Encuesta de Vulnerabilidad de Edificaciones de Albañilería" estado={true} src_modal="cipfinal.png" src_modal2="redacel.png" texto="texto prueba"/>
            <Step1
                currentStep={this.state.currentStep} 
                username={this.state.username}
                updatelatlon={this.update_latlon}
                urlclic={this.state.url_clic}
                lati={Math.round(this.state.latitud*1000000)/1000000}
                long={Math.round(this.state.longitud*1000000)/1000000}
            />
            <Step2 
                currentStep={this.state.currentStep} 
                handleChange={this.handleChangetexts}
                InicioConstruccion={this.state.inicio_construccion}
                FinConstruccion={this.state.fin_construccion}
                AreaEdificacion={this.state.area_edificacion}
                PisosConstruidos={this.state.pisos_construidos}
                PisosProyectados={this.state.pisos_proyectados}
                AlturaPisos={this.state.altura_pisos}
                UsoEdificacion={this.state.uso_edificacion}
                UsoEdificacionOtro={this.state.uso_edificacion_otro}
                EstadoUsoEdificacionOtro={this.state.estado_edificacion_otro}
            />
            <Step3 
                currentStep={this.state.currentStep} 
                email={this.state.email}
                ver_data={this.ver_data}
                type_earth = {this.state.type_earth}
            />
            <Step4 
                currentStep={this.state.currentStep} 
                ver_pendiente_terreno={this.ver_pendiente_terreno}
                type_pendient = {this.state.type_pendient}
            />
            <Step5 
                currentStep={this.state.currentStep}
                ver_construccion_edificacion={this.ver_construccion_edificacion}
                type_construccion={this.state.type_construccion}
            />
            <Step6 
                currentStep={this.state.currentStep} 
                ver_tipo_ladrillos={this.ver_tipo_ladrillos}
                type_ladrillos={this.state.type_ladrillos}
            />
            <Step7 
                currentStep={this.state.currentStep}
                ver_tipo_techo_cobertura = {this.ver_tipo_techo_cobertura}
                type_cobertura = {this.state.type_cobertura}
            />
            <Step8
                currentStep={this.state.currentStep}
                ver_tipo_muros_fachada={this.ver_tipo_muros_fachada}
                type_muros_fachada={this.state.type_muros_fachada}
            />
            <Step9 
                currentStep={this.state.currentStep}
                ver_conservacion_construccion={this.ver_conservacion_construccion}
                type_conservacion = {this.state.type_conservacion}
            />
            <Step10
                currentStep={this.state.currentStep} 
                
                cantidad_ejes={this.state.cantidad_ejes} 
                lista_eje_X={this.state.lista_eje_X}
                matriz_m_conf_X={this.state.matriz_m_conf_X}

                cantidad_ejes_Y={this.state.cantidad_ejes_Y}
                lista_eje_Y={this.state.lista_eje_Y}
                matriz_m_conf_Y={this.state.matriz_m_conf_Y}
                //guarda_estadoconservacion={this.guarda_estadoconservacion}
                cantidad_x1={this.state.cantidad_x1} 
                longitud_x1={this.state.longitud_x1}
                espesor_x1={this.state.espesor_x1}
                pared_x1={this.state.pared_x1}
                cantidad_x2={this.state.cantidad_x2} 
                longitud_x2={this.state.longitud_x2}
                espesor_x2={this.state.espesor_x2}
                pared_x2={this.state.pared_x2}
                cantidad_x3={this.state.cantidad_x3} 
                longitud_x3={this.state.longitud_x3}
                espesor_x3={this.state.espesor_x3}
                pared_x3={this.state.pared_x3}
                
                cantidad_y1={this.state.cantidad_y1} 
                longitud_y1={this.state.longitud_y1}
                espesor_y1={this.state.espesor_y1}
                pared_y1={this.state.pared_y1}
                cantidad_y2={this.state.cantidad_y2} 
                longitud_y2={this.state.longitud_y2}
                espesor_y2={this.state.espesor_y2}
                pared_y2={this.state.pared_y2}
                cantidad_y3={this.state.cantidad_y3} 
                longitud_y3={this.state.longitud_y3}
                espesor_y3={this.state.espesor_y3}
                pared_y3={this.state.pared_y3}
                EspesorJuntas={this.state.espesor_juntas}
                
                handleChange={this.handleChangetexts}
                state_ec_pre1 ={this.state.ec_pregone}
                state_ec_pre2 ={this.state.ec_pregtwo}
                state_ec_pre3 ={this.state.ec_pregthr}
            />
            <Step11 
                currentStep={this.state.currentStep}
                ver_diafragmas_edificacion={this.ver_diafragmas_edificacion}
                type_diafragmas = {this.state.type_diafragmas}

            />
            <Step12
                currentStep={this.state.currentStep} 
                ver_configuracion_edificacion={this.ver_configuracion_edificacion}
                type_configuracion_edificacion={this.state.type_configuracion_edificacion}
                AA ={this.state.AA}
                BB ={this.state.BB}
                aa ={this.state.aa}
                bb ={this.state.bb}
                a1 ={this.state.a1}
                b1 ={this.state.b1}
                state_AA = {this.state.state_AA}
                state_BB = {this.state.state_BB}
                state_aa = {this.state.state_aa}
                state_bb = {this.state.state_bb}
                state_a1 = {this.state.state_a1}
                state_b1 = {this.state.state_b1}
                handleChange={this.handleChangetextsplanta}
                /*handleChange={this.handleChangetexts}*/
            />
            <Step13
                currentStep={this.state.currentStep} 
                ver_configuracion_edificacion_altura={this.ver_configuracion_edificacion_altura}
                type_configuracion_edificacion_altura = {this.state.type_configuracion_edificacion_altura}
            />
            <Step14
                currentStep={this.state.currentStep} 
                recaptcha = {this.valida_recaptcha}
            />
            <Step15
                currentStep={this.state.currentStep} 
                longitud_sismo={this.state.longitud}
                latitud_sismo={this.state.latitud}
                resultado_encuesta={this.state.resultado_encuesta}
                url_data2="https://eval-vulnerabilidad.cdlima.org.pe/encuesta/vulnerabilidadperu"//{this.state.url_clic}
            />
        </div>
        <div className="Barra_Botones"> 
            {this.previousButton()}
            {this.nextButton()}
            {this.sendButton()}
        </div>
        {/* </form> */}
        
        </MDBCol>
        <MDBCol className="preguntas_avance" sm={2}>
      <Stepper className="steps_respondidos" activeStep={this.state.currentStep-1} orientation="vertical">
        {this.state.steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (this.isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          if (this.isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      </MDBCol>
        </MDBRow>
        </MDBContainer>
        </React.Fragment>
      );
    }
  }
  
  function Step1(props) {
    if (props.currentStep !== 1) {
      return null
    } 
    return(
      <div className="form-group">
          <small><strong>Seleccione su ubicación haciendo clic en el mapa, o con la opción de geoposicionamiento, o buscando tu dirección en particular.</strong></small>
          <MapaEncuesta updatecoord={props.updatelatlon} url_referencia={props.urlclic}/> 
          <span><strong>Latitud: </strong>{props.lati}  <strong>Longitud: </strong> {props.long}</span>
      </div>
    );
  }


  
  function Step2(props) {
    if (props.currentStep !== 2) {
      return null
    } 
    return(
      <React.Fragment>
      <div className="form-group">
        <MDBContainer>
          <MDBRow sm={12}>
            <MDBCol sm={12}>
              <small><strong>Nota: </strong>Para avanzar llene todos los campos.</small>
            </MDBCol>
            <br/>
          <MDBCol sm={6}>
            <label htmlFor="inicio_construccion">Inicio de construcción (año)</label>
            <input
              className="form-control"
              id="inicio_construccion"
              name="inicio_construccion"
              type="number"
              placeholder="Inicio de construcción"
              pattern='[0-9]{4}'
              min="1920"
              max="2023"
              value={props.InicioConstruccion}
              onChange={props.handleChange}
              />   
            <small><strong>Cuatro dígitos como máximo. e.g. 2000</strong></small>

            <br/><br/>
            <label htmlFor="inicio_construccion">Fin de construcción (año)</label>
            <input
              className="form-control"
              id="fin_construccion"
              name="fin_construccion"
              type="number"
              placeholder="Fin de construcción"
              pattern='[0-9]{0,4}'
              min="0"
              max="9999"
              value={props.FinConstruccion}
              onChange={props.handleChange}
              />   
            <small><strong>Cuatro dígitos como máximo. e.g. 2022</strong></small>

            <br/><br/>

            <label htmlFor="area_edificacion">Área del 1<sup>er</sup> piso de la edificación (m<sup>2</sup>)</label>
            <input
              className="form-control"
              id="area_edificacion"
              name="area_edificacion"
              type="number"
              placeholder="Área del primer piso (m2)"
              value={props.AreaEdificacion}
              onChange={props.handleChange}
              pattern='[0-9]{0,4}'
              min="0"
              max="9999"
              />   
              <small><strong>Cuatro dígitos como máximo. e.g. 2334</strong></small>
              
              <br/><br/>

              <label htmlFor="pisos_construidos">N° Pisos construidos</label>
              <input
                className="form-control"
                id="pisos_construidos"
                name="pisos_construidos"
                type="number"
                placeholder="Pisos Construidos"
                pattern='[0-9]{0,3}'
                min="1"
                max="10"
                value={props.PisosConstruidos}
                onChange={props.handleChange}
                />   
              <small><strong>Tres dígitos como máximo. e.g. 124</strong></small>
              {/* <br/><br/>
              <select value={props.PisosConstruidos} onChange={props.handleChange} className="form-control" id="pisos_construidos" name="pisos_construidos">
                <option value=''>Seleccione el número de pisos construidos </option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
              </select>
              <small><strong>Selecione el número de pisos construidos de su edificación</strong></small> */}

            </MDBCol>
            <MDBCol sm={6}>
              <label htmlFor="pisos_proyectados">N° Pisos proyectados originalmente</label>
              <input
                className="form-control"
                id="pisos_proyectados"
                name="pisos_proyectados"
                type="number"
                placeholder="Pisos Proyectados"
                pattern='[0-9]{0,3}'
                min="1"
                max="10"
                value={props.PisosProyectados}
                onChange={props.handleChange}
                />   
              <small><strong>Tres dígitos como máximo. e.g. 124</strong></small>
              <br/><br/>

              <label htmlFor="altura_pisos">Altura promedio de pisos (m)</label>
              <input
                className="form-control"
                id="altura_pisos"
                name="altura_pisos"
                type="number"
                placeholder="Altura Pisos entre 2.1m y 5m"
                pattern='[0-9]{0,2}'
                min="0"
                max="99"
                value={props.AlturaPisos}
                onChange={props.handleChange}
                />   
              <small><strong>Dos dígitos como máximo. e.g. </strong></small>
              <br/><br/>
              
                  
              <label htmlFor="uso_edificacion">Uso de la Edificación</label>
              <select
                className="form-control"
                id="uso_edificacion"
                name="uso_edificacion"
                type="text"
                placeholder="Seleccione el Uso de la Edificación"
                value={props.UsoEdificacion}
                onChange={props.handleChange}
                >
              <option value="Vivienda">Vivienda</option> 
              <option value="Tienda">Tienda o Negocio</option> 
              <option value="Hoteles">Hoteles</option> 
              <option value="Lugares_Asamblea">Lugares Asamblea</option> 
              <option value="Edificaciones_Importantes">Edificaciones Importantes</option> 
              <option value="Otros">Otros</option> 
              </select>   
              <small><strong>Seleccione el tipo de uso que le da a la edificación.</strong></small>

              <br/><br/>
              <input
                className={props.EstadoUsoEdificacionOtro}
                id="uso_edificacion_otro"
                name="uso_edificacion_otro"
                type="text"
                placeholder="Especifique su tipo de vivienda"
                value={props.UsoEdificacionOtro}
                onChange={props.handleChange}
                />   
                
            </MDBCol>
          </MDBRow>
        </MDBContainer>
           
      </div>
      {/* <button className="btn btn-success btn-block">Sign up</button> */}
      </React.Fragment>
    );
  }

  function Step3(props) {
    if (props.currentStep !== 3) {
      return null
    } 
    return(
      <div className="form-group">
          <MDBContainer>
          <small><strong>Seleccione el tipo de suelo donde fue construido su vivienda.</strong></small>

          <MDBRow className="seccion_row">
            {props.type_earth.map(({id, name, stage, image, description}) => <MDBCol sm={4} className="col_encuesta">
                <div className="radio" id="atributo">
                <label className="form-check-label" for={"opcion"+id}>
                <input type="radio" id={"opcion"+id} value={id} name="tipo_suelo" onClick={props.ver_data} checked={stage}/>
                <img src={image} class="imagenes" alt="Responsive image"/>
                <ModalPage titulo={name} estado={false} src_modal={image} texto={description}/>{/*<button type="button" onClick={ver_modal} className="btn btn-danger bg-danger boton_encima"  data-toggle="modal" data-target="#SiAsesoramientoT">+</button>*/}                
                </label>
              </div>
              <br/>
            </MDBCol>
            )}
            
          </MDBRow>
          </MDBContainer>
      </div>
    );
  }
  
  function Step4(props) {
    if (props.currentStep !== 4) {
      return null
    } 
    return(
      <div className="form-group">
          <MDBContainer>
          <small><strong>Seleccione una opción sobre la pendiente del terreno sobre el cual fue construido su vivienda.</strong></small>
          <MDBRow className="seccion_row">
            {props.type_pendient.map(({id, name, stage, image, description}) => <MDBCol sm={6} className="col_encuesta">
                <div className="radio" id="atributo">
                <label className="form-check-label" for={"at_opcion"+id}>
                <input type="radio" id={"at_opcion"+id} value={id} name="pendiente_terreno" onClick={props.ver_pendiente_terreno} checked={stage}/>
                <img src={image} class="imagenes" alt="Responsive image"/>
                <ModalPage titulo={name} estado={false} src_modal={image} texto={description}/>{/*<button type="button" onClick={ver_modal} className="btn btn-danger bg-danger boton_encima"  data-toggle="modal" data-target="#SiAsesoramientoT">+</button>*/}
                </label>
              </div>
              <br/>
            </MDBCol>
            )}
            
          </MDBRow>
          </MDBContainer>
      </div>  
    );
  }

  function Step5(props) {
    if (props.currentStep !== 5) {
      return null
    } 
    return(
      <div className="form-group">
          <MDBContainer>
          <small><strong>Seleccione una opción sobre el tipo de construcción de su vivienda.</strong></small>
          <MDBRow className="seccion_row">
            {props.type_construccion.map(({id, name, stage, image, description}) => <MDBCol sm={6} className="col_encuesta">
                <div className="radio" id="atributo">
                <label className="form-check-label" for={"ce_opcion"+id}>
                <input type="radio" id={"ce_opcion"+id} value={id} name="contruccion_edificacion" onClick={props.ver_construccion_edificacion} checked={stage}/>
                <img src={image} class="imagenes" alt="Responsive image"/>
                <ModalPage titulo={name} estado={false} src_modal={image} texto={description}/>{/*<button type="button" onClick={ver_modal} className="btn btn-danger bg-danger boton_encima"  data-toggle="modal" data-target="#SiAsesoramientoT">+</button>*/}
                </label>
              </div> 
              <br/>
            </MDBCol>
            )}
            
          </MDBRow>
          
          </MDBContainer>
      </div>
    );
  }

  function Step6(props) {
    if (props.currentStep !== 6) {
      return null
    } 
    return(
      <MDBContainer>
          <small><strong>Seleccione una opción sobre el tipo de ladrillo de los muros del 1er piso.</strong></small>
          <MDBRow className="seccion_row">
            {props.type_ladrillos.map(({id, name, stage, image, description}) => <MDBCol sm={6} className="col_encuesta">
                <div className="radio" id="atributo">
                <label className="form-check-label" for={"tl_opcion"+id}>
                <input type="radio" id={"tl_opcion"+id} value={id} name="ladrillos_muros" onClick={props.ver_tipo_ladrillos} checked={stage}/>
                <img src={image} class="imagenes" alt="Responsive image"/>
                <ModalPage titulo={name} estado={false} src_modal={image} texto={description}/>{/*<button type="button" onClick={ver_modal} className="btn btn-danger bg-danger boton_encima"  data-toggle="modal" data-target="#SiAsesoramientoT">+</button>*/}
                </label>
              </div>
              <br/>
            </MDBCol>
            )}
            
          </MDBRow>
          </MDBContainer>
    );
  }

  function Step7(props) {
    if (props.currentStep !== 7) {
      return null
    } 
    return(
      <div className="form-group">
          <MDBContainer>
          <small><strong>Seleccione una opción sobre la cobertura o techo del último piso.</strong></small>
          <MDBRow className="seccion_row">
            {props.type_cobertura.map(({id, name, stage, image, description}) => <MDBCol sm={6} className="col_encuesta">
                <div className="radio" id="atributo">
                <label className="form-check-label" for={"tc_opcion"+id}>
                <input type="radio" id={"tc_opcion"+id} value={id} name="techo_cobertura" onClick={props.ver_tipo_techo_cobertura} checked={stage}/>
                <img src={image} class="imagenes" alt="Responsive image"/>
                <ModalPage titulo={name} estado={false} src_modal={image} texto={description}/>{/*<button type="button" onClick={ver_modal} className="btn btn-danger bg-danger boton_encima"  data-toggle="modal" data-target="#SiAsesoramientoT">+</button>*/}
                </label>
              </div>
              <br/>
            </MDBCol>
            )}
            
          </MDBRow>
          
          </MDBContainer>
      </div>
    );
  }
  
  function Step8(props) {
    if (props.currentStep !== 8) {
      return null
    } 
    return(
      <div className="form-group">
          <MDBContainer>
          <small><strong>Seleccione <strong>una o más opciones</strong> sobre como fueron construidos los muros interiores y fachadas.</strong></small>
          <MDBRow className="seccion_row">
            {props.type_muros_fachada.map(({id, name, stage, image, description}) => <MDBCol sm={6} className="col_encuesta">
                <div className="radio" id="atributo">
                <label className="form-check-label" for={"mf_opcion"+id}>
                <input type="radio" id={"mf_opcion"+id} value={id} name={"muros_fachada"+id} onClick={props.ver_tipo_muros_fachada} checked={stage}/>
                <img src={image} class="imagenes" alt="Responsive image"/>
                <ModalPage titulo={name} estado={false} src_modal={image} texto={description}/>{/*<button type="button" onClick={ver_modal} className="btn btn-danger bg-danger boton_encima"  data-toggle="modal" data-target="#SiAsesoramientoT">+</button>*/}
                </label>
              </div>
              <br/>
            </MDBCol>
            )}
            
          </MDBRow>
          </MDBContainer>
      </div>
    );
  }

  function Step9(props) {
    if (props.currentStep !== 9) {
      return null
    } 
    return(
      <div className="form-group">
          <MDBContainer>
          <small><strong>Seleccione <strong>una o más opciones</strong> sobre el estado actual de los muros.</strong></small>
          <MDBRow className="seccion_row">
            {props.type_conservacion.map(({id, name, stage, image, description}) => <MDBCol sm={6} className="col_encuesta">
              <div className="radio" id="atributo">
                <label className="form-check-label" for={"cc_opcion"+id}>
                <input type="radio" id={"cc_opcion"+id} value={id} name={"conservacion_edificacion"+id} onClick={props.ver_conservacion_construccion} checked={stage}/>
                <img src={image} class="imagenes" alt="Responsive image"/>
                <ModalPage titulo={name} estado={false} src_modal={image} texto={description}/>{/*<button type="button" onClick={ver_modal} className="btn btn-danger bg-danger boton_encima"  data-toggle="modal" data-target="#SiAsesoramientoT">+</button>*/}
                </label>
              </div>
              <br/>
            </MDBCol>
            )}
          </MDBRow>
          </MDBContainer>
      </div>  
    );
  }

  function Step10(props) {
    if (props.currentStep !== 10) {
      return null
    } 
    return(
      <div className="form-group">
          <MDBContainer>
            <MDBRow className="seccion_row">
              <MDBCol sm={12}>
                <small className='color_important'><strong>Esta parte de la encuesta es importante, identifique los muros confinados de su vivienda, luego mida la longitud en la dirección X e Y, y su espesor como se muestra en la figura. (No se considera las ventanas ni puertas)</strong></small>
                <br/>
                <img src="ejexy01.png" className="ejexy"/>
              </MDBCol>
              <MDBCol sm={12}  className="noborder-top">
                <small><strong>Nota: </strong>Para avanzar llene todos los campos.</small>
                <br/>
              </MDBCol>
              <MDBCol sm={4} className="border-top">
                <small><strong>Seleccione la cantidad de muros en el eje X: </strong></small>
              </MDBCol>
              
              <MDBCol sm={3}  className="border-top">
                <select className="form-control" name="cantidad_ejes" onChange={props.handleChange} value={props.cantidad_ejes}>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                </select>
              </MDBCol>
              <MDBCol sm={5} className="border-top"></MDBCol>

              {props.lista_eje_X.map((eje_Xx) => <MDBCol sm={3}>
                  <label htmlFor="inicio_construccion"><small>Eje: X{eje_Xx}</small></label>
                  {/* <input className="form-control" id={"cantidad_x"+eje_Xx} name={"cantidad_x"+eje_Xx} value={props.matriz_m_conf_X[eje_Xx-1][0]} type="number" step={0.01} placeholder="Cantidad" pattern='[0-9]{0,4}' min="0" max="9999" onChange={props.handleChange}/>   */}
                  <input className="form-control" id={"longitud_x"+eje_Xx} name={"longitud_x"+eje_Xx} value={props.matriz_m_conf_X[eje_Xx-1][0]} type="number" step={0.01}  placeholder="Longitud > a 1.2m" pattern='[0-9]{0,4}' min="0" max="9999" onChange={props.handleChange}/>  
                  <input className="form-control" id={"espesor_x"+eje_Xx} name={"espesor_x"+eje_Xx} value={props.matriz_m_conf_X[eje_Xx-1][1]} type="number" step={0.01}  placeholder="Espesor (0.1m a 0.5m)" pattern='[0-9]{0,4}' min="0" max="9999" onChange={props.handleChange}/>  
                  <select className="form-control" id={"pared_x"+eje_Xx} name={"pared_x"+eje_Xx} onChange={props.handleChange} value={props.matriz_m_conf_X[eje_Xx-1][2]}>
                    <option value="Si">Paredes Tarrajeadas</option>
                    <option value="No">Paredes no Tarrajeadas</option>
                  </select>
                </MDBCol>     
              )}
              
              <br/><br/>

              <MDBCol sm={12}></MDBCol>

              <MDBCol sm={4} className="border-top">
                <small><strong>Seleccione la cantidad de muros en el eje Y: </strong></small>
              </MDBCol>
              <MDBCol sm={3}  className="border-top">
                <select className="form-control" name="cantidad_ejes_Y" onChange={props.handleChange} value={props.cantidad_ejes_Y}>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                </select>
              </MDBCol>
              <MDBCol sm={5} className="border-top"></MDBCol>

              {props.lista_eje_Y.map((eje_Yy) => <MDBCol sm={3}>
                  <label htmlFor="inicio_construccion"><small>Eje: Y{eje_Yy}</small></label>
                  {/* <input className="form-control" id={"cantidad_y"+eje_Yy} name={"cantidad_y"+eje_Yy} value={props.matriz_m_conf_Y[eje_Yy-1][0]} type="number" step={0.01} placeholder="Cantidad" pattern='[0-9]{0,4}' min="0" max="9999" onChange={props.handleChange}/>   */}
                  <input className="form-control" id={"longitud_y"+eje_Yy} name={"longitud_y"+eje_Yy} value={props.matriz_m_conf_Y[eje_Yy-1][0]} type="number" step={0.01}  placeholder="Longitud > a 1.2m" pattern='[0-9]{0,4}' min="0" max="9999" onChange={props.handleChange}/>  
                  <input className="form-control" id={"espesor_y"+eje_Yy} name={"espesor_y"+eje_Yy} value={props.matriz_m_conf_Y[eje_Yy-1][1]} type="number" step={0.01}  placeholder="Espesor (0.1m a 0.5m)" pattern='[0-9]{0,4}' min="0" max="9999" onChange={props.handleChange}/>  
                  <select className="form-control" id={"pared_y"+eje_Yy} name={"pared_y"+eje_Yy} onChange={props.handleChange} value={props.matriz_m_conf_Y[eje_Yy-1][2]}>
                    <option value="Si">Paredes Tarrajeadas</option>
                    <option value="No">Paredes no Tarrajeadas</option>
                  </select>
                </MDBCol>     
              )}
              <br/>
              <MDBCol sm={12}>
              <br/><br/> 
              </MDBCol>
              <br/>              
              <MDBCol sm={4} className=' d-flex justify-content-center'>
              <img src="ejexy2.png"/>
              </MDBCol>
              <MDBCol sm={8}>
                <label htmlFor="pisos_construidos">Cuál es el espesor de las juntas de los muros (*).</label>
                <select value={props.EspesorJuntas} onChange={props.handleChange} className="form-control" id="espesor_juntas" name="espesor_juntas">
                  <option value={1}>Espesor entre 1cm y 1.5cm</option>
                  <option value={0.8}>Espesor mayor a 1.6 cm y/o variable</option>
                </select>
                <br/>
                <p><b>(*) Junta de muros</b> se refiere al mortero para unir los ladrillos.</p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
      </div>
    );
  }


  function Step11(props) {
    if (props.currentStep !== 11) {
      return null
    } 
    return(
      <div className="form-group">
          <MDBContainer>
          <small><strong>Seleccione una opción sobre el piso de la edificación.</strong></small>

          <MDBRow className="seccion_row">
            {props.type_diafragmas.map(({id, name, stage, image, description}) => <MDBCol sm={6} className="col_encuesta">
                <div className="radio" id="atributo">
                <label className="form-check-label" for={"opcion"+id}>
                <input type="radio" id={"opcion"+id} value={id} name="tipo_diafragmas" onClick={props.ver_diafragmas_edificacion} checked={stage}/>
                <img src={image} class="imagenes" alt="Responsive image"/>
                <ModalPage titulo={name} estado={false} src_modal={image} texto={description}/>{/*<button type="button" onClick={ver_modal} className="btn btn-danger bg-danger boton_encima"  data-toggle="modal" data-target="#SiAsesoramientoT">+</button>*/}
                </label>
              </div>
            </MDBCol>
            )}
          </MDBRow>
          </MDBContainer>
      </div>
    );
  }
  
  function Step12(props) {
    if (props.currentStep !== 12) {
      return null
    } 
    return(
      <div className="form-group">
          <MDBContainer>
          <small><strong>Seleccione la opción de la forma en planta de la edificación.</strong></small>
          <MDBRow className="seccion_row">
            <MDBCol sm={10}>
              <MDBRow className="seccion_row">
                {props.type_configuracion_edificacion.map(({id, name, stage, image, description}) => <MDBCol sm={4} className="col_encuesta">
                    <div className="radio" id="atributo">
                    <label className="form-check-label" for={"opcion"+id}>
                    <input type="radio" id={"opcion"+id} value={id} name="tipo_configuracion_edificacion" onClick={props.ver_configuracion_edificacion} checked={stage}/>
                    <img src={image} class="imagenes" alt="Responsive image"/>
                    <ModalPage titulo={name} estado={false} src_modal={image} texto={description}/>{/*<button type="button" onClick={ver_modal} className="btn btn-danger bg-danger boton_encima"  data-toggle="modal" data-target="#SiAsesoramientoT">+</button>*/}
                    </label>
                  </div>
                </MDBCol>
                )}
                <MDBCol sm={4} className="texto-nota-step12">
                  <br/><br/>
                  <p><b>Nota:</b> Para las formas "T" y "+"</p>
                  <ul>
                    <li>"a" es mayor o igual que "a1".</li>
                    <li>"b" es mayor o igual que "b1".</li>
                    <li>No es necesario ingresar los valores de "a1" y "b1".</li>
                  </ul>  
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol sm={2}>
              <small><strong>Nota: </strong>Para avanzar llene todos los campos.</small>
              <input className="form-control" id="AA" name="AA" value={props.AA} type="number" disabled={props.state_AA} step={0.01} placeholder="A (m)" pattern='[0-9]{0,4}' min="0" max="9999" onChange={props.handleChange}/>  
              <input className="form-control" id="BB" name="BB" value={props.BB} type="number" disabled={props.state_BB} step={0.01} placeholder="B (m)" pattern='[0-9]{0,4}' min="0" max="9999"  onChange={props.handleChange}/>  
              <input className="form-control" id="aa" name="aa" value={props.aa} type="number" disabled={props.state_aa} step={0.01} placeholder="a (m)" pattern='[0-9]{0,4}' min="0" max="9999" onChange={props.handleChange}/>  
              <input className="form-control" id="bb" name="bb" value={props.bb} type="number" disabled={props.state_bb} step={0.01} placeholder="b (m)" pattern='[0-9]{0,4}' min="0" max="9999" onChange={props.handleChange}/>
              {/* <input className="form-control" id="a1" name="a1" value={props.a1} type="number" disabled={props.state_a1} step={0.01} placeholder="a1 (m)" pattern='[0-9]{0,4}' min="0" max="9999" onChange={props.handleChange}/>  
              <input className="form-control" id="b1" name="b1" value={props.b1} type="number" disabled={props.state_b1} step={0.01} placeholder="b1 (m)" pattern='[0-9]{0,4}' min="0" max="9999" onChange={props.handleChange}/>                  */}
            </MDBCol>
          </MDBRow>
          </MDBContainer>
      </div>  
    );
  }

  function Step13(props) {
    if (props.currentStep !== 13) {
      return null
    } 
    return(
      <div className="form-group">
        <MDBContainer>
          <small><strong>Seleccione <strong>una o más opciones</strong> sobre la forma en elevación de su edificación.</strong></small>
          <MDBRow className="seccion_row">
            {props.type_configuracion_edificacion_altura.map(({id, name, stage, image, description}) => <MDBCol sm={6} className="col_encuesta">
                <div className="radio" id="atributo">
                <label className="form-check-label" for={"cea_opcion"+id}>
                <input type="radio" id={"cea_opcion"+id} value={id} name={"tipo_configuracion_edificacion_altura"+id} onClick={props.ver_configuracion_edificacion_altura} checked={stage}/>
                <img src={image} class="imagenes" alt="Responsive image"/>
                <ModalPage titulo={name} estado={false} src_modal={image} texto={description}/>{/*<button type="button" onClick={ver_modal} className="btn btn-danger bg-danger boton_encima"  data-toggle="modal" data-target="#SiAsesoramientoT">+</button>*/}
                </label>
              </div>
            </MDBCol>
            )}
            
          </MDBRow>
        </MDBContainer>
      </div>  
    );
  }

  function Step14(props) {
    if (props.currentStep !== 14) {
      return null
    } 
    return (
      <MDBContainer>
        <MDBRow>
          <br/><br/><br/>
          <h5 className="title_end"> 
            El <a className="referencias" href="https://cdlima.org.pe" target="_blank">El Colegio de Ingenieros del Perú – Consejo Departamental Lima</a> y el <a className="referencias" href="http://redacel.cid-allpanchis.org" target="_blank">Centro de Investigación de Desarrollo Allpanchis – CIDALL </a>, agradecen su interés por completar la encuesta de Vulnerabilidad Sísmica en Edificaciones de Albañilería.
          </h5>
          <br/><br/>
          <h6 className="title_end2">
            Si esta seguro de las respuestas seleccionadas, hague clic en el boton enviar para registrar su encuesta, caso contrario regrese a la pregunta que desea modificar y continue con el proceso.
          </h6>
          <br/><br/>
          <p className="title_end_agradecimiento">
            Atentamente.
            <br/>
            <br/>
            Colegio de Ingenieros del Perú CD Lima (CIP CD Lima)
          </p>
          <br/><br/>
          <div className="recaptcha">
            {/* <ReCAPTCHA
              sitekey="6Lc9mk4aAAAAAMZA3W1LH-bhRHjc71Z2VRgvYqP-"//"6LfgOL0UAAAAAEd2YAE5D8Jmg47u4-JkupfmPSsT"//"6LeQjMMUAAAAANeUaV8IT2hFIXOimLsbW8wPiIPL"//"6LeQjMMUAAAAABVj8NRTb6l6pEurjFSkI746G8bw"
              onChange={props.recaptcha}
              badge="inline"
              size="invisible"

            />  */}
            {/* <ReCAPTCHA
            sitekey="6LfgOL0UAAAAAEd2YAE5D8Jmg47u4-JkupfmPSsT"//"6LeQjMMUAAAAANeUaV8IT2hFIXOimLsbW8wPiIPL"//"6LeQjMMUAAAAABVj8NRTb6l6pEurjFSkI746G8bw"
            onChange={props.recaptcha}
            
            badge="bottomleft"
            size="invisible"
            /> */}
          </div>
        </MDBRow>
      </MDBContainer>
    );
  }
  function Step15(props) {
    if (props.currentStep !== 15) {
      return null
    }
    const weekdays = ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"];
    const namemonths = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dic"];
    let objectDate = new Date();
    let day = objectDate.getDay();
    let nday = objectDate.getDate();
    let nameday = weekdays[day];
    let month = objectDate.getMonth();
    let namemonth = namemonths[month];
    let year = objectDate.getFullYear();
    let time = objectDate.toLocaleTimeString();
    return(
      <MDBContainer fluid>
      <MDBRow>
        <MDBCol sm={6}>
          <MapaResultado longitud_sismo={props.longitud_sismo} latitud_sismo={props.latitud_sismo} url_data={props.url_data} url_data1={props.url_data1} url_data2={props.url_data2}/>
        </MDBCol>
        <MDBCol sm={6}>
          <div className="Texto_Resultado">
            <div className="division_Resultado">
              <div className="Enunciado_Resultado">
                <h3 className="Resultado_Titulo_Principal"><strong>Resultado de la evaluación de vulnerabilidad de su edificación</strong></h3>
                <br/>
                <div className="seccion_resultado">
                  <h4><strong>Datos de la edificación</strong></h4>
                  <p>
                  <h5><strong>Ubicación</strong></h5>
                  <h6><strong>Latitud:</strong>{Math.round(props.latitud_sismo*1000000)/1000000} </h6>
                  <h6><strong>Longitud:</strong>{Math.round(props.longitud_sismo*1000000)/1000000} </h6>
                  </p>
                </div>
                <div className="seccion_resultado">
                  <h4><strong>Fecha de realización de la encuesta</strong></h4>
                  <h6>{`${nameday} ${nday} ${namemonth}, ${year}. ${time} (GMT-05 Hora de Perú).`}</h6>
                </div>
                <br/>
                <div className="seccion_resultado">
                  El resultado <strong>PRELIMINAR</strong> obtenido luego de completar la encuesta de vulnerabilidad de su edificación, es que posee <span className="texto_resultado_muestra">{props.resultado_encuesta['name']}</span> de sufrir daños frentea un evento sísmico de gran magnitud.
                </div>
                <br/>
                <div className="seccion_resultado">
                  Agradecemos su participación
                </div>
                <br/>
                <div className="seccion_resultado">
                  <div className="Footer_PDF">
                    <small><strong>
                    <tr>Colegio de Ingenieros del Perú - Consejo Departamental de Lima</tr>
                    <tr>https://cdlima.org.pe</tr>
                    <tr>Teléfono: (01) 202-5004</tr>
                    <tr>Email: comisionescdlima@ciplima.org</tr>
                    <br/>
                    <tr>Centro de Investigación y Desarrollo – CIDALL</tr>
                    <tr>http://redacel.cid-allpanchis.org</tr>
                    <tr>Email: informes.cidall@gmail.com</tr>
                    </strong></small>
                  </div>
                </div>
                <Pdf targetRef={ref} filename={"resultado_vulnerabilidad_sismica"+String(Date())+".pdf"}>
                  {({ toPdf }) => <FaPrint className="print" onClick={toPdf}/>}
                </Pdf>
              </div>
              <div className="PDF_Intensidades">
                <div className="imprimir_pdf" ref={ref}>
                  <div className="Header_PDF">
                  <img src="./PPT_Vulnerabilidad_paint.png" className="logo_cip_one_PDF"/>{/*<strong> <h2 className="texto_T_cip_PDF">Vulnerabilidad Sísmica CIP - CD Lima</h2> </strong><img src="./redacel.png" className="logo_red2_PDF"/>
                  {/* <img src="./redacel.png" className="logo_red_PDF"/> */}
                  </div>
                  <div className="Content_PDF">
                    <br/>
                    <h3 className="Resultado_Titulo_Principal"><strong>Resultado de la evaluación de vulnerabilidad de su edificación</strong></h3>
                    <br/>
                    <div className="seccion_resultado">
                      <h4><strong>Datos de la edificación</strong></h4>
                      <p>
                      <h5><strong>Ubicación</strong></h5>
                      <h6><strong>Latitud:</strong>{Math.round(props.latitud_sismo*1000000)/1000000} </h6>
                      <h6><strong>Longitud:</strong>{Math.round(props.longitud_sismo*1000000)/1000000} </h6>
                      </p>
                    </div>
                    <div className="seccion_resultado">
                      <h4><strong>Fecha de realización de la encuesta</strong></h4>
                      <h6>{`${nameday} ${nday} ${namemonth}, ${year}. ${time} (GMT-05 Hora de Perú).`}</h6>
                    </div>
                    <br/>
                    <div className="seccion_resultado">
                    El resultado <strong>PRELIMINAR</strong> obtenido luego de completar la encuesta de vulnerabilidad de su edificación, es que posee <span className="texto_resultado_muestra">{props.resultado_encuesta['name']}</span> de sufrir daños frentea un evento sísmico de gran magnitud.
                    </div>
                    <br/>
                    <div className="seccion_resultado">
                      Agradecemos su participación
                    </div>
                    <br/>
                    
                    <img src="./cipfinal.png" className="logo_cip_PDF"/><img src="./redacel.png" className="logo_red2_PDF"/>
                    <div className="informacion"><small className="span_info">Si desea más información, por favor, pongase en contacto con el Colegio de Ingenieros del Perú Consejo Departamental Lima.</small></div>
                  </div>
                  <br/>
                  <div className="Footer_PDF">
                    <small><strong>
                    <tr>Colegio de Ingenieros del Perú - Consejo Departamental de Lima</tr>
                    <tr>https://cdlima.org.pe</tr>
                    <tr>Teléfono: (01) 202-5004</tr>
                    <tr>Email: comisionescdlima@ciplima.org</tr>
                    <br/>
                    <tr>Centro de Investigación y Desarrollo – CIDALL</tr>
                    <tr>http://redacel.cid-allpanchis.org</tr>
                    <tr>Email: informes.cidall@gmail.com</tr>
                    </strong></small>
                  </div>
                </div>
              </div>
            
            </div>
          </div>
        </MDBCol>
      </MDBRow>
      </MDBContainer>
    )
  }
